import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';

import errorReducer from '../features/error/slice';
import sessionReducer from '../features/session/slice';
import socketReducer from '../features/socket/slice';
import deviceReducer from '../features/device/slice';

import { webSocketMiddleware } from '../features/socket/middlewares';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, webSocketMiddleware];

const rootReducer = combineReducers({
  error: errorReducer,
  session: sessionReducer,
  socket: socketReducer,
  device: deviceReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => (
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  })
);

// Done so we don't import rootSaga here, which results in circular deps
export const registerRootSaga = (rootSaga: any) => {
  sagaMiddleware.run(rootSaga);
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

export default setupStore;
