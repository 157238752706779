import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value; // assign the value of ref to the argument
  }, [value]); // this code will run when the value of 'value' changes
  return ref.current; // in the end, return the current ref value.
};

// the state is refresh only every X ms maximum.
export const useStateLimitRefreshRate = <T>(
  ms: number,
  defaultValue?: T,
): [T | undefined, Dispatch<T | undefined>] => {
  const [value, setValue] = useState<T | undefined>(defaultValue);
  const lastUpdate = useRef<Date>();
  const timeoutUpdateRef = useRef<NodeJS.Timeout>();

  const updateValue = (
    newValue: T | undefined,
    isFromTimeout: boolean = false,
  ) => {
    const now = new Date();
    let nextRefresh: number = ms;
    let update = true;
    if (!lastUpdate.current) {
      lastUpdate.current = now;
    } else {
      const diff = now.getTime() - lastUpdate.current.getTime();
      if (diff < ms) {
        nextRefresh = diff;
        update = false;
      }
    }

    if (update) {
      if (timeoutUpdateRef.current) {
        clearTimeout(timeoutUpdateRef.current);
      }

      if (!isFromTimeout) {
        timeoutUpdateRef.current = setTimeout(() => {
          updateValue(newValue, true);
        }, nextRefresh);
      }
      lastUpdate.current = now;
      setValue(newValue);
    }
  };

  useEffect(() => {
    updateValue(value);
  }, []);

  const newSetValue = (newValue: T | undefined) => updateValue(newValue);
  return [value, newSetValue];
};
