import { call, put, race, select, take } from 'redux-saga/effects';
import { ErrorType, ResponseError } from '../../common/helpers/errors';

import storage, { getToken, TokenType } from '../../common/helpers/storage';
import sessionApi from './api';
import { refreshTokenFailed, retryRequest } from './actions';
import { selectIsRefreshingToken } from './selectors';
import { setIsRefreshingToken, setRefreshToken, setToken } from './slice';
import { handleError } from '../error/generators';
import { appError } from '../error/actions';
import { createError } from '../error/utils';
import { setError } from '../error/slice';

type FetchingFunction = (body?: object) => object;

interface RefreshTokenRaceResult {
  refreshSuccess?: any,
  refreshFail?: any,
}

function disconnect() {
  // This will cause user disconnection
  throw new ResponseError(createError(ErrorType.ApiError, 460));
}

function* requestAndUpdateToken() {
  // Lock logic so we can check if there is concurrency
  try {
    yield put(setIsRefreshingToken(true));

    const rToken: string = yield call(getToken, TokenType.Refresh);
    if (!rToken) {
      throw new Error();
    }
    const {
      token,
      refreshToken,
    } = yield call(sessionApi.refreshToken, { refreshToken: rToken });
    yield call(storage.setToken, token); // in localStorage
    yield call(storage.setToken, refreshToken, TokenType.Refresh);
    yield put(setToken(token)); // in redux store, to trigger useAuth
    yield put(setRefreshToken(refreshToken));
  } catch {
    yield put(refreshTokenFailed());
  } finally {
    yield put(setIsRefreshingToken(false));
  }
}

/**
 * This function handles all the workflow of :
 * - sending a request using a given 'sender' function and a body
 * - checking if the request was refused with a 460 error
 * - retrieving the refresh token and use it to ask for a new token
 * - replacing the old token with the new one
 * - trying the failed request again
 * - if any error occurs again, raise an error again
 * - if no error occur, returns the final result (from either the first or second request)
 *
 * @param sender The API fetching function (located in api.ts files)
 * @param body The body to send
 * @returns The request result
 */
export function* submitRequest(sender: FetchingFunction, body?: object): object {
  let response: object = {};

  try {
    response = yield call(sender, body);
  } catch (requestError) {
    const resError = requestError as ResponseError;
    const reData = resError.data;
    if (reData?.type === ErrorType.ApiError && reData.code === 460) {
      // Token needs a refresh
      const isRefreshing: boolean = yield select(selectIsRefreshingToken);
      if (isRefreshing) {
        // Token is already being refreshed
        // We wait to know if the refresh worked
        const winner: RefreshTokenRaceResult = yield race({
          refreshSuccess: take(setIsRefreshingToken),
          refreshFail: take(refreshTokenFailed),
        });
        if (winner.refreshSuccess) {
          try {
            response = yield call(sender, body);
          } catch (reRequestError) {
            yield call(disconnect);
          }
        }
      } else {
        // We need to update token ourself
        try {
          yield call(requestAndUpdateToken);
          response = yield call(sender, body);
        } catch (reRequestError) {
          yield call(disconnect);
        }
      }
    } else if (reData?.type === ErrorType.NetworkError) {
      yield put(setError(reData)); // Error component is aware of NetworkError
      yield take(retryRequest); // Saga is stuck until user asks for request retry
      response = yield call(submitRequest, sender, body);
    } else {
      throw resError;
    }
  }

  return response;
}

export default {
  submitRequest,
};
