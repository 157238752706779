import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useAuth } from './hooks';
import { AuthStatus } from './interfaces';

import styles from './AuthForm.module.css';
import { requestLoginLocal } from './actions';
import { selectAuthStatus, selectIsFetching } from './selectors';
import { useAppTranslation } from '../../common/hooks/translation';
import { Strings } from '../../common/constants/translation';
import { useAppError } from '../error/hooks';
import Touchable from '../../common/components/utils/Touchable';
import Fonts from '../../constants/fonts';

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { reset } = useAppError();
  const authStatus = useAppSelector(selectAuthStatus);
  const isFetching = useAppSelector(selectIsFetching);
  const navigate = useNavigate();
  const { t } = useAppTranslation();

  useEffect(() => {
    if (authStatus === AuthStatus.In) {
      navigate('/home', { replace: true });
    }
  }, [authStatus]);

  const onSubmit = (data: any) => {
    reset();
    dispatch(requestLoginLocal(data));
  };

  const renderRequiredField = () => (
    <span className={styles.fieldError}>
      {t(Strings.REQUIRED_FIELD)}
    </span>
  );

  return (
    <div className={styles.authContainer}>
      <p className={styles.loginTitle}>{t(Strings.PANEL)}</p>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <input className={styles.input} placeholder={t(Strings.EMAIL)} {...register('email', { required: true })} />
        { errors.email && renderRequiredField() }
        <input className={styles.input} placeholder={t(Strings.PASSWORD)} type="password" {...register('password', { required: true })} />
        { errors.password && renderRequiredField() }
        <div className={styles.row}>
          <Touchable
            style={{
              color: '#aeaeff',
              textDecorationLine: 'underline',
              fontFamily: Fonts.getSignika(),
            }}
            onPress={() => navigate('/reset_password')}
          >
            {t(Strings.PASSWORD_RECOVERY_FORGOT_PASSWORD)}
          </Touchable>
        </div>
        <input className={styles.button} type="submit" value={t(Strings.LOGIN)} disabled={isFetching} />
      </form>
    </div>
  );
};

export default LoginForm;
