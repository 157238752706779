import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Datepicker } from '@mobiscroll/react';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import { is24HourClockFormat } from '../../helpers/utils';

import styles from './TimePicker.module.css';
import Colors from '../../constants/colors';

const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DISABLED = false;
const DEFAULT_DEFAULT_STEP_HOUR = 1;
const DEFAULT_DEFAULT_STEP_MINUTE = 1;
const DEFAULT_DEFAULT_STEP_SECOND = 1;

interface DateTimeOnChange {
  value: Date;
  valueText: string;
}

export interface TimePickerProps {
  timeFormat?: string;
  disabled?: boolean;
  min?: DateType;
  max?: DateType;
  value?: DateType;
  stepHour?: number;
  stepMinute?: number;
  stepSecond?: number;
  onChange?: (value: string, date: Date) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface TimePickerRef {
  getValue: () => string | undefined;
  getDate: () => Date | undefined;
}

const TimePicker = React.forwardRef(({
  timeFormat,
  disabled,
  min,
  max,
  value,
  stepHour,
  stepMinute,
  stepSecond,
  onChange,
  className,
  style,
}: TimePickerProps, forwardRef: ForwardedRef<TimePickerRef | undefined>) => {
  const [time, setTime] = useState<string | undefined>();
  const dataPickerRef = useRef<Datepicker>(null);

  useImperativeHandle(forwardRef, () => ({
    getValue: () => time,
    getDate: () => dataPickerRef.current?.value,
  }));

  useEffect(() => {
    setTime(dataPickerRef.current?._valueText);
  }, []);

  const getTimeFormatFromLocal = () => {
    let format: string = timeFormat ?? DEFAULT_TIME_FORMAT;
    if (!is24HourClockFormat()) {
      format += ' A';
    }
    return format;
  };

  const background = Colors.getBlueBayoux();
  const lineBackground = Colors.getMainBlack(0.1);
  const itemColor = Colors.getMainWhite();
  const itemSelectedColor = Colors.getBlueCornflower();
  const itemHoverBackground = Colors.getPickledBluewood(0.5);

  // override lib css
  const css = `
    .mbsc-popup-body,
    .mbsc-scroller {
      background: ${background} !important;
    }
    .mbsc-scroller-wheel-line {
      background: ${lineBackground} !important;
    }
    .mbsc-scroller-wheel-item {
      color: ${itemColor} !important;
      margin: 0px 10px;
    }
    .mbsc-scroller-wheel-item.mbsc-selected {
      color: ${itemSelectedColor} !important;
    }
    .mbsc-scroller-wheel-item.mbsc-hover {
      background: ${itemHoverBackground} !important;
    }
    .mbsc-textfield-wrapper {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0px !important;
      opacity: 0 !important;
      top: 0px;
      left: 0px;
    }

    .mbsc-textfield-wrapper span, .mbsc-textfield-wrapper input {
      width: 100% !important;
      height: 100% !important;
      cursor: pointer !important;
    }
  `;

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div className={classes.join(' ')} style={style}>
      <style>{css}</style>
      <div className={styles.value}>
        {time}
      </div>
      <Datepicker
        controls={['time']}
        returnFormat="moment"
        timeFormat={getTimeFormatFromLocal()}
        min={min}
        max={max}
        defaultValue={value}
        stepHour={stepHour}
        stepMinute={stepMinute}
        stepSecond={stepSecond}
        disabled={disabled}
        onChange={(data: DateTimeOnChange) => {
          setTime(data.valueText);
          onChange?.(data.valueText, data.value);
        }}
        ref={dataPickerRef}
      />
    </div>
  );
});

TimePicker.defaultProps = {
  timeFormat: DEFAULT_TIME_FORMAT,
  disabled: DEFAULT_DISABLED,
  min: undefined,
  max: undefined,
  value: new Date(),
  stepHour: DEFAULT_DEFAULT_STEP_HOUR,
  stepMinute: DEFAULT_DEFAULT_STEP_MINUTE,
  stepSecond: DEFAULT_DEFAULT_STEP_SECOND,
  onChange: undefined,
  className: undefined,
  style: undefined,
};

export default TimePicker;
