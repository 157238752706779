import { DeviceType, DeviceUpdateSession, SoftwareVersionImageType, DeviceInformation as DeviceInformationWS } from '@kalyzee/kast-websocket-module';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddSelectedDevicePayload, Device, DeviceInformation, DeviceResponsePayload, DeviceUpdateProgression, SetSelectedDevicesPayload, UpdateDeviceNamePayload } from './interfaces';

interface DeviceState {
  devices: Device[],
  informations: DeviceInformation[],
  updateSessions: DeviceUpdateSession[],
  updateProgressions: DeviceUpdateProgression[],
}

const initialState: DeviceState = {
  devices: [],
  informations: [],
  updateSessions: [],
  updateProgressions: [],
};

const devicePayloadFormat = (payload: DeviceResponsePayload): Device => {
  const device: Device = {
    id: payload.deviceId,
    online: !!payload.context?.online,
    userSelected: true,
    context: payload.context,
  };
  if (payload.room) device.room = payload.room;
  if (payload.name) device.name = payload.name;
  if (payload.hardwareId) device.hardwareId = payload.hardwareId;
  if (payload.externalIp) device.externalIp = payload.externalIp;
  if (payload.timeOffset) device.timeOffset = payload.timeOffset;
  return device;
};

// ------ REDUCERS -------- //

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<DeviceResponsePayload[]>) => {
      state.devices = action.payload.map((devicePayload) => devicePayloadFormat(devicePayload));
    },
    updateDevice: (state, { payload }: PayloadAction<DeviceResponsePayload>) => {
      const updatedIdx = state.devices.findIndex(
        (device) => device.id === payload.deviceId,
      );
      if (updatedIdx > -1) {
        const updatedDevice = state.devices[updatedIdx];
        updatedDevice.context = payload.context;
        if (payload.externalIp !== undefined) {
          updatedDevice.externalIp = payload.externalIp;
        }
        if (payload.timeOffset !== undefined) {
          updatedDevice.timeOffset = payload.timeOffset;
        }
        if (payload.context?.online !== undefined) {
          updatedDevice.online = payload.context.online;
        }
      }
    },
    setSelectedDevices: (state, { payload }: PayloadAction<SetSelectedDevicesPayload>) => {
      state.devices.forEach((device) => {
        if (payload.ids.includes(device.id)) {
          device.userSelected = true;
        } else {
          device.userSelected = false;
        }
      });
    },
    selectDevice: (state, { payload }: PayloadAction<AddSelectedDevicePayload>) => {
      const updatedIdx = state.devices.findIndex(
        (device) => device.id === payload.id,
      );
      if (updatedIdx > -1) {
        state.devices[updatedIdx].userSelected = !state.devices[updatedIdx].userSelected;
      }
    },
    updateDeviceName: (state, { payload }: PayloadAction<UpdateDeviceNamePayload>) => {
      const updatedIdx = state.devices.findIndex(
        (device) => device.id === payload.id,
      );
      if (updatedIdx > -1) {
        state.devices[updatedIdx].name = payload.name;
      }
    },
    updateDevicesInformation: (state, { payload }: PayloadAction<DeviceInformationWS[]>) => {
      payload.forEach((info) => {
        const index = state.informations.findIndex(
          (curr) => curr.deviceId === info.deviceId,
        );
        const isNew = index < 0;
        let data : DeviceInformation;
        if (isNew) {
          data = {
            deviceId: info.deviceId,
            type: info.type,
          };
        } else {
          data = state.informations[index];
        }

        if (info.type === DeviceType.KAST) {
          data.kast = info.information;
        }
        if (info.versions) {
          data.osVersion = info.versions.find((v) => v.type === SoftwareVersionImageType.OS);
        }

        if (info.time) {
          data.time = info.time;
          if (data.time.time) {
            data.time.timeOffset = new Date().getTime() - data.time.time;
          }
        }

        if (info.networks) {
          data.networks = info.networks;
        }

        if (isNew) state.informations.push(data);
      });
    },
    setDevicesUpdateSessions: (state, { payload }: PayloadAction<DeviceUpdateSession[]>) => {
      state.updateSessions = payload;
    },
    updateDevicesUpdateSessions: (state, { payload }: PayloadAction<DeviceUpdateSession[]>) => {
      payload.forEach((session) => {
        const index = state.updateSessions.findIndex(
          (curr) => curr.id === session.id,
        );
        const isNew = index < 0;
        if (isNew) state.updateSessions.push(session);
        else Object.assign(state.updateSessions[index], session);
      });
    },
    updateDeviceUpdateProgression: (state, { payload }: PayloadAction<DeviceUpdateProgression>) => {
      const index = state.updateProgressions.findIndex(
        (curr) => curr.sessionId === payload.sessionId,
      );
      const now = new Date();
      const isNew = index < 0;
      if (isNew) {
        state.updateProgressions.push({
          ...payload,
          lastUpdate: now.toISOString(),
          downloadSpeed: undefined,
        });
      } else {
        const currentProgression = state.updateProgressions[index];
        if (currentProgression?.lastUpdate
          && currentProgression.currentSize !== payload.currentSize) {
          const COUNT = 5;
          const sizeRemaining = payload.totalSize - payload.currentSize;
          const lastDownloadSpeed = currentProgression.lastDownloadSpeed ?? [];
          let downloadSpeed = 0;
          let averageDownloadSpeed = 0;

          const diffTime = now.getTime() - new Date(currentProgression.lastUpdate).getTime();
          const diffSize = payload.currentSize - currentProgression.currentSize;
          downloadSpeed = diffSize / diffTime;

          lastDownloadSpeed.push(downloadSpeed);
          if (lastDownloadSpeed.length > COUNT) {
            lastDownloadSpeed.splice(0, (lastDownloadSpeed.length - COUNT));
          }
          lastDownloadSpeed.forEach((v) => {
            averageDownloadSpeed += v;
          });
          averageDownloadSpeed /= lastDownloadSpeed.length;

          const estimateTimeRemaining = sizeRemaining / averageDownloadSpeed;

          Object.assign(state.updateProgressions[index], payload, {
            lastUpdate: now.toISOString(),
            lastDownloadSpeed,
            downloadSpeed,
            averageDownloadSpeed,
            estimateTimeRemaining,
          });
        }
      }
    },
  },
});

export const {
  setDevices,
  updateDevice,
  setSelectedDevices,
  selectDevice,
  updateDeviceName,
  updateDevicesInformation,
  setDevicesUpdateSessions,
  updateDevicesUpdateSessions,
  updateDeviceUpdateProgression,
} = deviceSlice.actions;

export default deviceSlice.reducer;
