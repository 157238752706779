import React, { useRef } from 'react';
import { DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection, PlayerWebRTC, PlayerWebRTCChildrenFunc, PlayerWebRTCRef, VideoState } from '@kalyzee/kast-react-player-module';
import GridList, { GridListDetails } from '../utils/GridList';
import { getGenerateWebRTCSessionTokenUrl, getWebRTCSocketUrl } from '../../helpers/request';
import { getToken } from '../../helpers/storage';
import PlayerContainer, { PlayerContainerRef } from '../container/PlayerContainer';
import Colors from '../../constants/colors';
import VideoOverlay from '../video/VideoOverlay';
import { DeviceData } from '../../../features/device/interfaces';
import { isTouchEnabled } from '../../helpers/utils';

const containerStyle : React.CSSProperties = {
  backgroundColor: 'black',
  padding: '5px',
};

const playerContainerStyle : React.CSSProperties = {
  height: '100%',
  width: '100%',
};

const onlineStyle : React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const onlineVideoStyle : React.CSSProperties = {
  height: '100%',
  width: '100%',
  backgroundColor: 'unset',
};

export interface PlayerGridListProps {
  autoAdjust?: boolean;
  devices: DeviceData[];
  freezeAutoAdjust: boolean;
  onClick?: (item: DeviceData, ref : PlayerWebRTCRef, details : GridListDetails) => void;
  onMove?: (item: DeviceData, direction : DeviceMoveDirection) => void;
  onStopMove?: (item: DeviceData) => void;
  onZoom?: (item: DeviceData, zoom : DeviceZoomDirection) => void;
  onStopZoom?: (item: DeviceData) => void;
  onScene?: (item: DeviceData, scene : DeviceScene) => void;
  onView?: (item: DeviceData, view: DeviceView) => void;
  onAssignView?: (item: DeviceData, view: DeviceView) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface PlayerGridListChildProps {
  scene: DeviceScene;
  view: DeviceView;
}

export interface PlayerWebRTCDataRef {
  playerRef: PlayerWebRTCRef;
  containerRef: PlayerContainerRef;
  contentRef?: HTMLDivElement;
  index : number;
}

export const DEFAULT_PLAYERWEBRTC_CHILD_PROPS = {
  scene: DeviceScene.CAMERA_ONLY,
  view: DeviceView.VIEW_1,
};

const PlayerGridList = ({
  devices,
  autoAdjust,
  freezeAutoAdjust,
  onClick,
  onMove,
  onStopMove,
  onZoom,
  onStopZoom,
  onScene,
  onView,
  onAssignView,
  className,
  style,
}: PlayerGridListProps) => {
  const references = useRef<PlayerWebRTCDataRef[]>([]);

  if (devices) {
    references.current = new Array(devices.length).fill({});
  }

  const handleClickVideo = (
    index: number,
    item : DeviceData | undefined,
    details: GridListDetails,
  ) : void => {
    if (item && index < references.current?.length) {
      const playerRef = references.current[index]?.playerRef;
      if (playerRef?.getState?.() === VideoState.RUNNING) {
        // if camera controls isn't displaying, it's because is mobile mode (touch mode).
        // wait controls are displayed to enable click
        if (playerRef.isDisplayingCameraControls()) {
          onClick?.(item, playerRef, details);
        }
      }
    }
  };

  const renderOverlayByState = (
    state: VideoState,
    retry: () => void,
  ) => (
    <VideoOverlay
      state={state}
      onRetry={retry}
    />
  );

  const renderItem = (index: number, item : DeviceData | undefined, details : GridListDetails) => {
    if (!item) return null;
    let content = null;
    const enableControls = {
      scene: true,
      view: true,
    };
    let enableKeyBoard = false;
    if (details.itemsForCurrentPage <= 1) {
      enableKeyBoard = true;
    }
    if (item.isOnline) {
      content = (
        <PlayerWebRTC
          ref={(r : PlayerWebRTCRef) => {
            if (index < references.current?.length) {
              references.current[index] = {
                playerRef: r,
                containerRef: references.current[index].containerRef,
                contentRef: references.current[index].contentRef,
                index,
              };
            }
          }}
          style={onlineVideoStyle}
          videoStyle={{ maxWidth: '100%', maxHeight: '100%' }}
          src={{
            url: getWebRTCSocketUrl(),
            generateTokenWithUserToken: getToken() || undefined,
            generateTokenEndpoint: getGenerateWebRTCSessionTokenUrl(item.id),
          }}
          play
          live
          mute
          delayAfterHideControls={0}
          retryAmountOnError={3}
          retryDelay={3000}
          enableCameraControls={!isTouchEnabled()}
          enableVideoControls={false}
          enableScene={enableControls.scene}
          enableView={enableControls.view}
          enableMoveKeyboardEvent={enableKeyBoard}
          enableAssignViewKeyboardEvent={enableKeyBoard}
          enableViewKeyboardEvent={enableKeyBoard}
          enableSceneKeyboardEvent={enableKeyBoard}
          enableZoomKeyboardEvent={enableKeyBoard}
          scene={item.currentScene as (DeviceScene | undefined)}
          onMove={(direction : DeviceMoveDirection) => onMove?.(item, direction)}
          onStopMove={() => onStopMove?.(item)}
          onZoom={(zoom : DeviceZoomDirection) => onZoom?.(item, zoom)}
          onStopZoom={() => onStopZoom?.(item)}
          onScene={(scene : DeviceScene) => onScene?.(item, scene)}
          onView={(view: DeviceView) => onView?.(item, view)}
          onAssignView={(view: DeviceView) => onAssignView?.(item, view)}
        >
          {
            (state: VideoState, r : () => void) => {
              if (index < references.current?.length) {
                const divElement = references.current[index].contentRef;
                if (divElement) {
                  Object.assign(divElement.style, { cursor: state === VideoState.RUNNING ? 'pointer' : 'unset' });
                }
              }
              return renderOverlayByState(state, r);
            }
          }
        </PlayerWebRTC>
      );
    } else {
      content = (
        <VideoOverlay
          state="NO_CONNECTED"
        />
      );
    }
    return (
      <div
        role="button"
        style={onlineStyle}
      >
        <PlayerContainer
          style={playerContainerStyle}
          data={item}
          autoResize
          tabIndex={0}
          onClick={() => handleClickVideo(index, item, details)}
          onKeyDown={(e) => { if (e.code === 'Enter') handleClickVideo(index, item, details); }}
          ref={(r : PlayerContainerRef) => {
            if (index < references.current?.length) {
              references.current[index] = {
                playerRef: references.current[index].playerRef,
                containerRef: r,
                contentRef: references.current[index].contentRef,
                index,
              };
              r?.resize();
            }
          }}
        >
          <div
            style={{ width: '100%', height: '100%' }}
            ref={(el: HTMLDivElement) => {
              if (index < references.current?.length) {
                references.current[index] = {
                  playerRef: references.current[index].playerRef,
                  containerRef: references.current[index].containerRef,
                  contentRef: el,
                  index,
                };
              }
            }}
          >
            { content }
          </div>
        </PlayerContainer>
      </div>
    );
  };

  const render = () => (
    <GridList
      autoAdjust={autoAdjust}
      freezeAutoAdjust={freezeAutoAdjust}
      className={className}
      style={{ ...containerStyle, ...(style || {}) }}
      gap={10}
      paginationTextColor={Colors.getMainWhite()}
      paginationTextColorSelected={Colors.getBlueCornflower()}
      data={devices}
      minRow={1}
      maxRow={3}
      minColumn={1}
      maxColumn={4}
      minContentRatio={10 / 9}
      maxContentRatio={18 / 9}
      targetContentRatio={16 / 9}
      offsetRatioHeightItem={-60} // height of footer + header of PlayerContainer
      minContentResolution={200 * 200}
      renderItem={renderItem}
      keyExtractor={(_index : number, item : DeviceData) => item.id}
    />
  );

  return render();
};

PlayerGridList.defaultProps = {
  autoAdjust: true,
  freezeAutoAdjust: false,
  onClick: undefined,
  className: undefined,
  style: undefined,
};

export default PlayerGridList;
