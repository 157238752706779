import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getToken, TokenType } from '../../common/helpers/storage';
import { AuthStatus, Organization, PasswordResetState, User } from './interfaces';

interface SessionState {
  isFetching: boolean,
  user: User | null,
  organization: Organization | null,
  token: string | null,
  refreshToken: string | null,
  isRefreshingToken: boolean,
  authStatus: AuthStatus,
  passwordResetState: PasswordResetState,
}

const initialState: SessionState = {
  isFetching: false,
  user: null,
  organization: null,
  token: getToken(),
  refreshToken: getToken(TokenType.Refresh),
  isRefreshingToken: false,
  authStatus: getToken() ? AuthStatus.Loading : AuthStatus.Out,
  passwordResetState: PasswordResetState.Idle,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingToken = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.authStatus = action.payload;
    },
    setPasswordResetState: (state, action: PayloadAction<PasswordResetState>) => {
      state.passwordResetState = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    resetPasswordResetState: (state) => {
      state.passwordResetState = PasswordResetState.Idle;
    },
    reset: (state) => {
      state.isFetching = false;
      state.token = getToken();
      state.refreshToken = getToken(TokenType.Refresh);
      state.isRefreshingToken = false;
      state.user = null;
      state.authStatus = getToken() ? AuthStatus.Loading : AuthStatus.Out;
      state.passwordResetState = PasswordResetState.Idle;
    },
  },
});

export const {
  setUser,
  setOrganization,
  setToken,
  setRefreshToken,
  setIsRefreshingToken,
  setAuthStatus,
  setPasswordResetState,
  setIsFetching,
  resetPasswordResetState,
  reset,
} = sessionSlice.actions;

export default sessionSlice.reducer;
