export enum RTCSignlingEvent {
  SDP = 'SDP',
  ICE_CANDIDATE = 'ICE_CANDIDATE',
}

export interface RTCSignlingEmitterData {
  [RTCSignlingEvent.SDP] :RTCSessionDescriptionInit | null;
  [RTCSignlingEvent.ICE_CANDIDATE] : RTCIceCandidateInit | null;
}

export type RTCSignlingEmitter = <E extends keyof RTCSignlingEmitterData>(
  event : E,
  data : RTCSignlingEmitterData[E]
) => void;

export interface RTCSignlingReceiverData {
  [RTCSignlingEvent.SDP] : RTCSessionDescriptionInit;
  [RTCSignlingEvent.ICE_CANDIDATE] : RTCIceCandidateInit;
}

export class RTCCommunication {
  // DEBUG
  private log? : (...args : any[]) => void;

  private emitter : RTCSignlingEmitter;
  readonly peerConnection : RTCPeerConnection;

  public onTrack? : (ev : RTCTrackEvent) => void;
  public onError? : (error? : any) => void;

  constructor(
    emitter : RTCSignlingEmitter,
    configuration?: RTCConfiguration,
  ) {
    this.emitter = emitter;
    this.peerConnection = this.connectRTC(configuration);
    // eslint-disable-next-line no-console, max-len
    // this.log = (...args : string[]) => console.log('[WebRTCSession] - ', ...(args || []));
  }

  public emit<E extends keyof RTCSignlingReceiverData>(
    event : E,
    data : RTCSignlingReceiverData[E],
  ) : void {
    if (event === RTCSignlingEvent.SDP) {
      this.processSdp(data as RTCSignlingReceiverData[RTCSignlingEvent.SDP]);
    } else if (event === RTCSignlingEvent.ICE_CANDIDATE) {
      this.processIceCandidate(data as RTCSignlingReceiverData[RTCSignlingEvent.ICE_CANDIDATE]);
    }
  }

  private connectRTC(configuration?: RTCConfiguration) : RTCPeerConnection {
    const peerConnection = new RTCPeerConnection(configuration);
    peerConnection.onicecandidate = this.onIceCandidate.bind(this);
    peerConnection.onnegotiationneeded = this.onNegotiationNeeded.bind(this);
    peerConnection.ontrack = (ev : RTCTrackEvent) => this.onTrack?.(ev);
    return peerConnection;
  }

  private async processOfferCreated(description : RTCSessionDescriptionInit) : Promise<void> {
    if (!this.peerConnection) return;
    await this.peerConnection.setLocalDescription(description);
    this.emitter(RTCSignlingEvent.SDP, this.peerConnection.localDescription);
  }

  private async processSdp(data : RTCSessionDescriptionInit) : Promise<void> {
    this.log?.('processSdp : ', data);
    if (!this.peerConnection) return;
    try {
      await this.peerConnection.setRemoteDescription(data);
      if (this.peerConnection?.remoteDescription?.type === 'offer') {
        const description : RTCSessionDescriptionInit = await this.peerConnection.createAnswer();
        await this.processOfferCreated(description);
      }
    } catch (err : any) {
      this.onError?.(err);
    }
  }

  private async processIceCandidate(data : RTCIceCandidateInit) : Promise<void> {
    this.log?.('processIceCandidate : ', data);
    if (!this.peerConnection) return;
    try {
      await this.peerConnection.addIceCandidate(data);
    } catch (err : any) {
      this.onError?.(err);
    }
  }

  private onIceCandidate(ev : RTCPeerConnectionIceEventInit) : void {
    this.log?.('rtcOnIceCandidate : ', ev);
    this.emitter(RTCSignlingEvent.ICE_CANDIDATE, ev.candidate || null);
  }

  private async onNegotiationNeeded(ev : Event) : Promise<void> {
    this.log?.('rtcOnNegotiationNeeded : ', ev);
    if (!this.peerConnection) return;
    try {
      const description : RTCSessionDescriptionInit = await this.peerConnection.createOffer();
      await this.processOfferCreated(description);
    } catch (err : any) {
      this.onError?.(err);
    }
  }

  close() {
    this.peerConnection.close();
  }

  addStream(stream : MediaStream) {
    stream.getTracks().forEach((track) => {
      this.addTrack(track, stream);
    });
  }

  addTrack(track : MediaStreamTrack, stream?: MediaStream) {
    this.peerConnection.addTransceiver(track, { streams: stream ? [stream] : [] });
  }
}
