import React, { useState, useEffect, useRef } from 'react';

// Define general type for useWindowSize hook, which includes width and height
export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export interface Position {
  x: number;
  y: number;
}

export interface Positions {
  relative?: Position;
  absolute?: Position;
}

// Hook
export function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useElementSize<T extends HTMLElement>(
  ref : React.RefObject<T> | React.MutableRefObject<T | undefined>,
  enable : boolean = true,
): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const windowSize = useWindowSize();
  const [size, setSize] = useState<Size>(windowSize);
  const [result, setResult] = useState<Size>(size);

  useEffect(() => {
    let observer : ResizeObserver;
    if (ref.current) {
      const onSize = () => {
        if (ref.current?.clientWidth !== size.width
          || ref.current?.clientHeight !== size.height) {
          setSize({
            width: ref.current?.clientWidth,
            height: ref.current?.clientHeight,
          });
        }
      };
      onSize();
      observer = new ResizeObserver(() => {
        onSize();
      });
      observer.observe(ref.current);
    }
    return () => observer?.disconnect();
  });

  useEffect(() => {
    if (enable) {
      setResult(size);
    }
  }, [size]);

  return result;
}

export const useWindowUnfocused = (callback : () => void) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef<() => void>();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleWindowUnfocus = () => {
      if (callbackRef.current) callbackRef.current();
    };
    window.addEventListener('blur', handleWindowUnfocus);
    // cleanup this component
    return () => {
      window.removeEventListener('blur', handleWindowUnfocus);
    };
  }, []);
};

export interface ElementHoveredDetails {
  isHovered: boolean;
  isTouching: boolean;
  positions: Positions;
  mouseEvent?: MouseEvent;
}

export interface ElementHoveredOptions {
  // allow to detect touch for mobile
  enableTouch?: boolean;
  /* Hover used will be on :
   *  0 => no parent.
   *  1 => parent of target
   *  2 => parent of parent of target
   * ...
  */
  hoverOnParentLevel?: number;
}

export const elementHoveredOptionsDefault : ElementHoveredOptions = {
  enableTouch: false,
  hoverOnParentLevel: 0,
};

/**
   * Allow to detect hover on target or on parent of target.
   * @argument ref reference of target
   * @argument onChange callback trigger on every change
   * @argument opts
   */
export function useElementHovered<T extends HTMLElement>(
  ref: React.RefObject<T> | React.MutableRefObject<T | undefined>,
  onChange?: (details: ElementHoveredDetails) => void,
  opts?: ElementHoveredOptions,
) {
  const options : ElementHoveredOptions = { ...elementHoveredOptionsDefault, ...(opts ?? {}) };
  const detailsRef = useRef<ElementHoveredDetails>({
    isHovered: false,
    isTouching: false,
    positions: {},
    mouseEvent: undefined,
  });

  useEffect(() => {
    let element: HTMLElement | null | undefined = ref.current;
    for (let level = 0; level < options.hoverOnParentLevel!; level += 1) {
      if (!element) {
        element = null;
        break;
      }
      element = element.parentElement;
    }
    if (element) {
      const getPositions = (e: MouseEvent | Touch) => ({
        relative: {
          x: e?.clientX,
          y: e?.clientY,
        },
        absolute: {
          x: e?.pageX,
          y: e?.pageY,
        },
      });
      const handleTouchStart = (e: TouchEvent) => {
        if (!options.enableTouch) return;
        if (!detailsRef.current.isTouching) {
          if (e.touches?.length) {
            const touch = e.touches[0];
            detailsRef.current.positions = getPositions(touch);
          }
          detailsRef.current.isTouching = true;
          onChange?.(detailsRef.current);
        }
      };
      const handleTouchEnd = (_: TouchEvent) => {
        if (!options.enableTouch) return;
        if (detailsRef.current.isTouching) {
          setTimeout(() => {
            detailsRef.current.isTouching = false;
            onChange?.(detailsRef.current);
          }, 100); // hack to have touchEnd after click event
        }
      };
      element.addEventListener('touchstart', handleTouchStart, { passive: false });
      element.addEventListener('touchend', handleTouchEnd, { passive: true });
      const handleMouseEnter = (e: MouseEvent) => {
        if ((e as any)?.sourceCapabilities?.firesTouchEvents) return;
        detailsRef.current.isHovered = true;
        detailsRef.current.positions = getPositions(e);
        detailsRef.current.mouseEvent = e;
        onChange?.(detailsRef.current);
      };
      const handleMouseMove = (e: MouseEvent) => {
        if ((e as any)?.sourceCapabilities?.firesTouchEvents) return;
        detailsRef.current.positions = getPositions(e);
        detailsRef.current.mouseEvent = e;
        onChange?.(detailsRef.current);
      };
      const handleMouseExit = (e: MouseEvent) => {
        if ((e as any)?.sourceCapabilities?.firesTouchEvents) return;
        detailsRef.current.isHovered = false;
        detailsRef.current.positions = getPositions(e);
        onChange?.(detailsRef.current);
      };
      element.addEventListener('mouseenter', handleMouseEnter, { passive: true });
      element.addEventListener('mousemove', handleMouseMove, { passive: true });
      element.addEventListener('mouseleave', handleMouseExit, { passive: true });

      return () => {
        element?.removeEventListener('touchstart', handleTouchStart);
        element?.removeEventListener('touchend', handleTouchEnd);
        element?.removeEventListener('mouseenter', handleMouseEnter);
        element?.removeEventListener('mousemove', handleMouseMove);
        element?.removeEventListener('mouseleave', handleMouseExit);
      };
    }
    return () => null;
  });
}
