import React, { ForwardedRef } from 'react';
import 'flag-icons/css/flag-icons.min.css'; // https://github.com/lipis/flag-icons/blob/main/css/flag-icons.css
import { CountryCode } from 'countries-and-timezones';

import styles from './CountryFlag.module.css';

export interface CountryFlagProps {
  countryCode: CountryCode | undefined;
  className?: string;
  style?: React.CSSProperties;
}

export interface CountryFlagRef {}

const CountryFlag = React.forwardRef(({
  countryCode,
  className,
  style,
}: CountryFlagProps, forwardRef: ForwardedRef<CountryFlagRef | undefined>) => {
  const classes = [styles.container];
  if (className) classes.push(className);
  classes.push('fi-xx'); // default if no flag
  if (countryCode) classes.push(`fi-${countryCode.toLowerCase()}`);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    />
  );
});

CountryFlag.defaultProps = {
  className: undefined,
  style: undefined,
};

export default CountryFlag;
