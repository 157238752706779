import { useEffect, useRef } from 'react';

export enum KeyboardAction {
  UP,
  DOWN,
}
export type UseKayboardTouchedCallback = (
  action : KeyboardAction,
  key : string,
  repeat : boolean,
  event : KeyboardEvent) => void;
export const useKeyboardTouched = (callback : UseKayboardTouchedCallback) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef<UseKayboardTouchedCallback>();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleKeyAction = (action : KeyboardAction) => (
      (event : KeyboardEvent) => {
        const { key, repeat } = event;
        if (callbackRef.current) callbackRef.current(action, key, repeat, event);
      }
    );

    const handleKeyDown = handleKeyAction(KeyboardAction.DOWN);
    const handleKeyUp = handleKeyAction(KeyboardAction.UP);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
};
