import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../hooks/window';
import TouchableImage from '../utils/TouchableImage';
import { MenuDisplayMode } from './menu.constant';
import { useAppTranslation } from '../../hooks/translation';
import { Strings } from '../../constants/translation';
import MenuItems, { MenuItemName } from './MenuItems';

import { ReactComponent as Logo } from '../../../assets/logo/logo-animated.svg';
import { ReactComponent as SmallLogo } from '../../../assets/logo/small-logo-animated.svg';
import IconDisconnect from '../../../assets/icons/disconnect.svg';
import IconChevron from '../../../assets/icons/chevron.svg';

import styles from './Menu.module.css';

const DEFAULT_DISPLAY = true;
const DEFAULT_DISPLAY_MODE = MenuDisplayMode.DEFAULT;
const THRESHOLD_WIDTH_OVERLAY = 1000;

export interface MenuProps {
  display?: boolean;
  displayMode?: MenuDisplayMode;
  onDisplayMode?: (mode: MenuDisplayMode) => void;
  onNavigateTo?: (itemName : MenuItemName, path: string) => void;
  enableSwitchDisplayMode?: boolean;
  className?: string;
  style?: React.CSSProperties;
}
export interface MenuRef {
  show: () => void;
  hide: () => void;
  setDisplayMode: (mode: MenuDisplayMode) => void;
}

const Menu = React.forwardRef((
  {
    display,
    displayMode,
    onDisplayMode,
    onNavigateTo,
    enableSwitchDisplayMode,
    className,
    style,
  }: MenuProps,
  forwardRef: React.ForwardedRef<MenuRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(display ?? DEFAULT_DISPLAY);
  const [mode, setMode] = useState<MenuDisplayMode>(displayMode ?? DEFAULT_DISPLAY_MODE);
  const [inOverlay, setInOverlay] = useState<boolean>(false);
  const size = useWindowSize();

  if (size?.width) {
    if (inOverlay && size.width > THRESHOLD_WIDTH_OVERLAY) {
      setShow(true);
      setInOverlay(false);
    } else if (!inOverlay && size.width < THRESHOLD_WIDTH_OVERLAY) {
      setShow(display ?? DEFAULT_DISPLAY); // reset last value
      setInOverlay(true);
    }
  }

  useImperativeHandle(forwardRef, () => ({
    show: () => setShow(true),
    hide: () => setShow(false),
    setDisplayMode: (m: MenuDisplayMode) => setMode(m),
  }));

  useEffect(() => {
    onDisplayMode?.(mode);
  }, [mode]);

  useEffect(() => {
    setShow(display ?? DEFAULT_DISPLAY);
  }, [display]);

  useEffect(() => {
    setMode(displayMode ?? DEFAULT_DISPLAY_MODE);
  }, [displayMode]);

  const renderHeader = () => (
    <div
      className={styles.header}
    >
      <Logo
        className={styles.logo}
      />
      <SmallLogo
        className={styles.smallLogo}
      />
      {
        enableSwitchDisplayMode ? (
          <TouchableImage
            className={styles.buttonDisplayMode}
            src={IconChevron}
            alt=""
            imageClassName={styles.iconDisplayMode}
            onPress={() => {
              if (mode === MenuDisplayMode.SMALL) setMode(MenuDisplayMode.DEFAULT);
              else setMode(MenuDisplayMode.SMALL);
            }}
          />
        ) : null
      }
    </div>
  );

  const renderDisconnect = () => (
    <Link
      to="../logout"
      className={styles.disconnectContainer}
    >
      <TouchableImage
        className={styles.disconnectLogo}
        src={IconDisconnect}
        alt="Disconnect"
        onPressOut={() => {
          navigate('../logout');
        }}
      />
      <div
        className={styles.disconnectLabel}
      >
        {t(Strings.MENU_LOGOUT)}
      </div>
    </Link>
  );

  const renderContent = () => (
    <div
      className={styles.content}
    >
      <MenuItems
        className={styles.items}
        displayMode={mode}
        onNavigateTo={(itemName: MenuItemName, path : string) => {
          onNavigateTo?.(itemName, path);
        }}
      />
      {renderDisconnect()}
    </div>
  );

  const classes = [styles.container];
  classes.push(show ? styles.show : styles.hide);
  classes.push(mode === MenuDisplayMode.SMALL
    ? styles.displayModeSmall : styles.displayModeDefault);
  if (inOverlay) {
    if (mode !== MenuDisplayMode.SMALL || !show) {
      classes.push(styles.overlay);
    }
  }
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      {renderHeader()}
      {renderContent()}
    </div>
  );
});

Menu.defaultProps = {
  display: DEFAULT_DISPLAY,
  displayMode: DEFAULT_DISPLAY_MODE,
  onNavigateTo: undefined,
  enableSwitchDisplayMode: true,
  onDisplayMode: undefined,
  className: undefined,
  style: undefined,
};

export default Menu;
