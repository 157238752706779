import React, { ForwardedRef, useRef } from 'react';
import CountryAndTimezones, { TimezoneName } from 'countries-and-timezones';
import Timezone from 'timezone-enum';
import CountryFlag from './CountryFlag';

import styles from './TimezoneLabel.module.css';
import MessageOnHover, { MessageOnHoverMode } from './MessageOnHover';

const DEFAULT_TIMEZONE_NAME_ON_HOVER = true;

export enum TimezoneLabelOffsetFormat {
  UTC = 'utc',
  DST = 'dst', // https://en.wikipedia.org/wiki/Daylight_saving_time
}

export interface TimezoneLabelProps {
  timezone: TimezoneName | Timezone;
  formatOffset?: TimezoneLabelOffsetFormat,
  flag?: boolean;
  timezoneNameOnHover?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export interface TimezoneLabelRef {}

const TimezoneLabel = React.forwardRef(({
  timezone,
  formatOffset,
  flag,
  timezoneNameOnHover,
  className,
  style,
}: TimezoneLabelProps, forwardRef: ForwardedRef<TimezoneLabelRef | undefined>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const timezoneDetails = CountryAndTimezones.getTimezone(timezone);
  const country = CountryAndTimezones.getCountryForTimezone(timezone);

  const renderText = () => {
    if (!timezoneDetails) return '-';
    if (formatOffset === TimezoneLabelOffsetFormat.DST) {
      return timezoneDetails.dstOffsetStr;
    }
    return timezoneDetails.utcOffsetStr;
  };

  const renderFlag = () => {
    if (!flag || !country) return null;
    return (
      <CountryFlag
        countryCode={country.id}
      />
    );
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {
        timezoneNameOnHover ? (
          <MessageOnHover
            targetRef={containerRef}
            mode={MessageOnHoverMode.INFO}
            message={timezone}
          />
        ) : null
      }
      { renderText() }
      { renderFlag() }
    </div>
  );
});

TimezoneLabel.defaultProps = {
  flag: true,
  formatOffset: TimezoneLabelOffsetFormat.DST,
  timezoneNameOnHover: DEFAULT_TIMEZONE_NAME_ON_HOVER,
  className: undefined,
  style: undefined,
};

export default TimezoneLabel;
