import { DeviceSetSystemTimeRequest, DeviceSetSystemTimeResult, DeviceSetSystemTimezoneRequest, DeviceSetSystemTimezoneResult } from '@kalyzee/kast-websocket-module';
import { TimezoneName } from 'countries-and-timezones';
import React, { useRef, useState } from 'react';
import Timezone from 'timezone-enum';
import { useSocketAppDispatch } from '../../../app/hooks';
import PageContainer from '../../../common/components/page/PageContainer';
import TableTimeDevices, { TableTimeDevicesRef } from '../../../common/components/table/time/TableTimeDevices';
import { TableTimeDevicesAction, TableTimeDevicesData } from '../../../common/components/table/time/TableTimeDevices.constant';
import Button from '../../../common/components/utils/Button';
import DateTimeLabel from '../../../common/components/utils/DateTimeLabel';
import MessageOnHover, { MessageOnHoverMode } from '../../../common/components/utils/MessageOnHover';
import { PopupButtonType, PopupIconType, PopupId, PopupRef } from '../../../common/components/utils/Popup';
import { showPopup } from '../../../common/components/utils/PopupContainer';
import TimezoneSelector from '../../../common/components/utils/TimezoneSelector';
import { Strings } from '../../../common/constants/translation';
import { awaitWithMinimumDelay, getCurrentTimezone } from '../../../common/helpers/utils';
import { useRender } from '../../../common/hooks/component';
import { useAppTranslation } from '../../../common/hooks/translation';
import { setSystemTime, setSystemTimezone } from '../../../features/device/actions';
import { useDevices } from '../../../features/device/hooks';
import { Device } from '../../../features/device/interfaces';
import { getDeviceName } from '../../../features/device/utils';

import styles from './devices.time.module.css';

const DevicesTimePage = () => {
  const { t } = useAppTranslation();
  const render = useRender();
  const [loading, setLoading] = useState<boolean>(true);
  const socketDispatch = useSocketAppDispatch();
  const devices: Device[] = useDevices();
  const tableDataRef = useRef<TableTimeDevicesData[]>([]);
  const [itemsChecked, setItemChecked] = useState<TableTimeDevicesData[]>([]);
  const tableRef = useRef<TableTimeDevicesRef>();

  if (devices.length && loading) {
    setLoading(false);
  }

  const updateItemChecked = () => {
    const current = tableDataRef.current.filter((d) => d.checked);
    let update = false;
    if (current.length === itemsChecked.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currItem1 of current) {
        if (!itemsChecked.find((currItem2) => currItem1.deviceId === currItem2.deviceId)) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) setItemChecked(current);
  };
  updateItemChecked();
  const onItemCheck = (item: TableTimeDevicesData) => {
    updateItemChecked();
  };

  const generateTableData = (): TableTimeDevicesData[] => {
    if (loading) return tableDataRef.current;
    const result: TableTimeDevicesData[] = [];
    devices.forEach((device) => {
      const deviceId = device.id;
      const currentData: TableTimeDevicesData | undefined = tableDataRef.current.find(
        (d) => d.deviceId === deviceId,
      );
      const online = !!device?.online;
      const timezone = device.context?.systemContext?.timezone ?? Timezone.GMT0;
      const processing = online ? currentData?.processing : undefined;
      const success = online ? currentData?.success : false;
      const error = online ? currentData?.error : undefined;
      const newData: TableTimeDevicesData = {
        deviceId,
        device,
        name: getDeviceName(device, false),
        online,
        checked: currentData?.checked ?? false,
        timeOffset: device.timeOffset,
        timezone,
        processing,
        success,
        error,
      };
      const data: TableTimeDevicesData = Object.assign(currentData ?? {}, newData);
      result.push(data);
    });
    tableDataRef.current = result;
    return tableDataRef.current;
  };

  const renderTable = () => (
    <TableTimeDevices
      className={styles.table}
      ref={tableRef}
      onItemChecked={onItemCheck}
      data={generateTableData()}
      onAction={(
        action: TableTimeDevicesAction,
        item: TableTimeDevicesData,
      ) => {
        if (action === TableTimeDevicesAction.CLEAN_ERROR) {
          item.error = undefined;
          tableRef.current?.render();
          render();
        }
      }}
    />
  );

  // ---------------- POPUP ------------ //

  const showPopupWarningTimeSynchro = (
    items: TableTimeDevicesData[],
    successCallback?: () => void,
  ) => {
    const content = t(Strings.TIME_POPUP_WARNING_TIME_SYNCHRO_CONTENT);
    const displayed = showPopup({
      id: PopupId.WARNING_TIME_SYNCHRO,
      title: t(Strings.TIME_POPUP_WARNING_TIME_SYNCHRO_TITLE),
      buttons: [
        {
          element: t(Strings.TIME_POPUP_WARNING_TIME_SYNCHRO_CANCEL),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(Strings.TIME_POPUP_WARNING_TIME_SYNCHRO_VALIDATE),
          type: PopupButtonType.VALIDATE,
          onClick: () => {
            successCallback?.();
            return true;
          },
        },
      ],
      content,
      iconContent: PopupIconType.WARNING,
      enableCloseButton: false,
      enableNotShowAgain: true,
    });
    // not show again
    if (!displayed) {
      successCallback?.();
    }
  };

  const showPopupUpdateTimezone = (
    items: TableTimeDevicesData[],
    successCallback?: (timezone : TimezoneName) => void,
  ) => {
    const popupRef : React.MutableRefObject<PopupRef | null> = { current: null };
    let currentTimezone = getCurrentTimezone() as TimezoneName;
    const getContent = () => (
      <div className={styles.popupUpdateTimezoneContent}>
        <div className={styles.popupUpdateTimezoneTitle}>
          {t(Strings.TIME_POPUP_UPDATE_TIMEZONE_CONTENT_MESSAGE)}
        </div>
        <TimezoneSelector
          className={styles.timezoneSelector}
          timezone={currentTimezone}
          onChange={(timezone : TimezoneName) => {
            currentTimezone = timezone;
            popupRef.current?.updateContent(getContent());
          }}
        />
        <div className={styles.popupUpdateTimezoneValue}>
          {t(Strings.TIME_POPUP_UPDATE_TIMEZONE_CONTENT_SELECTED, { timezone: currentTimezone })}
        </div>
      </div>
    );
    showPopup({
      id: PopupId.UPDATE_TIMEZONE,
      title: t(Strings.TIME_POPUP_UPDATE_TIMEZONE_TITLE),
      buttons: [
        {
          element: t(Strings.TIME_POPUP_UPDATE_TIMEZONE_CANCEL),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(Strings.TIME_POPUP_UPDATE_TIMEZONE_VALIDATION),
          type: PopupButtonType.VALIDATE,
          onClick: () => {
            successCallback?.(currentTimezone);
            return true;
          },
        },
      ],
      content: getContent(),
      enableCloseButton: false,
      enableNotShowAgain: false,
    }, popupRef);
  };

  // ---------------- REQUEST ------------ //

  const synchroTimeForDevice = async (
    item: TableTimeDevicesData,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId } = item;

    item.processing = t(Strings.TIME_SYNCHRO_PROCESSING_MESSAGE);
    if (rerender) tableRef.current?.render();
    const payload: DeviceSetSystemTimeRequest = {
      deviceId,
    };
    const result: DeviceSetSystemTimeResult = await awaitWithMinimumDelay(
      socketDispatch(setSystemTime(payload)),
      1000,
    );

    if (result.error) {
      item.error = t(Strings.TIME_SYNCHRO_ERROR_MESSAGE);
    } else {
      item.success = true;
      item.checked = false;
      updateItemChecked();
      // Show success during 5seconds
      setTimeout(() => {
        item.success = false;
        if (rerender) tableRef.current?.render();
      }, 1000);
    }
    item.processing = undefined;

    if (rerender) tableRef.current?.render();
    return true;
  };

  const synchroTimeForData = async (items: TableTimeDevicesData[]) => {
    items.forEach((item) => {
      synchroTimeForDevice(item);
    });
    render();
  };

  const updateTimezoneForDevice = async (
    item: TableTimeDevicesData,
    timezone: TimezoneName,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId } = item;

    item.processing = t(Strings.TIME_UPDATE_TIMEZONE_PROCESSING_MESSAGE);
    if (rerender) tableRef.current?.render();
    const payload: DeviceSetSystemTimezoneRequest = {
      deviceId,
      timezone: timezone as Timezone,
    };
    const result: DeviceSetSystemTimezoneResult = await awaitWithMinimumDelay(
      socketDispatch(setSystemTimezone(payload)),
      1000,
    );

    if (result.error) {
      item.error = t(Strings.TIME_UPDATE_TIMEZONE_ERROR_MESSAGE);
    } else {
      item.success = true;
      item.checked = false;
      updateItemChecked();
      // Show success during 5seconds
      setTimeout(() => {
        item.success = false;
        if (rerender) tableRef.current?.render();
      }, 1000);
    }
    item.processing = undefined;

    if (rerender) tableRef.current?.render();
    return true;
  };

  const updateTimezoneForData = async (items: TableTimeDevicesData[], timezone : TimezoneName) => {
    items.forEach((item) => {
      updateTimezoneForDevice(item, timezone);
    });
    render();
  };

  // ---------------- BUTTONS ------------ //

  const renderUpdateTimeButton = () => {
    const buttonSupportRef = { current: null };
    const itemsAvailable = tableDataRef.current.filter((v) => (
      v.online && !v.processing && !v.error && !v.success
    ));
    const itemsAvailableChecked = itemsAvailable.filter((v) => v.checked);
    const buttonEnabled = itemsAvailableChecked.length;
    if (!itemsAvailable.length) return null;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.TIME_BUTTON_SYNCHRO_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(Strings.TIME_BUTTON_SYNCHRO, { details: buttonEnabled ? ` (${itemsAvailableChecked.length})` : '' })}
            onPress={() => {
              showPopupWarningTimeSynchro(itemsAvailableChecked, () => {
                synchroTimeForData(itemsAvailableChecked);
              });
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderUpdateTimezoneButton = () => {
    const buttonSupportRef = { current: null };
    const itemsAvailable = tableDataRef.current.filter((v) => (
      v.online && !v.processing && !v.error && !v.success
    ));
    const itemsAvailableChecked = itemsAvailable.filter((v) => v.checked);
    const buttonEnabled = itemsAvailableChecked.length;
    if (!itemsAvailable.length) return null;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.TIME_BUTTON_UPDATE_TIMEZONE_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(Strings.TIME_BUTTON_UPDATE_TIMEZONE, { details: buttonEnabled ? ` (${itemsAvailableChecked.length})` : '' })}
            onPress={() => {
              showPopupUpdateTimezone(itemsAvailableChecked, (timezone : TimezoneName) => {
                updateTimezoneForData(itemsAvailableChecked, timezone);
              });
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderButtons = () => (
    <div
      className={styles.buttonContainer}
    >
      {renderUpdateTimeButton()}
      {renderUpdateTimezoneButton()}
    </div>
  );

  // ---------------- NO DATA ------------ //

  const renderNoDevices = () => (
    <div className={styles.emptyDataMessage}>
      {t(Strings.TIME_NO_DEVICES)}
    </div>
  );

  const notEmptyData = devices.length > 0;
  return (
    <PageContainer
      title="Appareils"
      subtitle="Heure"
      loading={loading}
    >
      <div
        className={styles.dateContainer}
      >
        <span
          className={styles.dateTitle}
        >
          {t(Strings.TIME_CURRENT_DATE)}
        </span>
        <DateTimeLabel
          dateTime={new Date()}
          className={styles.dateValue}
          timezone={getCurrentTimezone()}
        />
      </div>
      <div
        className={styles.container}
      >
        {notEmptyData ? renderTable() : renderNoDevices()}
      </div>
      {notEmptyData ? renderButtons() : null}
    </PageContainer>
  );
};

export default DevicesTimePage;
