import React from 'react';
import AppVersion from '../../common/components/app/AppVersion';
import LoginForm from '../../features/session/LoginForm';

import styles from './login.module.css';

const LoginPage = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <div className={styles.blurredBackground} />
    <div className={styles.container}>
      <div className={styles.form}>
        <LoginForm />
        <AppVersion />
      </div>
    </div>
  </div>
);

export default LoginPage;
