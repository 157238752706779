import React, { useState, useEffect, useRef } from 'react';
import { Positions } from './window';

export type UseOuterClickCallback = (e : Event) => void;
export const useOuterClick = <T extends HTMLElement>(
  ref : React.RefObject<T> | React.MutableRefObject<T | undefined>,
  callback : UseOuterClickCallback,
) : void => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef<UseOuterClickCallback | undefined>(undefined);

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e : Event) => {
      if (ref.current && callbackRef.current && !ref.current.contains(e.target as Node)) {
        callbackRef.current(e);
      }
    };
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick, { passive: true });
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  });
};

export const useOuterClickRef = <T extends HTMLElement>(callback : UseOuterClickCallback) :
React.RefObject<T> => {
  const innerRef = useRef<T>(null);
  useOuterClick(innerRef, callback);
  return innerRef; // convenience for client (doesn't need to init ref himself)
};

export const useOuterClickManyTargets = <T extends HTMLElement>(
  refs : (React.RefObject<T> | React.MutableRefObject<T | undefined>)[],
  callback : UseOuterClickCallback,
) : void => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef<UseOuterClickCallback | undefined>(undefined);

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e : Event) => {
      let isOuter : boolean | undefined;
      refs.forEach((ref) => {
        if (ref.current) {
          if (isOuter === undefined || isOuter === true) {
            isOuter = !ref.current.contains(e.target as Node);
          }
        }
      });
      if (isOuter === true) {
        callbackRef.current?.(e);
      }
    };
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick, { passive: true });
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  });
};
export enum UseOnPressDirecion {
  PRESS_IN,
  PRESS_OUT,
}
export type UseOnPressCallback = (e : UseOnPressDirecion) => void;
export const useOnPress = <T extends HTMLElement>(
  ref : React.RefObject<T> | React.MutableRefObject<T | undefined>,
  callback : UseOnPressCallback,
  disableRightClick : boolean = true,
) : void => {
  const [pressed, setPressed] = useState<boolean>(false);
  // initialize mutable ref, which stores callback
  const callbackRef = useRef<UseOnPressCallback>();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });
  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    let handleTouchStart : (e : TouchEvent) => boolean;
    let handleTouchEnd : (e : TouchEvent) => boolean;
    let handlePressIn : (e : MouseEvent) => boolean;
    let handlePressOut : (e : MouseEvent) => boolean;
    let handleClick : (e : MouseEvent) => void;
    const element = ref.current;
    if (element) {
      element.oncontextmenu = (ev : MouseEvent) => {
        if (!disableRightClick) return;
        ev.preventDefault();
      };

      handleTouchStart = (e : TouchEvent) => {
        callbackRef.current?.(UseOnPressDirecion.PRESS_IN);
        setPressed(true);
        e.stopPropagation();
        e.preventDefault();
        return false;
      };
      handleTouchEnd = (e : TouchEvent) => {
        if (pressed) {
          callbackRef.current?.(UseOnPressDirecion.PRESS_OUT);
          e.stopPropagation();
          return false;
        }
        return true;
      };
      element.addEventListener('touchstart', handleTouchStart, { passive: false });
      element.addEventListener('touchend', handleTouchEnd, { passive: true });
      element.addEventListener('touchcancel', handleTouchEnd, { passive: true });
      handlePressIn = (e : MouseEvent) => {
        if ((e as any)?.sourceCapabilities?.firesTouchEvents) return false;
        callbackRef.current?.(UseOnPressDirecion.PRESS_IN);
        setPressed(true);
        e.stopPropagation();
        e.preventDefault();
        return false;
      };
      handlePressOut = (e : MouseEvent) => {
        if ((e as any)?.sourceCapabilities?.firesTouchEvents) return false;
        setPressed(false);
        if (pressed) {
          callbackRef.current?.(UseOnPressDirecion.PRESS_OUT);
          e.stopPropagation();
          return false;
        }
        return true;
      };
      handleClick = (e : MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
      };
      element.addEventListener('mousedown', handlePressIn, { passive: false });
      element.addEventListener('mouseup', handlePressOut, { passive: true });
      element.addEventListener('mouseleave', handlePressOut, { passive: true });
      element.addEventListener('click', handleClick, { passive: false });
    }

    return () => {
      if (element) {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchend', handleTouchEnd);
        element.removeEventListener('touchcancel', handleTouchEnd);
        element.removeEventListener('mousedown', handlePressIn);
        element.removeEventListener('mouseup', handlePressOut);
        element.removeEventListener('mouseleave', handlePressOut);
        element.removeEventListener('click', handleClick);
      }
    };
  });
};

export const useOnPressRef = <T extends HTMLElement>(callback : UseOnPressCallback) :
React.RefObject<T> => {
  // returned to client, who marks "border" element
  const innerRef = useRef<T>(null);
  useOnPress(innerRef, callback);
  return innerRef; // convenience for client (doesn't need to init ref himself)
};

export interface OnClickDetails {
  positions: Positions;
  mouseEvent: MouseEvent;
}
export const useOnClick = <T extends HTMLElement>(
  ref : React.RefObject<T> | React.MutableRefObject<T | undefined>,
  callback : (details: OnClickDetails) => void,
) => {
  // initialize mutable ref, which stores callback
  const callbackRef = useRef<(details: OnClickDetails) => void>();

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });
  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    const getPositions = (e: MouseEvent) => ({
      relative: {
        x: e?.clientX,
        y: e?.clientY,
      },
      absolute: {
        x: e?.pageX,
        y: e?.pageY,
      },
    });
    let handleClick : (event: MouseEvent) => void;
    const element = ref.current;
    if (element) {
      handleClick = (e : MouseEvent) => {
        callbackRef.current?.({
          positions: getPositions(e),
          mouseEvent: e,
        });
      };
      element.addEventListener('click', handleClick);
    }

    return () => {
      if (handleClick && element) {
        element.removeEventListener('click', handleClick);
      }
    };
  });
};
