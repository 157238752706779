import { HttpStatusCode } from '../constants/httpError';

export interface ApiParsedError {
  name: string
  message: string
  stack: string
  code: number
  cname: string
  data: any
  httpCode: HttpStatusCode,
}

export enum ErrorType {
  NetworkError, // 0 to 199
  ApiError, // Returned by the API but have a JSON body
  ParsingError, // Wrong json format
  ClientError,
}

export interface ErrorData<T = any> {
  type: ErrorType
  code?: number
  message: string
  data?: T
}

export class ResponseError extends Error {
  data: ErrorData;

  constructor(data: ErrorData) {
    super();
    this.data = data;
  }
}

export const isApiParsedError = (data: any) : data is ApiParsedError => {
  if (!data) return false;
  if (!('name' in data)) return false;
  if (!('message' in data)) return false;
  if (!('code' in data)) return false;
  if (!('cname' in data)) return false;
  if (!('httpCode' in data)) return false;
  return true;
};
