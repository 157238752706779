import { Strings } from '../../common/constants/translation';
import { ErrorData, ErrorType } from '../../common/helpers/errors';

const defaultForCode: { [key: number]: Strings } = {
  460: Strings.THE_SESSION_HAS_EXPIRED,
  403: Strings.UNAUTHORIZED_REQUEST,
};

const defaultForType: { [type in ErrorType]: Strings } = {
  [ErrorType.NetworkError]: Strings.COULD_NOT_SEND_REQUEST,
  [ErrorType.ApiError]: Strings.API_ERROR,
  [ErrorType.ClientError]: Strings.CLIENT_ERROR,
  [ErrorType.ParsingError]: Strings.PARSING_ERROR,
};

export const createError = <T = any>(
  type: ErrorType,
  code?: number,
  message?: string,
  data?: T,
): ErrorData<T> => {
  let errorMsg = message;
  if (!errorMsg) {
    if (code) {
      errorMsg = defaultForCode[code];
    }
    if (!errorMsg) {
      errorMsg = defaultForType[type];
    }
  }
  if (!errorMsg) errorMsg = Strings.AN_ERROR_OCCURED;
  const errorData: ErrorData = { message: errorMsg, type, code, data };
  return errorData;
};

export default {
  createError,
};
