import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Organization, PasswordResetState, RequestCreateRecovery, RequestGetRecoveryState, RequestLoginLocal, RequestTerminateRecovery, User } from './interfaces';

import sagaActions from './actions';
import sessionApi from './api';

import { reset, setIsFetching, setOrganization, setPasswordResetState, setRefreshToken, setToken, setUser } from './slice';
import storageHelpers, { TokenType } from '../../common/helpers/storage';
import { submitRequest } from './generators';
import { handleError } from '../error/generators';

export function* requestLoginLocalHandler(action: PayloadAction<RequestLoginLocal>) {
  try {
    yield put(setIsFetching(true));

    const {
      token,
      refreshToken,
    } = yield call(submitRequest, sessionApi.loginLocal, action.payload);

    yield call(storageHelpers.setToken, token);
    yield put(setToken(token));

    if (refreshToken) {
      yield call(storageHelpers.setToken, refreshToken, TokenType.Refresh);
      yield put(setRefreshToken(refreshToken));
    }
  } catch (error) {
    yield call(handleError, error);
  } finally {
    yield put(setIsFetching(false));
  }
}

export function* requestLogoutHandler() {
  yield call(storageHelpers.removeToken);
  yield call(storageHelpers.removeToken, TokenType.Refresh);
  yield put(reset());
}

export function* requestUserMeHandler() {
  try {
    yield put(setIsFetching(true));

    const user: User = yield call(submitRequest, sessionApi.userMe);
    yield put(setUser(user));
  } catch (error) {
    yield call(handleError, error);
  } finally {
    yield put(setIsFetching(false));
  }
}

export function* requestOrganizationHandler() {
  try {
    const organization: Organization = yield call(submitRequest, sessionApi.organization);
    yield put(setOrganization(organization));
  } catch (error) {
    yield call(handleError, error);
  }
}

function* requestCreateRecovery(action: PayloadAction<RequestCreateRecovery>) {
  try {
    yield put(setIsFetching(true));
    const result: {
      allowed?: string,
    } | undefined = yield call(submitRequest, sessionApi.createRecovery, action.payload);
    const state = result?.allowed
      ? PasswordResetState.EmailSent
      : PasswordResetState.ResetRefused;
    yield put(setPasswordResetState(state));
  } catch (error) {
    yield call(handleError, error);
  } finally {
    yield put(setIsFetching(false));
  }
}

function* requestTerminateRecovery(action: PayloadAction<RequestTerminateRecovery>) {
  try {
    yield put(setIsFetching(true));
    const result: {
      allowed?: string,
    } | undefined = yield call(submitRequest, sessionApi.terminateRecovery, action.payload);
    const state = result?.allowed
      ? PasswordResetState.Completed
      : PasswordResetState.KeyRefused;
    yield put(setPasswordResetState(state));
  } catch (error) {
    yield call(handleError, error);
  } finally {
    yield put(setIsFetching(false));
  }
}

function* requestGetRecoveryState(action: PayloadAction<RequestGetRecoveryState>) {
  try {
    yield put(setIsFetching(true));
    const result: {
      allowed?: string,
    } | undefined = yield call(submitRequest, sessionApi.getRecoveryState, action.payload);
    const state = result?.allowed
      ? PasswordResetState.UserInput
      : PasswordResetState.KeyRefused;
    yield put(setPasswordResetState(state));
  } catch (error) {
    yield call(handleError, error);
  } finally {
    yield put(setIsFetching(false));
  }
}

export default function* sessionSaga() {
  yield takeLatest(sagaActions.requestLoginLocal, requestLoginLocalHandler);
  yield takeLatest(sagaActions.requestLogout, requestLogoutHandler);
  yield takeLatest(sagaActions.requestUserMe, requestUserMeHandler);
  yield takeLatest(sagaActions.requestOrganization, requestOrganizationHandler);
  yield takeLatest(sagaActions.requestCreateRecovery, requestCreateRecovery);
  yield takeLatest(sagaActions.requestGetRecoveryState, requestGetRecoveryState);
  yield takeLatest(sagaActions.requestTerminateRecovery, requestTerminateRecovery);
}
