// eslint-disable-next-line import/prefer-default-export
export const resetAllSvgAnimations = (element: HTMLElement) => {
  if (!element) return;
  const svgElements = Array.from(element.getElementsByTagName('svg'));
  const animations: SVGAnimateElement[] = [];
  svgElements.forEach((svg) => {
    animations.push(...Array.from(svg.querySelectorAll('animate')));
    animations.push(...Array.from(svg.querySelectorAll('animateTransform')));
  });
  animations.forEach((animation) => {
    const beginAt = Number(animation.getAttribute('begin')) ?? 0;
    animation.beginElementAt(beginAt);
  });
};
