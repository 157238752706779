import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import IconChevron from '../../../assets/icons/chevron.svg';
import { ElementHoveredDetails, useElementHovered } from '../../hooks/window';
import Touchable from '../utils/Touchable';
import { MenuItemDisplayMode } from './menu.constant';

import styles from './MenuItem.module.css';

const DEFAULT_DISPLAY_MODE = MenuItemDisplayMode.DEFAULT;
const DEFAULT_DISPLAY_CHILD = true;

export interface MenuItemProps {
  iconSrc: string;
  iconAlt?: string;
  title: string;
  navigateTo?: string;
  matchLocations?: (string | RegExp)[],
  displayMode?: MenuItemDisplayMode,
  displayChild?: boolean;
  onSelected?: (value: boolean, ref: MenuItemRef) => void;
  onOpened?: (value: boolean, ref: MenuItemRef) => void;
  onMatching?: (value: boolean, ref: MenuItemRef) => void;
  onHovered?: (value: boolean, ref: MenuItemRef) => void;
  onChildHovered?: (value: boolean, ref: MenuItemRef) => void;
  onNavigateTo?: (path: string) => void;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export interface MenuItemRef {
  select: (value: boolean) => void;
  open: (value: boolean) => void;
  displayChild: (value: boolean) => void;
  isDisplayingChild: () => boolean;
  isSelected: () => boolean;
  isOpened: () => boolean;
  isMatching: () => boolean;
  isHovered: () => boolean;
  childIsHovered: () => boolean;
  getTitle: () => string;
}
const MenuItem = React.forwardRef((
  {
    iconSrc,
    iconAlt,
    title,
    navigateTo,
    matchLocations,
    displayMode,
    displayChild,
    onSelected,
    onOpened,
    onMatching,
    onHovered,
    onChildHovered,
    onNavigateTo,
    children,
    className,
    style,
  }: MenuItemProps,
  forwardRef: React.ForwardedRef<MenuItemRef | undefined>,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const childContainerRef = useRef<HTMLDivElement>(null);
  const hoveredRef = useRef(false);
  const childHoveredRef = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useState<MenuItemDisplayMode>(DEFAULT_DISPLAY_MODE);
  const [isSelected, setIsSelect] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isMatching, setIsMatching] = useState<boolean>(false);
  const [displayChildren, setDisplayChildren] = useState(displayChild ?? DEFAULT_DISPLAY_CHILD);

  const locationIsMatching = (): boolean => {
    if (!matchLocations) return false;
    const path = location.pathname;
    let matched = false;
    matchLocations.forEach((m) => {
      if (typeof m === 'string') {
        if (m === path) matched = true;
      } else if (new RegExp(m).test(path)) {
        matched = true;
      }
    });
    return matched;
  };

  const forwardedRef: MenuItemRef = {
    select: (value: boolean) => {
      setIsSelect(value);
    },
    open: (value: boolean) => {
      setIsOpened(value);
    },
    displayChild: (value: boolean) => {
      setDisplayChildren(value);
    },
    isDisplayingChild: () => displayChildren,
    isSelected: () => isSelected,
    isOpened: () => isOpened,
    isMatching: () => isMatching,
    isHovered: () => hoveredRef.current,
    childIsHovered: () => childHoveredRef.current,
    getTitle: () => title,
  };
  useImperativeHandle(forwardRef, () => forwardedRef);

  useElementHovered(containerRef, (details: ElementHoveredDetails) => {
    hoveredRef.current = details.isHovered;
    onHovered?.(details.isHovered, forwardedRef);
    if (containerRef.current) {
      if (details.isHovered) {
        containerRef.current.classList.add(styles.hovered);
      } else {
        containerRef.current.classList.remove(styles.hovered);
      }
    }
  }, { enableTouch: false });
  useElementHovered(childContainerRef, (details: ElementHoveredDetails) => {
    childHoveredRef.current = details.isHovered;
    onChildHovered?.(details.isHovered, forwardedRef);
  }, { enableTouch: false });

  const openIfIsSelected = useCallback(() => {
    if (!isSelected) return;
    setIsOpened(true);
  }, [isSelected, setIsOpened]);

  useEffect(() => {
    setDisplayChildren(displayChild ?? DEFAULT_DISPLAY_CHILD);
  }, [displayChild]);
  useEffect(() => {
    setMode(displayMode ?? DEFAULT_DISPLAY_MODE);
  }, [displayMode]);
  useEffect(() => {
    setIsMatching(locationIsMatching());
  }, [location]);
  useEffect(() => {
    onSelected?.(isSelected, forwardedRef);
  }, [isSelected]);
  useEffect(() => {
    onOpened?.(isOpened, forwardedRef);
  }, [isOpened]);
  useEffect(() => {
    onMatching?.(isMatching, forwardedRef);
  }, [isMatching]);
  useEffect(() => {
    if (mode === MenuItemDisplayMode.DEFAULT) {
      openIfIsSelected();
    }
  }, [mode]);

  useEffect(() => {
    const matched = locationIsMatching();
    if (matched) {
      setIsSelect(true);
      if (children) setIsOpened(true);
      setIsMatching(true);
    }
  }, []);

  const clickOnHeader = () => {
    if (isSelected) {
      if (children) setIsOpened(!isOpened);
    } else {
      if (children) {
        if (displayMode === MenuItemDisplayMode.ICON_ONLY) {
          if (!navigateTo) {
            setIsOpened(true);
          }
        } else {
          setIsOpened(true);
        }
      }
      setIsSelect(true);
      if (navigateTo) {
        navigate(navigateTo);
        onNavigateTo?.(navigateTo);
      }
    }
  };

  const renderHeader = () => {
    const renderIconOpeningIcon = () => {
      if (!children) return null;
      return (
        <img
          className={styles.openingIcon}
          src={IconChevron}
          alt=""
        />
      );
    };
    return (
      <div
        className={styles.headerContainer}
        ref={headerRef}
      >
        <Touchable
          onPressIn={clickOnHeader}
          className={styles.header}
        >
          <div className={styles.indicator} />
          <img
            className={styles.icon}
            src={iconSrc}
            alt={iconAlt}
          />
          {
            mode === MenuItemDisplayMode.ICON_ONLY && children ? (
              <div
                className={styles.childIndicator}
                style={{ opacity: isOpened ? '100%' : '50%' }}
              />
            ) : null
          }
          <div
            className={styles.title}
          >
            {title}
          </div>
          {
            renderIconOpeningIcon()
          }
        </Touchable>
      </div>
    );
  };

  const renderContent = () => {
    const contentStyle: React.CSSProperties = {};
    if (!isOpened) contentStyle.display = 'none';

    const renderHeaderContent = () => (
      <div className={styles.contentHeader}>
        {title}
      </div>
    );

    if ((!children || !displayChildren) && mode === MenuItemDisplayMode.ICON_ONLY) {
      return (
        <div
          className={styles.contentFromTitle}
          style={contentStyle}
        >
          <div className={styles.contentBackground} />
          <div className={styles.contentTitle}>
            {title}
          </div>
        </div>
      );
    }
    if (children && displayChildren) {
      return (
        <div
          className={styles.content}
          style={contentStyle}
          ref={childContainerRef}
        >
          <div className={styles.contentBackground} />
          {
            mode === MenuItemDisplayMode.ICON_ONLY
              ? renderHeaderContent()
              : null
          }
          <div className={styles.childContainer}>
            {children}
          </div>
        </div>
      );
    }
    return null;
  };

  const classes = [styles.container];
  if (isSelected) classes.push(styles.selected);
  if (isOpened) classes.push(styles.opened);
  if (isMatching) classes.push(styles.matched);
  if (navigateTo || mode === MenuItemDisplayMode.DEFAULT) classes.push(styles.clickable);
  if (hoveredRef.current) classes.push(styles.hovered);
  if (mode === MenuItemDisplayMode.ICON_ONLY) classes.push(styles.displayModeIconOnly);
  else classes.push(styles.displayModeDefault);
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderHeader()}
      {renderContent()}
    </div>
  );
});

MenuItem.defaultProps = {
  displayMode: DEFAULT_DISPLAY_MODE,
  displayChild: DEFAULT_DISPLAY_CHILD,
  children: undefined,
  iconAlt: undefined,
  navigateTo: undefined,
  matchLocations: undefined,
  onSelected: undefined,
  onOpened: undefined,
  onMatching: undefined,
  onHovered: undefined,
  onChildHovered: undefined,
  onNavigateTo: undefined,
  className: undefined,
  style: undefined,
};

export default MenuItem;
