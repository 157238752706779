import { MenuDisplayMode } from '../components/menu/menu.constant';
import { PopupId } from '../components/utils/Popup';
import { enumContainsString } from './utils';

export enum StorageCategory {
  TOKEN = 'token',
  MENU = 'menu',
  POPUP = 'popup',
}

export enum TokenType {
  Normal = 'normal',
  Refresh = 'refreshToken',
}

export enum MenuKeys {
  DISPLAY_MODE = 'display_mode',
}

export enum PopupKeys {
  NOT_SHOW_AGAIN = 'not_show_again',
}

const storage = localStorage;

// ---------------- NATIVE ------------------ //

const generateKey = (category : StorageCategory, key : string) => (
  `${category}_${key}`
);

const setItem = (
  category : StorageCategory,
  key: string,
  value: string,
) => storage.setItem(generateKey(category, key), value);

const getItem = (
  category : StorageCategory,
  key: string,
) => storage.getItem(generateKey(category, key));

const removeItem = (
  category : StorageCategory,
  key: string,
) => storage.removeItem(generateKey(category, key));

// ---------------- TOKEN ------------------ //

export const setToken = (
  token: string,
  tokenType: TokenType = TokenType.Normal,
) => setItem(StorageCategory.TOKEN, tokenType, token);

export const getToken = (
  tokenType: TokenType = TokenType.Normal,
) => getItem(StorageCategory.TOKEN, tokenType);

export const removeToken = (
  tokenType: TokenType = TokenType.Normal,
) => removeItem(StorageCategory.TOKEN, tokenType);

// ---------------- MENU ------------------ //
export const setMenuDisplayMode = (
  mode: MenuDisplayMode,
) => setItem(StorageCategory.MENU, MenuKeys.DISPLAY_MODE, mode);

export const getMenuDisplayMode = ()
: MenuDisplayMode | null => {
  const result = getItem(StorageCategory.MENU, MenuKeys.DISPLAY_MODE);
  if (result && enumContainsString(MenuDisplayMode, result)) {
    return result as MenuDisplayMode;
  }
  return null;
};

// ---------------- POPUP ------------------ //

export const getPopupToNotShowAgain = ()
: PopupId[] => {
  const result : PopupId[] = [];
  const strItems = getItem(StorageCategory.POPUP, PopupKeys.NOT_SHOW_AGAIN);
  let items = null;
  try {
    if (strItems) {
      items = JSON.parse(strItems);
    }
  } catch (err) { /**/ }
  if (items && Array.isArray(items)) {
    items.forEach((item) => {
      if (item && enumContainsString(PopupId, item)) {
        result.push(item);
      }
    });
  }
  return result;
};

export const addPopupToNotShowAgain = (
  id: PopupId,
) => {
  const popups = getPopupToNotShowAgain();
  if (!popups.includes(id)) {
    popups.push(id);
    setItem(StorageCategory.POPUP, PopupKeys.NOT_SHOW_AGAIN, JSON.stringify(popups));
  }
};

export default {
  // TOKEN
  setToken,
  getToken,
  removeToken,
  // MENU
  setMenuDisplayMode,
  getMenuDisplayMode,
  // POPUP
  addPopupToNotShowAgain,
  getPopupToNotShowAgain,
};
