import { ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  Middleware,
  MiddlewareAPI,
  PayloadAction } from '@reduxjs/toolkit';
import { WebSocket,
  SocketActionHandler,
  OrganizationGetDevicesActionHandler,
  DeviceOnStateChangeActionHandler,
  DeviceState,
  DeviceMoveRequest,
  DeviceSetViewRequest,
  DeviceStartLiveRequest,
  DeviceStopLiveRequest,
  DeviceStopMoveRequest,
  DeviceStopZoomRequest,
  DeviceSwitchSceneRequest,
  DeviceSwitchViewRequest,
  DeviceZoomRequest,
  ConnectionError,
  OrganizationGetInformationAboutDevicesActionHandler,
  DeviceInformation,
  DeviceGetInformationActionHandler,
  DeviceGetInformationRequest,
  OrganizationGetDeviceUpdateSessionsActionHandler,
  DeviceUpdateSession,
  DeviceUpdateGetContextRequest,
  DeviceUpdateStartDownloadRequest,
  DeviceUpdateInstallRequest,
  DeviceUpdateAbortRequest,
  DeviceUpdateOnProgressActionHandler,
  DeviceUpdateOnProgressEvent,
  DeviceUpdateStartDownloadActionHandler,
  DeviceUpdateOnSessionActionHandler,
  DeviceUpdateOnSessionEvent,
  ResultEmittedMessage,
  DeviceUpdateScheduleSilentActionHandler,
  DeviceUpdateScheduleSilentRequest,
  OrganizationGetInformationAboutDevicesRequest,
  DeviceSetSystemTimeRequest,
  DeviceSetSystemTimezoneRequest,
  DeviceRebootRequest,
  DeviceFactoryResetRequest } from '@kalyzee/kast-websocket-module';
import { getBaseUrl, getUserSocketUrl } from '../../common/helpers/request';
import socketActions from './actions';
import deviceActions from '../device/actions';

import { setDevices, setDevicesUpdateSessions, updateDevice, updateDevicesInformation, updateDevicesUpdateSessions, updateDeviceUpdateProgression } from '../device/slice';
import { setSocketStatus } from './slice';
import { SocketStatus } from './interfaces';
import { appError } from '../error/actions';
import { ErrorType } from '../../common/helpers/errors';
import { getToken } from '../../common/helpers/storage';

const log = (...args: string[]) => {
  // eslint-disable-next-line no-console
  console.log('[SOCKET] ', ...args);
};

/// Redux Action Handlers ///

interface ActionEntryWithPayload<P = any> {
  actionCreator: ActionCreatorWithPayload<P>,
  handle: (
    socket: WebSocket,
    store: MiddlewareAPI,
    payload: P,
  ) => (Promise<ResultEmittedMessage> | void),
}

interface ActionEntry {
  actionCreator: ActionCreatorWithoutPayload,
  handle: (socket: WebSocket, store: MiddlewareAPI) => void,
}

const reduxActionHandlers: (ActionEntry | ActionEntryWithPayload)[] = [
  {
    actionCreator: socketActions.socketConnect,
    handle: async (socket: WebSocket, store: MiddlewareAPI) => {
      try {
        const userToken = getToken() || '';
        socket.connect(userToken, `${getBaseUrl()}/socket/token`);
      } catch {
        // TODO : TO IMPROVE
        // AppErrors are usually handled in the handleError generator
        // which we can't access from here, we need more uniformity
        store.dispatch(appError({ type: ErrorType.NetworkError, code: -1, message: 'Error while fetching socket token' }));
        store.dispatch(setSocketStatus(SocketStatus.Offline));
      }
    },
  },
  {
    actionCreator: socketActions.socketDisconnect,
    handle: (socket: WebSocket) => {
      socket.disconnect();
    },
  },
  {
    actionCreator: deviceActions.requestDevices,
    handle: (socket: WebSocket, store: MiddlewareAPI) => (
      socket.emitter.organization.getDevices()
    ),
  },
  {
    actionCreator: deviceActions.move,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceMoveRequest) => (
      socket.emitter.device.move(payload)
    ),
  },
  {
    actionCreator: deviceActions.stopMove,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceStopMoveRequest) => (
      socket.emitter.device.stopMove(payload)
    ),
  },
  {
    actionCreator: deviceActions.zoom,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceZoomRequest) => (
      socket.emitter.device.zoom(payload)
    ),
  },
  {
    actionCreator: deviceActions.stopZoom,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceStopZoomRequest) => (
      socket.emitter.device.stopZoom(payload)
    ),
  },
  {
    actionCreator: deviceActions.startLive,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceStartLiveRequest) => (
      socket.emitter.device.startLive(payload)
    ),
  },
  {
    actionCreator: deviceActions.stopLive,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceStopLiveRequest) => (
      socket.emitter.device.stopLive(payload)
    ),
  },
  {
    actionCreator: deviceActions.switchScene,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceSwitchSceneRequest) => (
      socket.emitter.device.switchScene(payload)
    ),
  },
  {
    actionCreator: deviceActions.switchView,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceSwitchViewRequest) => (
      socket.emitter.device.switchView(payload)
    ),
  },
  {
    actionCreator: deviceActions.setView,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceSetViewRequest) => (
      socket.emitter.device.setView(payload)
    ),
  },
  {
    actionCreator: deviceActions.requestDevicesInformation,
    handle: (
      socket: WebSocket,
      store: MiddlewareAPI,
      payload: OrganizationGetInformationAboutDevicesRequest,
    ) => (
      socket.emitter.organization.getInformationAboutDevices(payload)
    ),
  },
  {
    actionCreator: deviceActions.requestDeviceInformation,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceGetInformationRequest) => (
      socket.emitter.device.getInformation(payload)
    ),
  },
  {
    actionCreator: deviceActions.requestDevicesUpdateSessions,
    handle: (socket: WebSocket, store: MiddlewareAPI) => (
      socket.emitter.organization.getDeviceUpdateSessions()
    ),
  },
  {
    actionCreator: deviceActions.requestDeviceUpdateSessions,
    handle: (socket: WebSocket, store: MiddlewareAPI, payload: DeviceUpdateGetContextRequest) => (
      socket.emitter.device.getUpdateSessions(payload)
    ),
  },
  {
    actionCreator: deviceActions.startDownloadUpdate,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceUpdateStartDownloadRequest) => (
      socket.emitter.device.startDownloadUpdate(payload)
    ),
  },
  {
    actionCreator: deviceActions.scheduleUpdate,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceUpdateScheduleSilentRequest) => (
      socket.emitter.device.scheduleSilentUpdate(payload)
    ),
  },
  {
    actionCreator: deviceActions.installUpdate,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceUpdateInstallRequest) => (
      socket.emitter.device.installUpdate(payload)
    ),
  },
  {
    actionCreator: deviceActions.abortUpdate,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceUpdateAbortRequest) => (
      socket.emitter.device.abortUpdate(payload)
    ),
  },
  {
    actionCreator: deviceActions.setSystemTime,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceSetSystemTimeRequest) => (
      socket.emitter.device.setSystemTime(payload)
    ),
  },
  {
    actionCreator: deviceActions.setSystemTimezone,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceSetSystemTimezoneRequest) => (
      socket.emitter.device.setSystemTimezone(payload)
    ),
  },
  {
    actionCreator: deviceActions.reboot,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceRebootRequest) => (
      socket.emitter.device.reboot(payload)
    ),
  },
  {
    actionCreator: deviceActions.factoryReset,
    handle: (socket: WebSocket, _: MiddlewareAPI, payload: DeviceFactoryResetRequest) => (
      socket.emitter.device.factoryReset(payload)
    ),
  },
];

const reduxActionMatcher = (
  socket: WebSocket,
  store: MiddlewareAPI,
  action: PayloadAction,
) : Promise<ResultEmittedMessage> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const handler of reduxActionHandlers) {
    if (handler.actionCreator.match(action)) {
      const result = handler.handle(socket, store, action.payload);
      if (result instanceof Promise) return result;
      return undefined;
    }
  }
  return undefined;
};

/// Socket Action Handlers ///

const socketActionHandlers = (store: MiddlewareAPI): SocketActionHandler[] => {
  const handlers: SocketActionHandler[] = [];

  // Get Devices
  const organizationDevices = new OrganizationGetDevicesActionHandler();
  organizationDevices.onResponse = (content: DeviceState[]) => {
    if (!content) return;
    store.dispatch(setDevices(content));
  };
  handlers.push(organizationDevices);

  // Device State Update
  const deviceState = new DeviceOnStateChangeActionHandler();
  deviceState.onReceive = (content: DeviceState) => {
    if (!content) return;
    store.dispatch(updateDevice(content));
  };
  handlers.push(deviceState);

  // Devices Information
  const organizationDevicesInformation = new OrganizationGetInformationAboutDevicesActionHandler();
  organizationDevicesInformation.onResponse = (content: DeviceInformation[]) => {
    if (!content) return;
    store.dispatch(updateDevicesInformation(content));
  };
  handlers.push(organizationDevicesInformation);

  // Device Information
  const deviceInformation = new DeviceGetInformationActionHandler();
  deviceInformation.onResponse = (content: DeviceInformation) => {
    if (!content) return;
    store.dispatch(updateDevicesInformation([content]));
  };
  handlers.push(deviceInformation);

  // Devices Update Session
  const organizationDeviceUpdateSesssions = new OrganizationGetDeviceUpdateSessionsActionHandler();
  organizationDeviceUpdateSesssions.onResponse = (content: DeviceUpdateSession[]) => {
    if (!content) return;
    store.dispatch(setDevicesUpdateSessions(content));
  };
  handlers.push(organizationDeviceUpdateSesssions);

  // Device Update Session Progress
  const deviceUpdateSessionsOnProgress = new DeviceUpdateOnProgressActionHandler();
  deviceUpdateSessionsOnProgress.onReceive = (content: DeviceUpdateOnProgressEvent) => {
    if (!content) return;
    store.dispatch(updateDeviceUpdateProgression(content));
  };
  handlers.push(deviceUpdateSessionsOnProgress);

  // Device Update Start Download - Response
  const deviceUpdateStartDownload = new DeviceUpdateStartDownloadActionHandler();
  deviceUpdateStartDownload.onResponse = (content: DeviceUpdateSession) => {
    if (!content) return;
    store.dispatch(updateDevicesUpdateSessions([content]));
  };
  handlers.push(deviceUpdateStartDownload);

  // Device Update Start Download - Response
  const deviceUpdateSchedule = new DeviceUpdateScheduleSilentActionHandler();
  deviceUpdateSchedule.onResponse = (content: DeviceUpdateSession) => {
    if (!content) return;
    store.dispatch(updateDevicesUpdateSessions([content]));
  };
  handlers.push(deviceUpdateSchedule);

  // Device Update On session
  const deviceUpdateOnSession = new DeviceUpdateOnSessionActionHandler();
  deviceUpdateOnSession.onReceive = (content: DeviceUpdateOnSessionEvent) => {
    if (!content?.session) return;
    store.dispatch(updateDevicesUpdateSessions([content.session]));
  };
  handlers.push(deviceUpdateOnSession);

  return handlers;
};

/// Middleware ///

export const webSocketMiddleware: Middleware = (store) => {
  const socket = new WebSocket({ url: getUserSocketUrl() });

  socket.registerActionHandlers(socketActionHandlers(store));

  socket.onConnect = () => {
    log('Connected');
    store.dispatch(setSocketStatus(SocketStatus.Online));
  };

  socket.onDisconnect = () => {
    log('Disconnected');
    store.dispatch(setSocketStatus(SocketStatus.Loading));
  };

  socket.onConnectionFailed = (error: ConnectionError) => {
    log('Connection Failed');
    store.dispatch(setSocketStatus(SocketStatus.Loading));
  };

  socket.onAbandon = () => {
    log('Abandonned');
    store.dispatch(setSocketStatus(SocketStatus.Offline));
  };

  // eslint-disable-next-line arrow-body-style
  return (next) => async (action) => {
    const result = reduxActionMatcher(socket, store, action);
    if (result) return result;
    return next(action);
  };
};

export default {
  webSocketMiddleware,
};
