import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppVersion from '../../common/components/app/AppVersion';
import BackButton from '../../common/components/utils/BackButton';
import LoginForm from '../../features/session/LoginForm';
import ResetPasswordForm from '../../features/session/ResetPasswordForm';

import styles from './login.module.css';

const ResetPasswordPage = () => {
  const { recoveryKey } = useParams();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className={styles.blurredBackground} />
      <BackButton />
      <div className={styles.container}>
        <div className={styles.form}>
          <ResetPasswordForm recoveryKey={recoveryKey} />
          <AppVersion />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
