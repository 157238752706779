import { DeviceUpdateSession, DeviceVideoContext } from '@kalyzee/kast-websocket-module';
import { useAppSelector } from '../../app/hooks';
import { Device, DeviceData, DeviceInformation, DeviceUpdateProgression } from './interfaces';
import { selectDevices, selectDevicesInfo, selectDevicesUpdateProgressions, selectDevicesUpdateProgressionsForSession, selectDevicesUpdateSessions } from './selectors';
import { getDeviceName } from './utils';

const deviceToDeviceData = (device: Device): DeviceData => {
  const displayName = getDeviceName(device);
  const videoContext : DeviceVideoContext | undefined = device.context?.videoContext;
  const getDate = (strDate? : string) : Date | undefined => (
    strDate ? new Date(strDate) : undefined
  );
  return {
    id: device.id,
    displayName,
    isOnline: device.online,
    isInLive: !!videoContext?.isInLive,
    recordState: videoContext?.recordState,
    liveStartedAt: getDate(videoContext?.liveStartedAtInLocalTime),
    recordStartedAt: getDate(videoContext?.recordStartedAtInLocalTime),
    recordStoppedAt: getDate(videoContext?.recordStoppedAtInLocalTime),
    userSelected: device.userSelected,
    currentScene: videoContext?.currentScene,
  };
};

export const useDevices = (): Device[] => useAppSelector(selectDevices);

export const useDevicesData = (): DeviceData[] => {
  const devices = useDevices();
  if (!devices || devices.length === 0) return [];
  const result = devices.map((device) => deviceToDeviceData(device));
  // sort devices
  return result.sort((d1, d2) => {
    if (d1.isOnline === d2.isOnline) return 0;
    if (d1.isOnline) return -1;
    if (d2.isOnline) return 1;
    return 0;
  });
};

export const useDevicesInfo = (): DeviceInformation[] => useAppSelector(selectDevicesInfo);
export const useDevicesUpdateSessions = (): DeviceUpdateSession[] => (
  useAppSelector(selectDevicesUpdateSessions)
);
export const useDevicesUpdateProgressions = (): DeviceUpdateProgression[] => (
  useAppSelector(selectDevicesUpdateProgressions)
);
export const useDevicesUpdateProgressionsForSession = (
  sessionId?: string,
): DeviceUpdateProgression | undefined => (
  useAppSelector(selectDevicesUpdateProgressionsForSession(sessionId))
);

export default {
  useDevices,
  useDevicesData,
  useDevicesInfo,
  useDevicesUpdateSessions,
  useDevicesUpdateProgressions,
};
