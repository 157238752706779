import React, { ForwardedRef, useImperativeHandle } from 'react';

import styles from './ProgressBar.module.css';

const DEFAULT_SHOW_PERCENTAGE = true;

export interface ProgressBarProps {
  percentage: number;
  showPercentage?: boolean;
  className?: string;
  style?: React.CSSProperties;
  innerClassName?: string;
  innerStyle?: React.CSSProperties;
  percentageClassName?: string;
  percentageStyle?: React.CSSProperties;
}

export interface ProgressBarRef {
}

const ProgressBar = React.forwardRef(({
  percentage,
  showPercentage,
  className,
  style,
  innerClassName,
  innerStyle,
  percentageClassName,
  percentageStyle,
}: ProgressBarProps, forwardRef: ForwardedRef<ProgressBarRef | undefined>) => {
  useImperativeHandle(forwardRef, () => ({}));

  const renderPercentageValue = () => {
    const currClasses = [styles.percentage];
    if (percentageClassName) currClasses.push(percentageClassName);
    return (
      <div
        className={currClasses.join(' ')}
        style={percentageStyle}
      >
        { `${percentage.toFixed(0)}%` }
      </div>
    );
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  const classesInner = [styles.inner];
  if (innerClassName) classesInner.push(innerClassName);
  const inStyle : React.CSSProperties = {
    width: `${percentage}%`,
  };
  if (innerStyle) Object.assign(inStyle, innerStyle);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      <div className={styles.background} />
      <div
        className={classesInner.join(' ')}
        style={inStyle}
      >
        {
          showPercentage ? renderPercentageValue() : null
        }
      </div>
    </div>
  );
});

ProgressBar.defaultProps = {
  showPercentage: DEFAULT_SHOW_PERCENTAGE,
  className: undefined,
  style: undefined,
  innerClassName: undefined,
  innerStyle: undefined,
  percentageClassName: undefined,
  percentageStyle: undefined,
};

export default ProgressBar;
