import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { requestDevices } from '../device/actions';
import { AuthStatus } from '../session/interfaces';
import { selectAuthStatus, selectUserOrganization } from '../session/selectors';
import { socketConnect, socketDisconnect } from './actions';
import { SocketStatus } from './interfaces';
import { selectSocketStatus } from './selectors';
import { setSocketStatus } from './slice';

// Use only one time
export const useSocketConnexion = () => {
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector(selectAuthStatus);
  const socketStatus = useAppSelector(selectSocketStatus);

  const connect = () => {
    dispatch(socketConnect());
  };

  const retryConnect = () => {
    dispatch(setSocketStatus(SocketStatus.Loading));
    connect();
  };

  const disconnect = () => {
    dispatch(socketDisconnect());
  };

  useEffect(() => {
    if (authStatus === AuthStatus.In) {
      connect();
    }
    return () => {
      disconnect();
    };
  }, [authStatus]);

  useEffect(() => {
    if (socketStatus === SocketStatus.Online) {
      dispatch(requestDevices());
    }
  }, [socketStatus]);

  return { socketStatus, retry: retryConnect };
};

export const useSocket = () => {
  const dispatch = useAppDispatch();
  const socketStatus = useAppSelector(selectSocketStatus);
  const retryConnect = () => {
    dispatch(setSocketStatus(SocketStatus.Loading));
    dispatch(socketConnect());
  };
  return { socketStatus, retryConnect };
};

export default {
  useSocketConnexion,
  useSocket,
};
