import Timezone from 'timezone-enum';
import { Device } from '../../../../features/device/interfaces';

export enum TableTimeDevicesAction {
  CLEAN_ERROR = 'clean_error',
}

export interface TableTimeDevicesData {
  device: Device,
  deviceId: string,

  // UTILS
  online: boolean;
  checked: boolean;
  timeOffset?: number;
  timezone?: Timezone;
  error?: string;
  processing?: string;
  success?: boolean;

  // TO SHOW
  name?: string,
}
