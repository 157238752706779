import { Device } from '../../../../features/device/interfaces';

export enum TableSystemDevicesAction {
  REBOOT = 'reboot',
  FACTORY_RESET = 'factory_reset',
  CLEAN_ERROR = 'clean_error',
}

export interface TableSystemDevicesData {
  device: Device,
  deviceId: string,

  // UTILS
  online: boolean;
  checked: boolean;
  error?: string;
  processing?: string;
  success?: boolean;

  // TO SHOW
  name?: string,
  lastReboot?: string;
}
