import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection, Player, PlayerWebRTCRef } from '@kalyzee/kast-react-player-module';
import PlayerGridList from '../../common/components/player/PlayerGridList';
import OverlayContainer, { OverlayContainerRef } from '../../common/components/container/OverlayContainer';
import { addTrackEndedMediaStreamListener, TrackEndedMediaStreamListener } from '../../common/helpers/media';
import PlayerContainer from '../../common/components/container/PlayerContainer';
import { move, setView, stopMove, stopZoom, switchScene, switchView, zoom } from '../../features/device/actions';
import { useAppDispatch } from '../../app/hooks';
import { useDevicesData } from '../../features/device/hooks';
import { DeviceData } from '../../features/device/interfaces';

// ------------------------------- //
// ----------- PAGE TEST --------- //
// ------------------------------- //

const containerStyle : React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

const playerStyle : React.CSSProperties = {
  display: 'flex',
  height: '100%',
  width: '100%',
  backgroundColor: 'unset',
};

const PreviewPage = () => {
  const dispatch = useAppDispatch();
  //  const [showMenu, setShowMenu] = useState<boolean>(false);
  const overlayRef = useRef<OverlayContainerRef>();
  const ref = useRef<HTMLDivElement>(null);
  const [overlayAdditionnalStyle, setOverlayAdditionnalStyle] = useState<React.CSSProperties>({ visibility: 'hidden' });
  const [freezeAutoAdjust, setFreeAutoAdjust] = useState<boolean>(false);
  const [media, setMedia] = useState<MediaStream>();
  const [currentDeviceId, setCurrentDeviceId] = useState<string>();
  const devicesData: DeviceData[] = useDevicesData();
  const currentDevice = currentDeviceId
    ? devicesData.find((d) => d.id === currentDeviceId) : undefined;

  const onMove = (item : DeviceData, direction : DeviceMoveDirection) => {
    dispatch(move({ deviceId: item.id, direction }));
  };

  const onStopMove = (item : DeviceData) => {
    dispatch(stopMove({ deviceId: item.id }));
  };

  const onZoom = (item : DeviceData, direction : DeviceZoomDirection) => {
    dispatch(zoom({ deviceId: item.id, direction }));
  };

  const onStopZoom = (item : DeviceData) => {
    dispatch(stopZoom({ deviceId: item.id }));
  };

  const onScene = (item : DeviceData, scene : DeviceScene) => {
    dispatch(switchScene({ deviceId: item.id, scene: scene as any }));
  };

  const onView = (item : DeviceData, view: DeviceView) => {
    dispatch(switchView({ deviceId: item.id, view: view as any }));
  };

  const onAssignView = (item : DeviceData, view: DeviceView) => {
    dispatch(setView({ deviceId: item.id, view: view as any }));
  };

  useEffect(() => {
    let listener : TrackEndedMediaStreamListener;
    if (media) {
      listener = addTrackEndedMediaStreamListener(media, (track : MediaStreamTrack) => {
        if (track.kind === 'video') {
          setMedia(undefined);
          overlayRef.current?.hide();
        }
      });
    }
    return () => {
      if (listener) listener.clear();
    };
  }, [media]);

  const renderContainer = () => (
    <PlayerGridList
      style={{ height: '100%', flex: 4 }}
      devices={devicesData.filter((device) => device.userSelected)}
      freezeAutoAdjust={freezeAutoAdjust}
      onClick={(item : DeviceData, r : PlayerWebRTCRef, details) => {
        // if (details.itemsForCurrentPage <= 1) return;
        overlayRef.current?.show();
        setCurrentDeviceId(item.id);
        setMedia(r.getMediaStream());
      }}
      onMove={onMove}
      onStopMove={onStopMove}
      onZoom={onZoom}
      onStopZoom={onStopZoom}
      onScene={onScene}
      onView={onView}
      onAssignView={onAssignView}
    />
  );

  const renderOverlay = () => (
    <OverlayContainer
      ref={overlayRef}
      style={{ zIndex: 100, ...(overlayAdditionnalStyle || {}) }}
      onDisplay={(display : boolean) => {
        if (!display) setCurrentDeviceId(undefined);
        setFreeAutoAdjust(display);
      }}
    >
      <PlayerContainer
        data={currentDevice}
        style={{ height: '100%', width: '100%' }}
        autoResize
        closeButton
        onClose={() => {
          setOverlayAdditionnalStyle({ visibility: 'hidden' });
          overlayRef.current?.hide();
        }}
      >
        <Player
          style={playerStyle}
          media={media}
          enableCameraControls
          enableVideoControls
          alwaysDisplayCameraControls
          alwaysDisplayVideoControls
          mute
          live
          play
          scene={currentDevice?.currentScene as (DeviceScene | undefined)}
          onMove={useCallback((direction : DeviceMoveDirection) => {
            if (currentDevice) onMove(currentDevice, direction);
          }, [currentDevice])}
          onStopMove={useCallback(() => {
            if (currentDevice) onStopMove(currentDevice);
          }, [currentDevice])}
          onZoom={useCallback((direction : DeviceZoomDirection) => {
            if (currentDevice) onZoom(currentDevice, direction);
          }, [currentDevice])}
          onStopZoom={useCallback(() => {
            if (currentDevice) onStopZoom(currentDevice);
          }, [currentDevice])}
          onScene={useCallback((scene : DeviceScene) => {
            if (currentDevice) onScene(currentDevice, scene);
          }, [currentDevice])}
          onView={useCallback((view : DeviceView) => {
            if (currentDevice) onView(currentDevice, view);
          }, [currentDevice])}
          onAssignView={useCallback((view : DeviceView) => {
            if (currentDevice) onAssignView(currentDevice, view);
          }, [currentDevice])}
          onReady={() => {
            setOverlayAdditionnalStyle({ visibility: 'visible' });
          }}
        />
      </PlayerContainer>
    </OverlayContainer>
  );

  return (
    <div style={containerStyle} ref={ref}>
      { renderContainer() }
      { renderOverlay() }
    </div>
  );
};

export default PreviewPage;
