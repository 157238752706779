/* eslint-disable react/prop-types */
import React, { ForwardedRef, useRef } from 'react';

import MessageOnHover, { MessageOnHoverMode } from './MessageOnHover';
import Colors from '../../constants/colors';

import { ReactComponent as IconError } from '../../../assets/icons/error.svg';
import { ReactComponent as IconInfo } from '../../../assets/icons/info.svg';
import { ReactComponent as IconWarning } from '../../../assets/icons/warning.svg';
import { ReactComponent as IconSuccess } from '../../../assets/icons/success.svg';

import styles from './ContentWithIcon.module.css';
import { useElementSize } from '../../hooks/window';

const DEFAULT_ALWAYS_SHOW_ICON = false;
const DEFAULT_NEVER_SHOW_ICON = false;

export interface ContentWithIconProps {
  children: React.ReactNode;
  mode: MessageOnHoverMode;
  thresholdWidthSmall?: number | undefined;
  thresholdWidthXSmall?: number | undefined;
  alwaysShowIcon?: boolean;
  neverShowIcon?: boolean;
  smallMessage?: string;
  className?: string;
  style?: React.CSSProperties;
}

export interface ContentWithIconRef { }

const ContentWithIcon = React.forwardRef((
  {
    children,
    mode,
    thresholdWidthSmall,
    thresholdWidthXSmall,
    alwaysShowIcon,
    neverShowIcon,
    smallMessage,
    className,
    style,
  }: ContentWithIconProps,
  forwardRef: ForwardedRef<ContentWithIconRef | undefined>,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(containerRef);
  const width = size?.width ?? 0;

  const classes = [styles.container];
  if (className) classes.push(className);

  const renderIcon = () => {
    if (neverShowIcon) return null;
    const iconSize = '1.25rem';
    if (mode === MessageOnHoverMode.INFO) {
      return <IconInfo width={iconSize} height={iconSize} />;
    }
    if (mode === MessageOnHoverMode.WARNING) {
      return <IconWarning width={iconSize} height={iconSize} fill={Colors.getBroom()} />;
    }
    if (mode === MessageOnHoverMode.ERROR) {
      return <IconError width={iconSize} height={iconSize} fill={Colors.getTorchRed()} />;
    }
    if (mode === MessageOnHoverMode.SUCCESS) {
      return (
        <IconSuccess
          width={iconSize}
          height={iconSize}
          fill={Colors.getMountainMeadow()}
        />
      );
    }
    return null;
  };

  const renderSmallContent = () => (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      <MessageOnHover
        mode={mode}
        targetRef={containerRef}
      >
        {children}
      </MessageOnHover>
      {renderIcon()}
      {smallMessage}
    </div>
  );

  const renderXSmallContent = () => (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      <MessageOnHover
        mode={mode}
        targetRef={containerRef}
      >
        {children}
      </MessageOnHover>
      {renderIcon()}
    </div>
  );

  const render = () => {
    if (width > 0 && thresholdWidthXSmall
      && (width <= thresholdWidthXSmall || thresholdWidthXSmall < 0)) {
      return renderXSmallContent();
    }
    if (width > 0 && thresholdWidthSmall
      && (width <= thresholdWidthSmall || thresholdWidthSmall < 0)) {
      return renderSmallContent();
    }
    return (
      <div
        className={classes.join(' ')}
        style={style}
        ref={containerRef}
      >
        { alwaysShowIcon ? renderIcon() : null}
        {children}
      </div>
    );
  };

  return render();
});

ContentWithIcon.defaultProps = {
  alwaysShowIcon: DEFAULT_ALWAYS_SHOW_ICON,
  neverShowIcon: DEFAULT_NEVER_SHOW_ICON,
  smallMessage: undefined,
  className: undefined,
  style: undefined,
};

export default ContentWithIcon;
