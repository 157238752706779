import { DeviceUpdateSessionStatus, VersionState } from '@kalyzee/kast-websocket-module';
import React, { useImperativeHandle } from 'react';
import { Strings } from '../../../constants/translation';
import { useAppTranslation } from '../../../hooks/translation';
import { TableUpdateDevicesData } from './TableUpdateDevices.constant';

import styles from './TableUpdateDevicesActionCell.module.css';
import commonStyles from '../TableCommon.module.css';

export interface TableUpdateDevicesActionCellRef { }

export interface TableUpdateDevicesActionCellProps {
  data: TableUpdateDevicesData;
  onStartUpdate?: () => void;
  onInstall?: () => void;
  onAbort?: () => void;
  onCleanError?: () => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableUpdateDevicesActionCell = React.forwardRef((
  {
    data,
    onStartUpdate,
    onInstall,
    onAbort,
    onCleanError,
    className,
    style,
  }: TableUpdateDevicesActionCellProps,
  forwardRef: React.ForwardedRef<TableUpdateDevicesActionCellRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const { session } = data;

  useImperativeHandle(forwardRef, () => ({}));

  const startUpdate = () => onStartUpdate?.();
  const install = () => onInstall?.();
  const abort = () => onAbort?.();
  const cleanError = () => onCleanError?.();

  const renderError = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={`${commonStyles.button}`}
        onClick={cleanError}
      >
        {t(Strings.OK)}
      </button>
    </div>
  );

  const renderDownloadingState = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={`${commonStyles.button} ${commonStyles.cancel}`}
        onClick={abort}
      >
        {t(Strings.CANCEL)}
      </button>
    </div>
  );

  const renderWaitingForInstallState = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={`${commonStyles.button} ${commonStyles.cancel}`}
        onClick={abort}
      >
        {t(Strings.CANCEL)}
      </button>
      <button
        type="button"
        className={commonStyles.button}
        disabled={data.running}
        onClick={install}
      >
        {t(Strings.UPDATE_TABLE_ACTION_BUTTON_INSTALL)}
      </button>
    </div>
  );

  const renderInstallingState = () => null;

  const renderAbortedState = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={commonStyles.button}
        disabled={data.running}
        onClick={startUpdate}
      >
        {t(Strings.UPDATE_TABLE_ACTION_BUTTON_UPDATE)}
      </button>
    </div>
  );

  const renderFailedState = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={commonStyles.button}
        disabled={data.running}
        onClick={startUpdate}
      >
        {t(Strings.UPDATE_TABLE_ACTION_BUTTON_RETRY)}
      </button>
    </div>
  );

  const renderSuccessState = () => null;

  const renderScheduled = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={`${commonStyles.button} ${commonStyles.cancel}`}
        onClick={abort}
      >
        {t(Strings.CANCEL)}
      </button>
    </div>
  );

  const renderUpToDate = () => null;

  const renderToUpdate = () => (
    <div
      className={styles.content}
    >
      <button
        type="button"
        className={commonStyles.button}
        disabled={data.running}
        onClick={startUpdate}
      >
        {t(Strings.UPDATE_TABLE_ACTION_BUTTON_UPDATE)}
      </button>
    </div>
  );

  const renderDisconnected = () => null;

  const renderCell = () => {
    if (!data.online) {
      if (!session || session?.status !== DeviceUpdateSessionStatus.INSTALLING) {
        return renderDisconnected();
      }
    }
    if (data.error) return renderError();
    if (data.scheduled) {
      return renderScheduled();
    }
    if (data.online && data.state === VersionState.UP_TO_DATE) {
      return renderUpToDate();
    }
    if (!session) {
      if (data.online) {
        return renderToUpdate();
      }
      return null;
    }
    if (session.status === DeviceUpdateSessionStatus.DOWNLOADING) {
      return renderDownloadingState();
    }
    if (session.status === DeviceUpdateSessionStatus.WAITING_FOR_INSTALL) {
      return renderWaitingForInstallState();
    }
    if (session.status === DeviceUpdateSessionStatus.INSTALLING) {
      return renderInstallingState();
    }
    if (session.status === DeviceUpdateSessionStatus.ABORTED) {
      return renderAbortedState();
    }
    if (session.status === DeviceUpdateSessionStatus.FAILED) {
      return renderFailedState();
    }
    if (session.status === DeviceUpdateSessionStatus.DONE) {
      return renderSuccessState();
    }
    return null;
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      {renderCell()}
    </div>
  );
});

TableUpdateDevicesActionCell.defaultProps = {
  onStartUpdate: undefined,
  onInstall: undefined,
  onAbort: undefined,
  onCleanError: undefined,
  className: undefined,
  style: undefined,
};

export default TableUpdateDevicesActionCell;
