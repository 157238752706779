import React from 'react';

import { useAppDispatch } from '../../app/hooks';
import { Strings } from '../../common/constants/translation';
import { useAppTranslation } from '../../common/hooks/translation';

import styles from './AuthForm.module.css';
import { requestLogout } from './actions';

const LogoutForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();

  const onLogout = () => {
    dispatch(requestLogout());
  };

  return (
    <div className={styles.authContainer}>
      <p className={styles.loginTitle}>{t(Strings.PANEL)}</p>
      <div className={styles.form}>
        <button className={styles.button} type="submit" onClick={onLogout}>{t(Strings.LOGOUT)}</button>
      </div>
    </div>
  );
};

export default LogoutForm;
