import { Touchable } from '@kalyzee/kast-react-player-module';
import { DeviceVideoRecordState } from '@kalyzee/kast-websocket-module';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { CheckMarkType, CustomCheckBox } from '../../common/components/form/CustomCheckBox';
import { Strings } from '../../common/constants/translation';
import { useAppTranslation } from '../../common/hooks/translation';
import { DeviceCard } from './DeviceCard';

import styles from './DeviceList.module.css';
import { useDevicesData } from './hooks';
import { DeviceData, DeviceResponsePayload } from './interfaces';
import { selectDevice, setSelectedDevices } from './slice';

const generateFakeDevices = () => {
  const fake = [];
  const makeid = () => {
    let text = 'kast-';
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 4; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };
  for (let i = 0; i < 50; i += 1) {
    const id = makeid();
    fake.push(
      {
        id,
        displayName: id,
        isOnline: Math.random() > 0.3,
        isInLive: Math.random() > 0.5,
        isRecording: Math.random() > 0.5,
      },
    );
  }
  return fake;
};

const generateFakeDevicesFromServer = () => {
  const fake: DeviceResponsePayload[] = [];
  const makeid = () => {
    let text = 'kast-';
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 4; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };
  for (let i = 0; i < 20; i += 1) {
    const id = makeid();
    const recordState = Math.random() > 0.5
      ? DeviceVideoRecordState.IDLE : DeviceVideoRecordState.RUNNING;
    const now = new Date().toISOString();
    const liveStartedAt = now;
    const recordStartedAt = now;
    const recordStoppedAt = undefined;
    const liveStartedAtInLocalTime = now;
    const recordStartedAtInLocalTime = now;
    const recordStoppedAtInLocalTime = undefined;
    fake.push(
      {
        deviceId: id,
        context: {
          online: Math.random() > 0.3,
          videoContext: {
            isInLive: Math.random() > 0.5,
            recordState,
            liveStartedAt,
            recordStartedAt,
            recordStoppedAt,
            liveStartedAtInLocalTime,
            recordStartedAtInLocalTime,
            recordStoppedAtInLocalTime,
            currentScene: 0,
          },
        },
      },
    );
  }
  return fake;
};

const fakeDevices = generateFakeDevicesFromServer();

enum SelectMode {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Live = 'LIVE',
  Record = 'RECORD',
}

interface SelectModeData {
  displayName: Strings,
  accept: (device: DeviceData) => boolean,
}

const selectModeDataSet: { [key in SelectMode]: SelectModeData } = {
  [SelectMode.Online]: {
    displayName: Strings.ONLINE,
    accept: (device) => device.isOnline,
  },
  [SelectMode.Offline]: {
    displayName: Strings.OFFLINE,
    accept: (device) => !device.isOnline,
  },
  [SelectMode.Live]: {
    displayName: Strings.IN_LIVE,
    accept: (device) => device.isInLive,
  },
  [SelectMode.Record]: {
    displayName: Strings.RECORDING,
    accept: (device) => device.recordState === DeviceVideoRecordState.RUNNING,
  },
};

export interface DeviceListProps {
  className?: string;
  style?: React.CSSProperties;
}

export const DeviceList = ({
  className,
  style,
} : DeviceListProps) => {
  // Only for testing
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch((setDevices(fakeDevices)));
  //   }, 2000);
  // }, []);
  const dispatch = useAppDispatch();
  const devices = useDevicesData();

  const { t } = useAppTranslation();

  const [masterType, setMasterType] = useState(CheckMarkType.Normal);
  const [isSelectMode, setSelectMode] = useState(false);

  const [isPickerOpened, setPickerOpened] = useState(false);

  const selectDevices = (ids: string[]) => {
    dispatch(setSelectedDevices({ ids }));
  };

  useEffect(() => {
    // Has to be in a separate useEffect, otherwise devices update
    // would make the useEffect loop
    if (!isSelectMode) selectDevices(devices.map((device) => device.id));
  }, [isSelectMode]);

  useEffect(() => {
    if (isSelectMode) {
      const selectedNb = devices.filter((device) => device.userSelected).length;
      if (selectedNb === devices.length) {
        setMasterType(CheckMarkType.Normal);
      } else {
        setMasterType(CheckMarkType.Bar);
      }
    }
  }, [devices, isSelectMode]);

  /**
   * When selection mode is enabled :
   * - Check box are appearing next to devices
   * - The master checkbox is not empty
   * - Only checked devices are being displayed
   * When selection mode is disabled :
   * - No check box are appearing
   * - The master check box is empty
   * - Every devices are being displayed
   *
   * There is 3 ways to enter selection mode :
   * - By clicking on master check box
   * - By selecting a mode
   * - By selecting a device
   * There is 2 ways to leave selection mode :
   * - By clicking on master button (when the button is not empty)
   * - By unselecting every devices manually
   */

  const userSelectedMode = (mode: SelectModeData) => {
    setSelectMode(true);
    const toSelect: string[] = [];
    devices.forEach((device) => {
      if (mode.accept(device)) toSelect.push(device.id);
    });
    selectDevices(toSelect);
    setPickerOpened(false);
  };

  const userSelectedDevice = (id: string) => {
    if (isSelectMode) { // In select mode, adding device
      const selectedDevices = devices.filter((device) => device.userSelected);
      if (selectedDevices.length === 1 && selectedDevices[0].id === id) {
        // Is the last selected device, leaving select mode
        setSelectMode(false);
      } else {
        dispatch(selectDevice({ id }));
      }
    } else {
      setSelectMode(true);
      selectDevices([id]); // Entering select mode, selecting only this device
    }
  };

  const userClickedMaster = () => {
    setSelectMode(!isSelectMode);
    setPickerOpened(false);
  };

  const renderCards = () => {
    if (devices && devices.length > 0) {
      const cards = devices.map(
        (device) => (
          <DeviceCard
            select={() => userSelectedDevice(device.id)}
            device={device}
            key={device.id}
            isSelectMode={isSelectMode}
          />
        ),
      );
      return cards;
    }

    return [
      <DeviceCard key="device-load-0" />,
      <DeviceCard key="device-load-1" />,
      <DeviceCard key="device-load-2" />,
    ];
  };

  const renderSelectModeItem = (data: SelectModeData) => (
    <div
      tabIndex={0}
      onKeyPress={() => {}}
      role="button"
      aria-label="pick select mode"
      onClick={() => userSelectedMode(data)}
      className={styles.selectModeItem}
      key={`select-${data.displayName}`}
    >
      { t(data.displayName) }
    </div>
  );

  const renderDeviceSelector = () => (
    <div className={styles.selectGroup}>
      <CustomCheckBox
        isSelected={isSelectMode}
        setSelected={userClickedMaster}
        markType={masterType}
      />
      <Touchable
        className={styles.selectModePickerOpenButton}
        onPressOut={() => setPickerOpened(!isPickerOpened)}
      />
      { isPickerOpened ? (
        <div className={styles.selectModePicker}>
          { Object.values(selectModeDataSet).map((data) => renderSelectModeItem(data)) }
        </div>
      ) : null }
    </div>
  );

  const classes = [styles.devicesList];
  if (className) classes.push(className);
  return (
    <div className={classes.join(' ')} style={style}>
      <div className={styles.listHeader}>
        { renderDeviceSelector() }
        <span>{t(Strings.REGISTERED_DEVICES)}</span>
      </div>
      <div className={styles.listContent}>
        { renderCards() }
      </div>
    </div>
  );
};

DeviceList.defaultProps = {
  className: undefined,
  style: undefined,
};

export default {
  DeviceList,
};
