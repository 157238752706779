export enum Strings {
  // General
  PANEL = 'Panel',
  STATUS_BAR_TRIAL_PERIOD_IN_PROGRESS = 'status_bar_trial_period_in_progress',
  // Utils
  LOADING = 'Loading',
  CANCEL = 'Cancel',
  OK = 'Ok',
  // Login Page
  LOGIN = 'Login',
  REQUIRED_FIELD = 'This field is required',
  EMAIL = 'Email',
  PASSWORD = 'Password',
  // Logout Page
  LOGOUT = 'Logout',
  // Devices
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  IN_LIVE = 'In live',
  LIVE = 'Live',
  RECORDING = 'Recording',
  RECORD = 'Record',
  IN_LIVE_RECORDING = 'In live/recording',
  LIVE_RECORD = 'Live/Record',
  NO_VIDEO_SIGNAL = 'No Video Signal',
  NO_CONNECTED = 'No Connected',
  NO_VIDEO_SIGNAL_DESCRIPTION = 'No Video Signal Description',
  NO_CONNECTED_DESCRIPTION = 'No Connected Description',
  RETRY = 'Retry',
  REGISTERED_DEVICES = 'Registered devices',
  DEVICE_LIVE_STARTED_AT = 'device_live_started_at',
  DEVICE_RECORD_STARTED_AT = 'device_record_started_at',
  // Socket
  SOCKET_ONLINE = 'Socket online',
  SOCKET_LOADING = 'Socket loading...',
  SOCKET_OFFLINE = 'Socket offline',
  // Error messages
  ERROR = 'Error',
  AN_ERROR_OCCURED = 'An error occured',
  COULD_NOT_SEND_REQUEST = 'Network error, could not send the request',
  API_ERROR = 'Api error',
  CLIENT_ERROR = 'Client error',
  PARSING_ERROR = 'Parsing error',
  THE_SESSION_HAS_EXPIRED = 'The session has expired',
  UNAUTHORIZED_REQUEST = 'Unauthorized request',
  // Iframe
  CHOOSE_YOUR_KAST = 'Choose your kast',
  SELECT_THIS_KAST = 'Select this kast',
  // Popups
  POPUP_NOT_SHOW_AGAIN = 'popup_not_show_again',
  POPUP_CLOSE = 'popup_close',
  // Menu
  MENU_ITEM_TITLE_PREVIEW = 'menu_item_title_preview',
  MENU_ITEM_TITLE_DEVICES = 'menu_item_title_devices',
  MENU_SUBITEM_DEVICES_DESCRIPTION = 'menu_subitem_devices_description',
  MENU_SUBITEM_DEVICES_TIME = 'menu_subitem_devices_time',
  MENU_SUBITEM_DEVICES_NETWORK = 'menu_subitem_devices_network',
  MENU_SUBITEM_DEVICES_UPDATE = 'menu_subitem_devices_update',
  MENU_SUBITEM_DEVICES_SYSTEM = 'menu_subitem_devices_system',
  MENU_LOGOUT = 'menu_logout',

  // Device -> Description
  DEVICE_DESCRIPTION_PAGE_TITLE = 'device_description_page_title',
  DEVICE_DESCRIPTION_PAGE_SUBTITLE = 'device_description_page_subtitle',
  DEVICE_DESCRIPTION_NO_DEVICES = 'device_description_no_devices',
  DEVICE_DESCRIPTION_UPDATE_DEVICE_NAME_TOAST_SUCCESS = 'device_description_update_device_name_toast_success',
  DEVICE_DESCRIPTION_UPDATE_DEVICE_NAME_TOAST_ERROR = 'device_description_update_device_name_toast_error',
  DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_NAME = 'device_description_table_column_title_name',
  DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_HARDWARE_ID = 'device_description_table_column_title_hardware_id',
  DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_SOFTWARE_ID = 'device_description_table_column_title_software_id',
  DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_ROOM = 'device_description_table_column_title_room',
  DEVICE_DESCRIPTION_TABLE_INPUT_PLACEHOLDER_NAME = 'device_description_table_input_placeholder_name',
  DEVICE_DESCRIPTION_TABLE_STATE_ONLINE = 'device_description_table_state_online',
  DEVICE_DESCRIPTION_TABLE_STATE_OFFLINE = 'device_description_table_state_offline',

  // Device -> Time
  TIME_PAGE_TITLE = 'time_page_title',
  TIME_PAGE_SUBTITLE = 'time_page_subtitle',
  TIME_CURRENT_DATE = 'time_current_date',
  TIME_NO_DEVICES = 'time_no_devices',
  TIME_POPUP_WARNING_TIME_SYNCHRO_TITLE = 'time_popup_warning_time_synchro_title',
  TIME_POPUP_WARNING_TIME_SYNCHRO_CONTENT = 'time_popup_warning_time_synchro_content',
  TIME_POPUP_WARNING_TIME_SYNCHRO_CANCEL = 'time_popup_warning_time_synchro_cancel',
  TIME_POPUP_WARNING_TIME_SYNCHRO_VALIDATE = 'time_popup_warning_time_synchro_validate',
  TIME_POPUP_UPDATE_TIMEZONE_TITLE = 'time_popup_update_timezone_title',
  TIME_POPUP_UPDATE_TIMEZONE_CONTENT_MESSAGE = 'time_popup_update_timezone_content_message',
  TIME_POPUP_UPDATE_TIMEZONE_CONTENT_SELECTED = 'time_popup_update_timezone_content_selected',
  TIME_POPUP_UPDATE_TIMEZONE_CANCEL = 'time_popup_update_timezone_cancel',
  TIME_POPUP_UPDATE_TIMEZONE_VALIDATION = 'time_popup_update_timezone_validation',
  TIME_SYNCHRO_PROCESSING_MESSAGE = 'time_synchro_processing_message',
  TIME_SYNCHRO_ERROR_MESSAGE = 'time_synchro_error_message',
  TIME_UPDATE_TIMEZONE_PROCESSING_MESSAGE = 'time_update_timezone_processing_message',
  TIME_UPDATE_TIMEZONE_ERROR_MESSAGE = 'time_update_timezone_error_message',
  TIME_BUTTON_SYNCHRO = 'time_button_synchro',
  TIME_BUTTON_SYNCHRO_HOVER_MESSAGE = 'time_button_synchro_hover_message',
  TIME_BUTTON_UPDATE_TIMEZONE = 'time_button_update_timezone',
  TIME_BUTTON_UPDATE_TIMEZONE_HOVER_MESSAGE = 'time_button_update_timezone_hover_message',
  TIME_TABLE_COLUMN_TITLE_DEVICES = 'time_table_column_title_devices',
  TIME_TABLE_COLUMN_TITLE_DATE = 'time_table_column_title_date',
  TIME_TABLE_COLUMN_TITLE_TIME_LOCAL_TIMEZONE = 'time_table_column_title_time_local_timezone',
  TIME_TABLE_COLUMN_TITLE_TIME_DEVICE_TIMEZONE = 'time_table_column_title_time_device_timezone',

  // Device -> Network
  NETWORK_PAGE_TITLE = 'network_page_title',
  NETWORK_PAGE_SUBTITLE = 'network_page_subtitle',
  NETWORK_NO_DEVICES = 'network_no_devices',
  NETWORK_TABLE_COLUMN_TITLE_DEVICES = 'network_table_column_title_devices',
  NETWORK_TABLE_COLUMN_TITLE_LOCAL_IP = 'network_table_column_title_local_ip',
  NETWORK_TABLE_COLUMN_TITLE_EXTERNAL_IP = 'network_table_column_title_external_ip',
  NETWORK_TABLE_COLUMN_TITLE_DNS = 'network_table_column_title_dns',
  NETWORK_TABLE_COLUMN_TITLE_GATEWAY = 'network_table_column_title_gateway',
  NETWORK_TABLE_COLUMN_TITLE_NETMASK = 'network_table_column_title_netmask',
  NETWORK_TABLE_COLUMN_TITLE_MAC_ADDRESS = 'network_table_column_title_mac_address',

  // Device -> System
  SYSTEM_PAGE_TITLE = 'system_page_title',
  SYSTEM_PAGE_SUBTITLE = 'system_page_subtitle',
  SYSTEM_NO_DEVICES = 'system_no_devices',
  SYSTEM_POPUP_REBOOT_CONFIRMATION_TITLE = 'system_popup_reboot_confirmation_title',
  SYSTEM_POPUP_REBOOT_CONFIRMATION_CONTENT = 'system_popup_reboot_confirmation_content',
  SYSTEM_POPUP_REBOOT_CONFIRMATION_CANCEL = 'system_popup_reboot_confirmation_cancel',
  SYSTEM_POPUP_REBOOT_CONFIRMATION_VALIDATE = 'system_popup_reboot_confirmation_validate',
  SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_TITLE = 'system_popup_factory_reset_confirmation_title',
  SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_CONTENT = 'system_popup_factory_reset_confirmation_content',
  SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_CANCEL = 'system_popup_factory_reset_confirmation_cancel',
  SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_VALIDATE = 'system_popup_factory_reset_confirmation_validate',
  SYSTEM_REBOOT_PROCESSING_MESSAGE = 'system_reboot_processing_message',
  SYSTEM_REBOOT_ERROR_MESSAGE = 'system_reboot_error_message',
  SYSTEM_FACTORY_RESET_PROCESSING_MESSAGE = 'system_factory_reset_processing_message',
  SYSTEM_FACTORY_RESET_ERROR_MESSAGE = 'system_factory_reset_error_message',
  SYSTEM_BUTTON_REBOOT = 'system_button_reboot',
  SYSTEM_BUTTON_REBOOT_MANY_DEVICE = 'system_button_reboot_many_device',
  SYSTEM_BUTTON_REBOOT_HOVER_MESSAGE = 'system_button_reboot_hover_message',
  SYSTEM_BUTTON_FACTORY_RESET_TIMEZONE = 'system_button_factory_reset_timezone',
  SYSTEM_BUTTON_FACTORY_RESET_TIMEZONE_MANY_DEVICE = 'system_button_factory_reset_timezone_many_device',
  SYSTEM_BUTTON_FACTORY_RESET_HOVER_MESSAGE = 'system_button_factory_reset_hover_message',
  SYSTEM_TABLE_COLUMN_TITLE_DEVICES = 'system_table_column_title_devices',
  SYSTEM_TABLE_COLUMN_TITLE_LAST_REBOOT = 'system_table_column_title_last_reboot',
  SYSTEM_TABLE_COLUMN_TITLE_REBOOT = 'system_table_column_title_reboot',
  SYSTEM_TABLE_COLUMN_TITLE_FACTORY_RESET = 'system_table_column_title_factory_reset',
  SYSTEM_TABLE_BUTTON_REBOOT = 'system_table_button_reboot',
  SYSTEM_TABLE_BUTTON_FACTORY_RESET = 'system_table_button_factory_reset',
  SYSTEM_TABLE_BUTTON_FACTORY_RESET_HOVER_MESSAGE = 'system_table_button_factory_reset_hover_message',

  // Device -> Update
  UPDATE_PAGE_TITLE = 'update_page_title',
  UPDATE_PAGE_SUBTITLE = 'update_page_subtitle',
  UPDATE_NO_DEVICES = 'update_no_devices',
  UPDATE_START_UPDATE_ERROR = 'update_start_update_error',
  UPDATE_START_INSTALL_ERROR = 'update_start_install_error',
  UPDATE_ABORT_ERROR = 'update_abort_error',
  UPDATE_POPUP_INSTALL_CONFIRMATION_TITLE = 'update_popup_install_confirmation_title',
  UPDATE_POPUP_INSTALL_CONFIRMATION_CONTENT = 'update_popup_install_confirmation_content',
  UPDATE_POPUP_INSTALL_CONFIRMATION_CONTENT_MANY_DEVICE = 'update_popup_install_confirmation_content_many_device',
  UPDATE_POPUP_INSTALL_CONFIRMATION_CANCEL = 'update_popup_install_confirmation_cancel',
  UPDATE_POPUP_INSTALL_CONFIRMATION_VALIDATE = 'update_popup_install_confirmation_validate',
  UPDATE_VERSIONS_MESSAGE_NO_VALIDE_VERSION = 'update_versions_message_no_valide_version',
  UPDATE_VERSIONS_MESSAGE_NO_VERSION_DETECTED = 'update_versions_message_no_version_detected',
  UPDATE_VERSIONS_MESSAGE_EVERYTHING_UP_TO_DATE = 'update_versions_message_everything_up_to_date',
  UPDATE_VERSIONS_MESSAGE_UPDATE_AVAILABLE = 'update_versions_message_update_available',
  UPDATE_VERSIONS_MESSAGE_UPDATES_AVAILABLE = 'update_versions_message_updates_available',
  UPDATE_VERSIONS_DETAILS_VERSION = 'update_versions_details_version',
  UPDATE_VERSIONS_DETAILS_DATE = 'update_versions_details_date',
  UPDATE_BUTTON_UPDATE_NOW = 'update_button_update_now',
  UPDATE_BUTTON_UPDATE_NOW_HOVER_MESSAGE = 'update_button_update_now_hover_message',
  UPDATE_BUTTON_INSTALL_NOW = 'update_button_install_now',
  UPDATE_BUTTON_INSTALL_NOW_HOVER_MESSAGE = 'update_button_install_now_hover_message',
  UPDATE_TABLE_COLUMN_TITLE_DEVICES = 'update_table_column_title_devices',
  UPDATE_TABLE_COLUMN_TITLE_VERSION = 'update_table_column_title_version',
  UPDATE_TABLE_COLUMN_TITLE_DATE = 'update_table_column_title_date',
  UPDATE_TABLE_COLUMN_TITLE_STATE = 'update_table_column_title_state',
  UPDATE_TABLE_VERSION_HOVER_UNKNOWN = 'update_table_version_hover_unknown',
  UPDATE_TABLE_VERSION_HOVER_TO_UPDATE = 'update_table_version_hover_to_update',
  UPDATE_TABLE_VERSION_HOVER_UP_TO_DATE = 'update_table_version_hover_up_to_date',
  UPDATE_TABLE_VERSION_HOVER_ERROR = 'update_table_version_hover_error',
  UPDATE_TABLE_ACTION_BUTTON_INSTALL = 'update_table_action_button_install',
  UPDATE_TABLE_ACTION_BUTTON_UPDATE = 'update_table_action_button_update',
  UPDATE_TABLE_ACTION_BUTTON_RETRY = 'update_table_action_button_retry',
  UPDATE_TABLE_STATE_TIME_REMAINING = 'update_table_state_time_remaining',
  UPDATE_TABLE_STATE_TIME_REMAINING_SECONDS = 'update_table_state_time_remaining_seconds',
  UPDATE_TABLE_STATE_TIME_REMAINING_MINUTE = 'update_table_state_time_remaining_minute',
  UPDATE_TABLE_STATE_TIME_REMAINING_MINUTES = 'update_table_state_time_remaining_minutes',
  UPDATE_TABLE_STATE_TIME_REMAINING_HOUR = 'update_table_state_time_remaining_hour',
  UPDATE_TABLE_STATE_TIME_REMAINING_HOURS = 'update_table_state_time_remaining_hours',
  UPDATE_TABLE_STATE_TIME_REMAINING_SUP_ONE_DAY = 'update_table_state_time_remaining_sup_one_day',
  UPDATE_TABLE_STATE_TIME_REMAINING_INF_TEN_SECONDS = 'update_table_state_time_remaining_inf_ten_seconds',
  UPDATE_TABLE_STATE_ERROR_ABSTRACT = 'update_table_state_error_abstract',
  UPDATE_TABLE_STATE_DOWNLOAD_PREPARATION = 'update_table_state_download_preparation',
  UPDATE_TABLE_STATE_DOWNLOAD_PREPARATION_ABSTRACT = 'update_table_state_download_preparation_abstract',
  UPDATE_TABLE_STATE_DOWNLOADING_ABSTRACT = 'update_table_state_downloading_abstract',
  UPDATE_TABLE_STATE_WAITING_FOR_INSTALL = 'update_table_state_waiting_for_install',
  UPDATE_TABLE_STATE_WAITING_FOR_INSTALL_ABSTRACT = 'update_table_state_waiting_for_install_abstract',
  UPDATE_TABLE_STATE_INSTALLING = 'update_table_state_installing',
  UPDATE_TABLE_STATE_INSTALLING_ABSTRACT = 'update_table_state_installing_abstract',
  UPDATE_TABLE_STATE_ABORTED = 'update_table_state_aborted',
  UPDATE_TABLE_STATE_ABORTED_ABSTRACT = 'update_table_state_aborted_abstract',
  UPDATE_TABLE_STATE_FAILED = 'update_table_state_failed',
  UPDATE_TABLE_STATE_FAILED_ABSTRACT = 'update_table_state_failed_abstract',
  UPDATE_TABLE_STATE_SUCCESS = 'update_table_state_success',
  UPDATE_TABLE_STATE_SUCCESS_ABSTRACT = 'update_table_state_success_abstract',
  UPDATE_TABLE_STATE_UP_TO_DATE = 'update_table_state_up_to_date',
  UPDATE_TABLE_STATE_DISCONNECTED = 'update_table_state_disconnected',
  UPDATE_TABLE_STATE_SCHEDULED = 'update_table_state_scheduled',
  UPDATE_TABLE_STATE_SCHEDULED_ABSTRACT = 'update_table_state_scheduled_abstract',
  UPDATE_TABLE_STATE_ABORT_SCHEDULED = 'update_table_state_abort_scheduled',
  UPDATE_TABLE_STATE_ABORT_SCHEDULED_ABSTRACT = 'update_table_state_abort_scheduled_abstract',
  UPDATE_POPUP_SCHEDULE_UPDATE_TITLE = 'update_popup_schedule_update_title',
  UPDATE_POPUP_SCHEDULE_ONE_UPDATE_CONTENT = 'update_popup_schedule_one_update_content',
  UPDATE_POPUP_SCHEDULE_MANY_UPDATE_CONTENT = 'update_popup_schedule_many_update_content',
  UPDATE_POPUP_SCHEDULE_UPDATE_CANCEL = 'update_popup_schedule_update_cancel',
  UPDATE_POPUP_SCHEDULE_UPDATE_VALIDATE = 'update_popup_schedule_update_validate',
  UPDATE_POPUP_SCHEDULE_UPDATE_ERROR_UNKNOWN = 'update_popup_schedule_update_error_unknown',
  UPDATE_POPUP_SCHEDULE_UPDATE_ERROR_SAME_TIME = 'update_popup_schedule_update_error_same_time',
  UPDATE_POPUP_SCHEDULE_UPDATE_WARING_TWO_DAYS = 'update_popup_schedule_update_waring_two_days',
  UPDATE_POPUP_SCHEDULE_UPDATE_WARING_SMALL_RANGE = 'update_popup_schedule_update_waring_small_range',
  UPDATE_BUTTON_SCHEDULE_UPDATE = 'update_button_schedule_update',
  UPDATE_BUTTON_SCHEDULE_UPDATE_HOVER_MESSAGE = 'update_button_schedule_update_hover_message',

  // FORBIDDEN - Page
  FORBIDDEN_PAGE_CONTACT_ADMINISTRATOR = 'forbidden_page_contact_administrator',
  FORBIDDEN_PAGE_USER_BANNED_TITLE = 'forbidden_page_user_banned_title',
  FORBIDDEN_PAGE_USER_BANNED_MESSAGE = 'forbidden_page_user_banned_message',
  FORBIDDEN_PAGE_USER_DISABLED_TITLE = 'forbidden_page_user_disabled_title',
  FORBIDDEN_PAGE_USER_DISABLED_MESSAGE = 'forbidden_page_user_disabled_message',
  FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_TITLE = 'forbidden_page_user_trial_period_is_over_title',
  FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_MESSAGE = 'forbidden_page_user_trial_period_is_over_message',
  FORBIDDEN_PAGE_ORGANIZATION_DISABLED_TITLE = 'forbidden_page_organization_disabled_title',
  FORBIDDEN_PAGE_ORGANIZATION_DISABLED_MESSAGE = 'forbidden_page_organization_disabled_message',
  FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_TITLE = 'forbidden_page_organization_trial_period_is_over_title',
  FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_MESSAGE = 'forbidden_page_organization_trial_period_is_over_message',
  FORBIDDEN_PAGE_ERROR_TITLE = 'forbidden_page_error_title',
  FORBIDDEN_PAGE_ERROR_MESSAGE = 'forbidden_page_error_message',

  // Password recovery
  PASSWORD_RECOVERY_EXAMPLE_PASSWORD = '****',
  PASSWORD_RECOVERY_CONFIRM = 'password_recovery_confirm',
  PASSWORD_RECOVERY_CONFIRM_PASSWORD = 'password_recovery_confirm_password',
  PASSWORD_RECOVERY_BAD_EMAIL = 'password_recovery_bad_email',
  PASSWORD_RECOVERY_ENTER_PASSWORD = 'password_recovery_enter_password',
  PASSWORD_RECOVERY_BAD_PASSWORD = 'password_recovery_bad_password',
  PASSWORD_RECOVERY_NOT_SAME_PASSWORD = 'password_recovery_not_same_password',
  PASSWORD_RECOVERY_EMAIL_SENT = 'password_recovery_email_sent',
  PASSWORD_RECOVERY_SUCCESS = 'password_recovery_success',
  PASSWORD_RECOVERY_REFUSED = 'password_recovery_refused',
  PASSWORD_RECOVERY_INVALID_KEY = 'password_recovery_invalid_key',
  PASSWORD_RECOVERY_FORGOT_PASSWORD = 'password_recovery_forgot_password',
}

export default {
  Strings,
};
