import { DeviceUpdateSessionMode, DeviceUpdateSessionStatus, VersionState } from '@kalyzee/kast-websocket-module';
import React, { useImperativeHandle, useRef } from 'react';
import { useDevicesUpdateProgressionsForSession } from '../../../../features/device/hooks';
import { MessageOnHoverMode } from '../../utils/MessageOnHover';
import ProgressBar from '../../utils/ProgressBar';
import { TableUpdateDevicesData } from './TableUpdateDevices.constant';
import { useElementSize } from '../../../hooks/window';
import { useStateLimitRefreshRate } from '../../../hooks/utils';
import { Strings } from '../../../constants/translation';
import { useAppTranslation } from '../../../hooks/translation';
import ContentWithIcon from '../../utils/ContentWithIcon';

import styles from './TableUpdateDevicesStateCell.module.css';

export interface TableUpdateDevicesStateCellRef { }

export interface TableUpdateDevicesStateCellProps {
  data: TableUpdateDevicesData;
  className?: string;
  style?: React.CSSProperties;
}
const TableUpdateDevicesStateCell = React.forwardRef((
  {
    data,
    className,
    style,
  }: TableUpdateDevicesStateCellProps,
  forwardRef: React.ForwardedRef<TableUpdateDevicesStateCellRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const { session } = data;
  const progression = useDevicesUpdateProgressionsForSession(session?.id);
  const elementRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(elementRef);
  const [estimateTimeRemaining, setEstimateTimeRemaining] = useStateLimitRefreshRate<number>(1000);

  if (progression?.estimateTimeRemaining) {
    setEstimateTimeRemaining(progression.estimateTimeRemaining);
  }

  useImperativeHandle(forwardRef, () => ({}));

  const dateToStrTimeFormat = (value: string | Date | undefined): string => {
    if (value === undefined) return '-';
    const date: Date = typeof value === 'string' ? new Date(value) : value;
    return `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`;
  };
  const getEsimateTimeRemainingDetails = (value: number | undefined) => {
    const getText = () => {
      if (value === undefined) return '-';
      const inSeconds = Math.ceil(value / 1000);
      const inMinutes = Math.ceil(value / 1000 / 60);
      const inHours = Math.ceil(value / 1000 / 60 / 60);
      if (inSeconds < 10) {
        return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_INF_TEN_SECONDS);
      }
      if (inSeconds <= 59) {
        return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_SECONDS, { value: inSeconds });
      }
      if (inMinutes === 1) {
        return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_MINUTE);
      }
      if (inMinutes <= 59) {
        return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_MINUTES, { value: inMinutes });
      }
      if (inHours === 1) {
        return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_HOUR);
      }
      if (inHours <= 23) {
        return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_HOURS, { value: inHours });
      }
      return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING_SUP_ONE_DAY);
    };

    return t(Strings.UPDATE_TABLE_STATE_TIME_REMAINING, { value: getText() });
  };

  const renderContent = (
    content: React.ReactNode,
    mode: MessageOnHoverMode,
    smallMessage?: string,
    thresholdWidthSmall: number = -1,
    thresholdWidthXSmall: number = 200,
  ) => (
    <div className={styles.support}>
      <ContentWithIcon
        style={{ width: '100%' }}
        mode={mode}
        smallMessage={smallMessage}
        thresholdWidthSmall={thresholdWidthSmall}
        thresholdWidthXSmall={thresholdWidthXSmall}
      >
        {content}
      </ContentWithIcon>
    </div>
  );

  const renderError = () => (
    renderContent(
      <div className={styles.text}>
        {data.error}
      </div>,
      MessageOnHoverMode.ERROR,
      t(Strings.UPDATE_TABLE_STATE_ERROR_ABSTRACT),
    )
  );

  const renderDownloadingState = () => {
    if (!progression) {
      return (
        renderContent(
          <div className={styles.text}>
            {t(Strings.UPDATE_TABLE_STATE_DOWNLOAD_PREPARATION)}
          </div>,
          MessageOnHoverMode.INFO,
          t(Strings.UPDATE_TABLE_STATE_DOWNLOAD_PREPARATION_ABSTRACT),
          300,
        )
      );
    }
    const percentage = Math.min((progression.currentSize / progression.totalSize) * 100, 99);
    return (
      renderContent(
        <div className={styles.progressDownloadContainer}>
          <div className={styles.progressBardDownloadContainer}>
            <ProgressBar
              showPercentage={false}
              className={styles.progressBar}
              percentage={percentage}
            />
            <div className={styles.progressBarValue}>
              {`${(percentage).toFixed(0)}%`}
            </div>
          </div>
          {getEsimateTimeRemainingDetails(estimateTimeRemaining)}
        </div>,
        MessageOnHoverMode.INFO,
        t(Strings.UPDATE_TABLE_STATE_DOWNLOADING_ABSTRACT),
        300,
      )
    );
  };

  const renderWaitingForInstallState = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_WAITING_FOR_INSTALL)}
      </div>,
      MessageOnHoverMode.SUCCESS,
      t(Strings.UPDATE_TABLE_STATE_WAITING_FOR_INSTALL_ABSTRACT),
    )
  );

  const renderInstallingState = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_INSTALLING)}
      </div>,
      MessageOnHoverMode.INFO,
      t(Strings.UPDATE_TABLE_STATE_INSTALLING_ABSTRACT),
    )
  );

  const renderAbortedState = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_ABORTED)}
      </div>,
      MessageOnHoverMode.WARNING,
      t(Strings.UPDATE_TABLE_STATE_ABORTED_ABSTRACT),
    )
  );

  const renderScheduledAbortedState = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_ABORT_SCHEDULED, {
          start: dateToStrTimeFormat(data.session?.scheduledWindowStartsAt),
          end: dateToStrTimeFormat(data.session?.scheduledWindowEndsAt),
        })}
      </div>,
      MessageOnHoverMode.WARNING,
      t(Strings.UPDATE_TABLE_STATE_ABORT_SCHEDULED_ABSTRACT),
    )
  );

  const renderFailedState = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_FAILED)}
      </div>,
      MessageOnHoverMode.ERROR,
      t(Strings.UPDATE_TABLE_STATE_FAILED_ABSTRACT),
    )
  );

  const renderSuccessState = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_SUCCESS)}
      </div>,
      MessageOnHoverMode.SUCCESS,
      t(Strings.UPDATE_TABLE_STATE_SUCCESS_ABSTRACT),
    )
  );

  const renderScheduled = () => (
    renderContent(
      <div className={styles.text}>
        {t(Strings.UPDATE_TABLE_STATE_SCHEDULED, {
          start: dateToStrTimeFormat(data.session?.scheduledWindowStartsAt),
          end: dateToStrTimeFormat(data.session?.scheduledWindowEndsAt),
        })}
      </div>,
      MessageOnHoverMode.INFO,
      t(Strings.UPDATE_TABLE_STATE_SCHEDULED_ABSTRACT),
      350,
    )
  );

  const renderUpToDate = () => (
    t(Strings.UPDATE_TABLE_STATE_UP_TO_DATE)
  );

  const renderToUpdate = () => null;

  const renderDisconnected = () => (
    t(Strings.UPDATE_TABLE_STATE_DISCONNECTED)
  );

  const renderCell = () => {
    if (!data.online) {
      return renderDisconnected();
    }
    if (data.online && data.state === VersionState.UP_TO_DATE) {
      return renderUpToDate();
    }
    if (data.error) return renderError();
    if (data.scheduled) {
      return renderScheduled();
    }
    if (!session) {
      if (data.online) {
        return renderToUpdate();
      }
      return null;
    }
    if (session.status === DeviceUpdateSessionStatus.DOWNLOADING) {
      return renderDownloadingState();
    }
    if (session.status === DeviceUpdateSessionStatus.WAITING_FOR_INSTALL) {
      return renderWaitingForInstallState();
    }
    if (session.status === DeviceUpdateSessionStatus.INSTALLING) {
      return renderInstallingState();
    }
    if (session.status === DeviceUpdateSessionStatus.ABORTED) {
      if (session.mode === DeviceUpdateSessionMode.SILENT) return renderScheduledAbortedState();
      return renderAbortedState();
    }
    if (session.status === DeviceUpdateSessionStatus.FAILED) {
      return renderFailedState();
    }
    if (session.status === DeviceUpdateSessionStatus.DONE) {
      return renderSuccessState();
    }
    return null;
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={elementRef}
    >
      {renderCell()}
    </div>
  );
});

TableUpdateDevicesStateCell.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableUpdateDevicesStateCell;
