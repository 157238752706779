import { createAction } from '@reduxjs/toolkit';

// Socket Actions :
export const socketConnect = createAction('socket_connect');
export const socketDisconnect = createAction('socket_disconnect');

export default {
  socketConnect,
  socketDisconnect,
};
