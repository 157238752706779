import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectError } from './selectors';

import CloseSvg from '../../assets/icons/close.svg';
import styles from './ErrorNotifier.module.css';
import TouchableImage from '../../common/components/utils/TouchableImage';
import { ErrorType } from '../../common/helpers/errors';
import { retryRequest } from '../session/actions';
import { useAppError } from './hooks';
import { useAppTranslation } from '../../common/hooks/translation';
import { Strings } from '../../common/constants/translation';

export const ErrorNotifier = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { error, reset } = useAppError();

  const retry = () => {
    reset();
    dispatch(retryRequest());
  };

  const renderRetryButton = () => error?.type === ErrorType.NetworkError && (
    <button type="submit" onClick={retry}>{t(Strings.RETRY)}</button>
  );

  if (error) {
    return (
      <div className={styles.container}>
        <div className={`${styles.content} ${styles.animation}`}>
          <div className={styles.title}>{t(Strings.ERROR)}</div>
          <p>
            {t(error.message)}
          </p>
          { renderRetryButton() }
          <TouchableImage
            onPressOut={() => reset()}
            className={styles.closeIcon}
            alt="close error notification"
            src={CloseSvg}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default {
  ErrorNotifier,
};
