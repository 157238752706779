import React, { useImperativeHandle, useRef, useState } from 'react';
import { DeviceVideoRecordState } from '@kalyzee/kast-websocket-module';
import Colors from '../../constants/colors';
import Fonts from '../../../constants/fonts';
import { DeviceData } from '../../../features/device/interfaces';
import { Strings } from '../../constants/translation';
import { useAppTranslation } from '../../hooks/translation';
import { useElementSize } from '../../hooks/window';
import TouchableImage from '../utils/TouchableImage';
import Close from '../../../assets/icons/close.svg';
import MessageOnHover, { MessageOnHoverMode } from '../utils/MessageOnHover';

const DEFAULT_CLASSNAME = 'player-container';

const HEADER_HEIGHT = 30;
const FOOTER_HEIGHT = 30;
const MARGE_CONTENT = 5;

const containerStyle : React.CSSProperties = {
  position: 'relative',
  maxHeight: '100%',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: Colors.getClayEbony(),
  borderRadius: '10px',
};

const headerStyle : React.CSSProperties = {
  width: '100%',
  height: `${HEADER_HEIGHT}px`,
  maxHeight: `${HEADER_HEIGHT}px`,
  lineHeight: `${HEADER_HEIGHT}px`,
  color: Colors.getMainWhite(),
  fontFamily: Fonts.getSignika(),
  textAlign: 'center',
};

const footerStyle : React.CSSProperties = {
  width: '100%',
  height: `${FOOTER_HEIGHT}px`,
  maxHeight: `${FOOTER_HEIGHT}px`,
  lineHeight: `${FOOTER_HEIGHT}px`,
  textAlign: 'center',
  color: Colors.getMainWhite(),
  fontFamily: Fonts.getSignika(),
};

const textWrapEllipsis : React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const childrenContainerStyle : React.CSSProperties = {
  borderRadius: '10px',
  overflow: 'hidden',
  flex: 1,
  minHeight: '0px',
  marginLeft: `${MARGE_CONTENT}px`,
  marginRight: `${MARGE_CONTENT}px`,
  width: `calc(100% - ${2 * MARGE_CONTENT}px)`,
};

const closeButtonStyle : React.CSSProperties = {
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  border: `2px solid ${Colors.getBlueOxford()}`,
  backgroundColor: Colors.getClayEbony(0.9),
  borderRadius: '100%',
  width: '30px',
  height: '30px',
  fontSize: '1.6em',
  color: Colors.getMainWhite(),
  cursor: 'pointer',
};

const closeImgButtonStyle : React.CSSProperties = {
  width: '60%',
  height: '60%',
};

export interface PlayerContainerRef {
  resize: () => void;
}

export interface PlayerContainerProps extends
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  autoResize?: boolean;
  data?: DeviceData;
  closeButton?: boolean;
  onClose?: () => void;
  children? : React.ReactNode;
  contentStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
}
const PlayerContainer = React.forwardRef(({
  autoResize,
  data,
  children,
  closeButton,
  onClose,
  contentStyle,
  className,
  style,
  ...props
} : PlayerContainerProps, forwardRef : React.ForwardedRef<PlayerContainerRef | undefined>) => {
  const { t } = useAppTranslation();
  const [ratio, setRatio] = useState<number>(16 / 9);
  const supportRef = useRef<HTMLElement>();
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  useElementSize(supportRef);

  if (containerRef.current?.parentElement) supportRef.current = containerRef?.current.parentElement;

  const renderHeader = () => {
    const elementRef = { current: null };
    let state : string = '';
    let messageOnHover : string | undefined;
    const pointStyle : React.CSSProperties = {
      marginRight: '2px',
      marginLeft: '5px',
    };
    const textStyle : React.CSSProperties = {
      marginRight: '5px',
    };
    if (data) {
      if (data.isOnline) {
        if (data.isInLive && data.recordState === DeviceVideoRecordState.RUNNING) {
          state = t(Strings.IN_LIVE_RECORDING);
          pointStyle.color = Colors.getRed();
          if (data.liveStartedAt) {
            messageOnHover = t(Strings.DEVICE_LIVE_STARTED_AT, {
              date: data.liveStartedAt.toLocaleString(),
            });
          }
          if (data.recordStartedAt) {
            messageOnHover = messageOnHover ? `${messageOnHover}\n` : '';
            messageOnHover += t(Strings.DEVICE_RECORD_STARTED_AT, {
              date: data.recordStartedAt.toLocaleString(),
            });
          }
        } else if (data.isInLive) {
          state = t(Strings.IN_LIVE);
          pointStyle.color = Colors.getRed();
          if (data.liveStartedAt) {
            messageOnHover = t(Strings.DEVICE_LIVE_STARTED_AT, {
              date: data.liveStartedAt.toLocaleString(),
            });
          }
        } else if (data.recordState === DeviceVideoRecordState.RUNNING) {
          state = t(Strings.RECORDING);
          pointStyle.color = Colors.getRed();
          if (data.recordStartedAt) {
            messageOnHover = t(Strings.DEVICE_RECORD_STARTED_AT, {
              date: data.recordStartedAt.toLocaleString(),
            });
          }
        } else {
          state = t(Strings.ONLINE);
          pointStyle.color = Colors.getMainGreen();
        }
      } else {
        state = t(Strings.OFFLINE);
        pointStyle.color = Colors.getGrayDusty();
      }
    }
    return (
      <div
        style={{ ...headerStyle, ...textWrapEllipsis }}
        ref={elementRef}
      >
        <span style={pointStyle}>●</span>
        <span style={textStyle}>
          {
            messageOnHover ? (
              <MessageOnHover
                targetRef={elementRef}
                mode={MessageOnHoverMode.INFO}
                message={messageOnHover}
              />
            ) : null
          }
          { state }
        </span>
      </div>
    );
  };

  // resize to fit to width or height and keep ratio
  const resize = () => {
    const containerElement = containerRef.current;
    const supportElement = containerElement?.parentElement;
    if (supportElement && containerElement) {
      const supportWidth = supportElement.clientWidth;
      const supportHeight = supportElement.clientHeight;

      const containerPaddingHeight = FOOTER_HEIGHT + HEADER_HEIGHT;
      const containerPaddingWidth = 2 * MARGE_CONTENT;

      // If ratio is keeped
      const widthIfHeightFitted = containerPaddingWidth + (supportHeight - containerPaddingHeight)
        * ratio;
      const heightIfWidthFitted = containerPaddingHeight + (supportWidth - containerPaddingWidth)
        / ratio;

      let width = supportWidth;
      let height = supportHeight;

      if (heightIfWidthFitted > supportHeight) {
        width = widthIfHeightFitted;
      } else {
        height = heightIfWidthFitted;
      }

      containerElement.style.width = `${width}px`;
      containerElement.style.height = `${height}px`;
    }
  };

  if (contentRef.current) {
    const videos = contentRef.current.getElementsByTagName('video');
    if (videos.length && videos[0]) {
      const video = videos[0];
      video.onloadedmetadata = () => {
        if (video.videoWidth && video.videoHeight) {
          setRatio(video.videoWidth / video.videoHeight);
        }
        resize();
      };
    }
  }

  if (autoResize) resize();

  useImperativeHandle(forwardRef, () => ({
    resize,
  }));

  const renderContent = () => (
    <div ref={contentRef} style={{ ...childrenContainerStyle, ...(contentStyle || {}) }}>
      { children }
    </div>
  );

  const renderFooter = () => (
    <div style={{ ...footerStyle, ...textWrapEllipsis }}>
      { data?.displayName || null }
    </div>
  );

  const renderCloseButton = () => {
    if (!closeButton) return null;
    return (
      <TouchableImage
        src={Close}
        alt="close"
        style={closeButtonStyle}
        imageStyle={closeImgButtonStyle}
        onPress={() => onClose?.()}
      />
    );
  };

  return (
    <div
      {...props}
      className={className}
      style={{ ...containerStyle, ...(style || {}) }}
      ref={containerRef}
    >
      { renderHeader() }
      { renderContent() }
      { renderFooter() }
      { renderCloseButton() }
    </div>
  );
});

PlayerContainer.defaultProps = {
  autoResize: true,
  data: undefined,
  closeButton: false,
  onClose: undefined,
  children: undefined,
  contentStyle: undefined,
  className: DEFAULT_CLASSNAME,
  style: undefined,
};

export default PlayerContainer;
