import { useTranslation } from 'react-i18next';
import { Strings } from '../constants/translation';

export const useAppTranslation = (namespace?: string) => {
  const translation = useTranslation(namespace);
  // TODO : 'string' allowed here so we are not stuck with unknown strings
  // ex : strings coming from server side, we might want to restrict string to known strings only.
  const { t } = translation;
  return { t };
};

export default {
  useAppTranslation,
};
