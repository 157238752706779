import { call, put } from 'redux-saga/effects';
import { ErrorData, ErrorType, ResponseError } from '../../common/helpers/errors';
import { setError } from './slice';
import { createError } from './utils';

export function* handleError(error: any) {
  let errorData: ErrorData = (error as ResponseError).data;
  if (!errorData) {
    errorData = yield call(createError, ErrorType.ClientError, undefined, undefined);
  }
  yield put(setError(errorData));
}

export default {
  handleError,
};
