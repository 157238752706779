import React, { useImperativeHandle, useRef } from 'react';
import CountryAndTimezones from 'countries-and-timezones';
import Colors from '../../../constants/colors';
import { useElementSize } from '../../../hooks/window';
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from '../../utils/Table';
import { useRender } from '../../../hooks/component';
import { useAppTranslation } from '../../../hooks/translation';
import { TableTimeDevicesAction, TableTimeDevicesData } from './TableTimeDevices.constant';
import DateTimeLabel, { dateTimeLabelPredefinedOptions } from '../../utils/DateTimeLabel';
import ContentWithIcon from '../../utils/ContentWithIcon';
import { MessageOnHoverMode } from '../../utils/MessageOnHover';
import { resetAllSvgAnimations } from '../../../helpers/element';
import Touchable from '../../utils/Touchable';
import { Strings } from '../../../constants/translation';

import { ReactComponent as IconLoading } from '../../../../assets/icons/loading.svg';
import { ReactComponent as IconSuccess } from '../../../../assets/icons/success.svg';

import styles from './TableTimeDevices.module.css';
import commonStyles from '../TableCommon.module.css';

export interface TableTimeDevicesRef {
  render: () => void;
}

export interface TableTimeDevicesProps {
  data: TableTimeDevicesData[];
  onItemChecked?: (item: TableTimeDevicesData) => void;
  onAction?: (action: TableTimeDevicesAction, item: TableTimeDevicesData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableTimeDevices = React.forwardRef((
  {
    data,
    onItemChecked,
    onAction,
    className,
    style,
  }: TableTimeDevicesProps,
  forwardRef: React.ForwardedRef<TableTimeDevicesRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const render = useRender();
  const size = useElementSize(containerRef);
  let iconLoadingRef: TableContentRef[] = [];

  useImperativeHandle(forwardRef, () => ({
    render,
  }));

  const getCurrentDate = (item : TableTimeDevicesData) : Date | undefined => {
    if (!item.timeOffset) return undefined;
    return new Date(new Date().getTime() - item.timeOffset);
  };
  const getLocalDate = (item : TableTimeDevicesData) : Date | undefined => {
    if (!item.timeOffset || !item.timezone) return undefined;
    const timezoneDetails = CountryAndTimezones.getTimezone(item.timezone);
    if (!timezoneDetails) return undefined;
    const dstOffsetMS = timezoneDetails.dstOffset * 60 * 1000;
    const localOffsetMS = new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(new Date().getTime() - item.timeOffset + dstOffsetMS + localOffsetMS);
  };

  const sortByDate = (
    extractor : (item : TableTimeDevicesData) => Date | undefined,
    multiplicator : number = 1,
  ) => (a : TableTimeDevicesData, b : TableTimeDevicesData) => {
    const aDateTime = extractor(a)?.getTime() ?? 0;
    const bDateTime = extractor(b)?.getTime() ?? 0;
    return multiplicator * (aDateTime - bDateTime);
  };

  const generateConfiguration = () => {
    const columnConfiguration: TableConfColumn<TableTimeDevicesData>[] = [
      {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'name',
        width: '15%',
        minWidth: '7.5rem',
        title: t(Strings.TIME_TABLE_COLUMN_TITLE_DEVICES),
        header: { className: commonStyles.tableHeaderCellDevice },
        item: { className: commonStyles.tableCellDevice },
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'date',
        title: t(Strings.TIME_TABLE_COLUMN_TITLE_DATE),
        minWidth: '10rem',
        ascendantSort: sortByDate(getCurrentDate),
        descendantSort: sortByDate(getCurrentDate, -1),
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'time',
        title: t(Strings.TIME_TABLE_COLUMN_TITLE_TIME_LOCAL_TIMEZONE),
        minWidth: '8rem',
        ascendantSort: sortByDate(getCurrentDate),
        descendantSort: sortByDate(getCurrentDate, -1),
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'localTime',
        title: t(Strings.TIME_TABLE_COLUMN_TITLE_TIME_DEVICE_TIMEZONE),
        minWidth: '9rem',
        ascendantSort: sortByDate(getLocalDate),
        descendantSort: sortByDate(getLocalDate, -1),
      },
    ];

    const tableConfiguration: TableConf<TableTimeDevicesData> = {
      columns: columnConfiguration,
      header: {
        className: commonStyles.tableHeader,
        cell: {
          className: commonStyles.tableHeaderCell,
        },
      },
      row: {
        className: commonStyles.tableRow,
        cell: {
          className: commonStyles.tableRowCell,
        },
      },
      content: { className: commonStyles.tableContent },
      valueToShowIfUndefined: { value: '-', className: commonStyles.tableUndefinedValue },
    };

    return tableConfiguration;
  };

  // Called when a value is changed. Checkboxes here
  const valueChanged = (value: any, columnKey: string, item: TableTimeDevicesData) => {
    if (columnKey === 'checked') onItemChecked?.(item);
  };

  const customRenderCell = (
    element: JSX.Element | null,
    elementRef: TableContentRef,
    columnKey: string,
    item: TableTimeDevicesData,
  ) => {
    if (columnKey === 'checked') {
      if (!item.online) return element;
      if (item.error) {
        return (
          <div ref={elementRef} style={{ color: Colors.getGrayDusty() }}>
            -
          </div>
        );
      }
      if (item.processing) {
        iconLoadingRef.push(elementRef);
        return (
          <div ref={elementRef}>
            <IconLoading
              width="20px"
            />
          </div>
        );
      }
      if (item.success) {
        return (
          <IconSuccess
            width="20px"
            height="20px"
            fill={Colors.getMountainMeadow()}
          />
        );
      }
    }
    if (columnKey === 'date') {
      const date = getCurrentDate(item);
      if (!item.online || !date) return '-';
      return (
        <DateTimeLabel
          dateTime={date}
          options={dateTimeLabelPredefinedOptions.DATE_ONLY}
        />
      );
    }
    if (columnKey === 'time') {
      const date = getCurrentDate(item);
      if (!item.online || !date) return '-';
      return (
        <DateTimeLabel
          dateTime={date}
          options={dateTimeLabelPredefinedOptions.TIME_ONLY}
        />
      );
    }
    if (columnKey === 'localTime') {
      const date = getLocalDate(item);
      if (!item.online || !date) return '-';
      return (
        <DateTimeLabel
          dateTime={date}
          options={dateTimeLabelPredefinedOptions.TIME_ONLY}
          timezone={item.timezone}
        />
      );
    }
    return element;
  };

  const customRenderRow = (
    elements: JSX.Element[],
    item: TableTimeDevicesData,
    d: TableTimeDevicesData[],
    index: number,
  ) => {
    if (!item.online) return elements;
    if (item.error) {
      const content = (
        <div className={styles.errorMessageContainer} key={`error_${item.deviceId}`}>
          <ContentWithIcon
            className={styles.errorMessage}
            alwaysShowIcon
            mode={MessageOnHoverMode.ERROR}
          >
            {item.error}
          </ContentWithIcon>
          <Touchable
            className={commonStyles.button}
            onPressOut={() => onAction?.(TableTimeDevicesAction.CLEAN_ERROR, item)}
          >
            {t(Strings.OK)}
          </Touchable>
        </div>
      );
      return [
        elements[0],
        elements[1],
        content,
      ];
    }
    if (item.processing) {
      const content = (
        <ContentWithIcon
          className={styles.processingMessage}
          alwaysShowIcon
          mode={MessageOnHoverMode.INFO}
          key={`processing_${item.deviceId}`}
        >
          <div style={{ width: '100%' }}>
            {item.processing}
          </div>
        </ContentWithIcon>
      );
      return [
        elements[0],
        elements[1],
        content,
      ];
    }
    return elements;
  };

  const addCustomStyleOnCell = (
    columnKey: string,
    item: TableTimeDevicesData,
  ) => {
    const result: TableStyle = {};
    return result;
  };

  const addCustomStyleOnRow = (
    item: TableTimeDevicesData,
    currData: TableTimeDevicesData[],
    index: number,
  ) => {
    const result: TableStyle = {};
    const rowStyle: React.CSSProperties = {};
    result.style = rowStyle;
    if (!item.online) {
      rowStyle.opacity = 0.5;
      rowStyle.backgroundColor = Colors.getClayEbonyMedium(0.4);
      if (index === 0 || currData[index - 1].online) {
        rowStyle.borderTopLeftRadius = '10px';
        rowStyle.borderTopRightRadius = '10px';
      }
      if (index + 1 >= currData.length || currData[index + 1].online) {
        rowStyle.borderBottomLeftRadius = '10px';
        rowStyle.borderBottomRightRadius = '10px';
      }
    }
    return result;
  };

  const sortByDeviceOnline = (a: TableTimeDevicesData, b: TableTimeDevicesData) => {
    const aOnline = a.online ? 1 : 0;
    const bOnline = b.online ? 1 : 0;
    return bOnline - aOnline;
  };

  const onRenderTableStarts = () => {
    iconLoadingRef = [];
  };

  const onRenderTableEnded = () => {
    iconLoadingRef.forEach((ref) => {
      const el = ref?.current;
      if (el) resetAllSvgAnimations(el);
    });
  };

  // Transform value before to display it
  const transformValue = (columnKey: string, item: TableTimeDevicesData, value: any) => {
    if (columnKey === 'checked') {
      return (
        item.online
      ) ? value : false;
    }
    if (item.online && ['date', 'time', 'localTime'].includes(columnKey)) {
      return ''; // no set undefined value
    }
    return value;
  };

  const renderTable = () => (
    <Table
      className={commonStyles.table}
      data={data}
      keyExtractor={(_, item) => `key-${item.deviceId}`}
      transformValue={transformValue}
      configuration={generateConfiguration()}
      onData={(d) => d.sort(sortByDeviceOnline)}
      onRenderCellRow={customRenderCell}
      onRenderRow={customRenderRow}
      onStyleCellRow={addCustomStyleOnCell}
      onStyleRow={addCustomStyleOnRow}
      onChangeValue={valueChanged}
      onRenderStarts={() => onRenderTableStarts}
      onRenderEnded={onRenderTableEnded}
    />
  );

  const classes = [commonStyles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderTable()}
    </div>
  );
});

TableTimeDevices.defaultProps = {
  onItemChecked: undefined,
  onAction: undefined,
  className: undefined,
  style: undefined,
};

export default TableTimeDevices;
