import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ErrorData, ErrorType, isApiParsedError } from '../../common/helpers/errors';
import { useAppError } from '../error/hooks';
import { requestLogout, requestOrganization, requestUserMe } from './actions';
import { AuthStatus } from './interfaces';
import { selectAuthStatus, selectOrganization, selectToken, selectUser, selectUserOrganization } from './selectors';
import { setAuthStatus } from './slice';

const sessionBreakingCodes = [460, 403];

const isSessionBreakingError = (error: ErrorData): boolean => {
  if (error.type === ErrorType.ApiError && error.code) {
    return sessionBreakingCodes.includes(error.code);
  }
  return false;
};

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const organziation = useAppSelector(selectOrganization);
  const token = useAppSelector(selectToken);
  const authStatus = useAppSelector(selectAuthStatus);

  const { error } = useAppError();

  useEffect(() => {
    if (error) { // TODO : retry if ErrorType.Network and AuthStatus.Loading ?
      if (isSessionBreakingError(error)) {
        dispatch(requestLogout());
        if (error.type === ErrorType.ApiError
          && error.code === 403
          && isApiParsedError(error.data)
        ) {
          let reason: string | undefined;
          // TODO replace cname with code from @kalyzee/kast-app-module
          if (error.data?.cname === 'USER_IS_BANNED') reason = 'ubanned';
          else if (error.data?.cname === 'USER_IS_DISABLED') reason = 'udisabled';
          else if (error.data?.cname === 'USER_TRIAL_PERIOD_IS_OVER') reason = 'utrialperiodisover';
          else if (error.data?.cname === 'ORGANIZATION_IS_DISABLED') reason = 'odisabled';
          else if (error.data?.cname === 'ORGANIZATION_TRIAL_PERIOD_IS_OVER') reason = 'otrialperiodisover';

          if (reason) {
            // addition of a timeout to allow time for "Navigation" to restart
            navigate(`/forbidden?r=${reason}`);
          }
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      // TODO : admin check
      // if (user.role >= ADMIN) setIsAuthenticated(true);
      if (organziation) {
        dispatch(setAuthStatus(AuthStatus.In));
      } else {
        dispatch(requestOrganization());
      }
    } else if (token) {
      dispatch(requestUserMe());
    } else {
      dispatch(setAuthStatus(AuthStatus.Out));
    }
  }, [user, organziation, token]);

  return { authStatus, user };
};

export default {
  useAuth,
};
