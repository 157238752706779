import React, { ForwardedRef, useEffect, useImperativeHandle, useState } from 'react';
import Colors from '../../constants/colors';
import Touchable from './Touchable';

import styles from './Button.module.css';

const DEFAULT_DISABLED = false;

export enum ButtonColor {
  BLUE,
  PURPLE,
  GREEN,
  RED,
}

const COLOR_DISABLED = Colors.getGrayLevel(0.5);
const DEFAULT_COLOR = ButtonColor.PURPLE;

const COLORS : { [key in ButtonColor] : string } = {
  [ButtonColor.BLUE]: Colors.getBlueDodger(),
  [ButtonColor.PURPLE]: Colors.getBlueCornflower(),
  [ButtonColor.GREEN]: Colors.getMountainMeadow(),
  [ButtonColor.RED]: Colors.getSalem(),
};

export interface ButtonProps {
  title: string;
  color?: ButtonColor;
  disabled?: boolean;
  onPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  className?: string;
  style?: React.CSSProperties;
  disabledClassName?: string;
  disabledStyle?: React.CSSProperties;
}

export interface ButtonRef {
  updateTitle: (title: string) => void;
  updateColor: (color: ButtonColor) => void;
  enable: () => void;
  disable: () => void;
}

const Button = React.forwardRef(({
  title,
  color,
  disabled,
  onPress,
  onPressIn,
  onPressOut,
  className,
  style,
  disabledClassName,
  disabledStyle,
}: ButtonProps, forwardRef: ForwardedRef<ButtonRef | undefined>) => {
  const [backgroundColor, setBackgroundColor] = useState<ButtonColor | undefined>(color);
  const [buttonTitle, setButtonTitle] = useState<string>(title);
  const [enabled, setEnabled] = useState<boolean>(!(disabled ?? DEFAULT_DISABLED));

  useImperativeHandle(forwardRef, () => ({
    updateTitle: (t: string) => setButtonTitle(t),
    updateColor: (c: ButtonColor) => setBackgroundColor(c),
    enable: () => setEnabled(true),
    disable: () => setEnabled(false),
  }));

  useEffect(() => setBackgroundColor(color), [color]);
  useEffect(() => setButtonTitle(title), [title]);
  useEffect(() => setEnabled(!(disabled ?? DEFAULT_DISABLED)), [disabled]);

  const classes = [styles.container];
  if (!enabled) classes.push(styles.disabled);
  if (className) classes.push(className);
  if (!enabled && disabledClassName) classes.push(disabledClassName);
  const divStyle : React.CSSProperties = {
    backgroundColor: enabled ? COLORS[backgroundColor ?? DEFAULT_COLOR] : COLOR_DISABLED,
  };
  if (style) Object.assign(divStyle, style);
  if (!enabled && disabledStyle) Object.assign(divStyle, disabledStyle);
  return (
    <Touchable
      className={classes.join(' ')}
      style={divStyle}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      disabled={!enabled}
    >
      { buttonTitle }
    </Touchable>
  );
});

Button.defaultProps = {
  disabled: DEFAULT_DISABLED,
  color: DEFAULT_COLOR,
  className: undefined,
  style: undefined,
  disabledClassName: undefined,
  disabledStyle: undefined,
  onPress: undefined,
  onPressIn: undefined,
  onPressOut: undefined,
};

export default Button;
