import { DeviceContext, DeviceInformationNetworks } from '@kalyzee/kast-websocket-module';
import React, { useRef, useState } from 'react';
import PageContainer from '../../../common/components/page/PageContainer';
import TableNetworkDevices, { TableNetworkDevicesRef } from '../../../common/components/table/network/TableNetworkDevices';
import { TableNetworkDevicesData } from '../../../common/components/table/network/TableNetworkDevices.constant';
import { Strings } from '../../../common/constants/translation';
import { useAppTranslation } from '../../../common/hooks/translation';
import { useDevices } from '../../../features/device/hooks';
import { Device } from '../../../features/device/interfaces';
import { getDeviceName } from '../../../features/device/utils';

import styles from './devices.networks.module.css';

const DevicesNetworksPage = () => {
  const { t } = useAppTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const devices: Device[] = useDevices();
  const tableDataRef = useRef<TableNetworkDevicesData[]>([]);
  const [itemsChecked, setItemChecked] = useState<TableNetworkDevicesData[]>([]);
  const tableRef = useRef<TableNetworkDevicesRef>();

  if (devices.length && loading) {
    setLoading(false);
  }

  const updateItemChecked = () => {
    const current = tableDataRef.current.filter((d) => d.checked);
    let update = false;
    if (current.length === itemsChecked.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currItem1 of current) {
        if (!itemsChecked.find((currItem2) => currItem1.deviceId === currItem2.deviceId)) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) setItemChecked(current);
  };
  updateItemChecked();
  const onItemCheck = (item: TableNetworkDevicesData) => {
    updateItemChecked();
  };

  const generateTableData = (): TableNetworkDevicesData[] => {
    if (loading) return tableDataRef.current;
    const result: TableNetworkDevicesData[] = [];
    devices.forEach((device) => {
      const deviceId = device.id;
      const currentData: TableNetworkDevicesData | undefined = tableDataRef.current.find(
        (d) => d.deviceId === deviceId,
      );
      const online = !!device?.online;
      const networkContext = device.context?.networkInformation;
      const newData: TableNetworkDevicesData = {
        deviceId,
        device,
        name: getDeviceName(device, false),
        online,
        checked: currentData?.checked ?? false,
        externalIp: device.externalIp,
        localIp: networkContext?.ip,
        netmask: networkContext?.netmask,
        gateway: networkContext?.gateway,
        dns: networkContext?.dns,
        mac: networkContext?.mac,
      };
      const data: TableNetworkDevicesData = Object.assign(currentData ?? {}, newData);
      result.push(data);
    });
    tableDataRef.current = result;
    return tableDataRef.current;
  };

  const renderTable = () => (
    <TableNetworkDevices
      className={styles.table}
      ref={tableRef}
      onItemChecked={onItemCheck}
      data={generateTableData()}
    />
  );

  // ---------------- NO DATA ------------ //

  const renderNoDevices = () => (
    <div className={styles.emptyDataMessage}>
      {t(Strings.NETWORK_NO_DEVICES)}
    </div>
  );

  const notEmptyData = devices.length > 0;
  return (
    <PageContainer
      title={t(Strings.NETWORK_PAGE_TITLE)}
      subtitle={t(Strings.NETWORK_PAGE_SUBTITLE)}
      loading={loading}
    >
      <div
        className={styles.container}
      >
        {notEmptyData ? renderTable() : renderNoDevices()}
      </div>
    </PageContainer>
  );
};

export default DevicesNetworksPage;
