import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.css';
import LoginPage from '../pages/login/login';
import PrivateRoute from '../common/components/navigation/PrivateRoute';
import LogoutPage from '../pages/logout/logout';
import { useAuth } from '../features/session/hooks';
import HomePage from '../pages/home/home';
import PreviewPage from '../pages/preview/preview';
import RoomsPage from '../pages/rooms/rooms';
import UsersPage from '../pages/users/users';
import LoginRoute from '../common/components/navigation/LoginRoute';
import 'react-toastify/dist/ReactToastify.min.css';
import { ErrorNotifier } from '../features/error/ErrorNotifier';
import { generateRandomId } from '../common/helpers/utils';
import DeviceSelectorPage from '../pages/iframe/deviceSelector';
import DevicesUpdatePage from '../pages/devices/update/devices.update';
import PopupContainer from '../common/components/utils/PopupContainer';
import DevicesTimePage from '../pages/devices/time/devices.time';
import DevicesNetworksPage from '../pages/devices/networks/devices.networks';
import DevicesSystemPage from '../pages/devices/system/devices.system';
import { RouterProvider } from './RouterContext';
import ForbiddenPage from '../pages/forbidden/forbidden';
import DevicesPage from '../pages/devices/description/devices';
import ResetPasswordPage from '../pages/login/reset_password';

const App = () => {
  const { authStatus } = useAuth();

  return (
    <RouterProvider>
      <div className="App">
        <ErrorNotifier key={generateRandomId(8)} />
        <Routes>
          <Route element={<PrivateRoute redirectPath="login" authStatus={authStatus} />}>
            <Route path="/" element={<HomePage />}>
              <Route index element={<PreviewPage />} />
              <Route path="preview" element={<PreviewPage />} />
              <Route path="devices">
                <Route path="description" element={<DevicesPage />} />
                <Route path="update" element={<DevicesUpdatePage />} />
                <Route path="time" element={<DevicesTimePage />} />
                <Route path="networks" element={<DevicesNetworksPage />} />
                <Route path="system" element={<DevicesSystemPage />} />
              </Route>
              <Route path="users" element={<UsersPage />} />
              <Route path="rooms" element={<RoomsPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="iframe">
              <Route path="device-selector" element={<DeviceSelectorPage />} />
              <Route path="*" element={<Navigate to="device-selector" replace />} />
            </Route>
            <Route path="logout" element={<LogoutPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
          <Route element={<LoginRoute redirectPath="/" authStatus={authStatus} />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="forbidden" element={<ForbiddenPage />} />
            <Route path="reset_password" element={<ResetPasswordPage />} />
            <Route path="reset_password/:recoveryKey" element={<ResetPasswordPage />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Route>
          <Route path="*" />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
        />
        <PopupContainer />
      </div>
    </RouterProvider>
  );
};

export default App;
