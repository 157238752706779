export type TrackEndedMediaStreamListenerCallback = (track: MediaStreamTrack) => void;
export interface TrackEndedMediaStreamListener {
  clear : () => void;
}
export const addTrackEndedMediaStreamListener = (
  mediaStream : MediaStream,
  callback : TrackEndedMediaStreamListenerCallback,
) : TrackEndedMediaStreamListener => {
  const result : TrackEndedMediaStreamListener = {
    clear: () => null,
  };
  if (!mediaStream) return result;
  function onChange(this: MediaStreamTrack) {
    callback?.(this);
  }
  const onTrackChange = () => {
    if (!mediaStream) return;
    mediaStream.getTracks().forEach((track) => {
      track.removeEventListener('ended', onChange); // if already added
      track.addEventListener('ended', onChange);
    });
  };
  mediaStream.getTracks().forEach((track) => {
    track.addEventListener('ended', onChange);
  });
  mediaStream.addEventListener('addtrack', onTrackChange);
  mediaStream.addEventListener('removetrack', onTrackChange);
  result.clear = () => {
    if (!mediaStream) return;
    mediaStream.getTracks().forEach((track) => {
      track.removeEventListener('ended', onChange);
    });
    mediaStream.addEventListener('addtrack', onTrackChange);
    mediaStream.addEventListener('removetrack', onTrackChange);
  };
  return result;
};
