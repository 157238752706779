export enum SocketStatus {
  Online = 'online',
  Offline = 'offline',
  Loading = 'loading',
}

export interface SocketTokenResponse {
  token: string,
  expireAt: Date,
}

// Socket Actions :

export interface SocketConnectPayload {
  token: string,
}
