import React, { useState, useEffect, useRef } from 'react';
import { CheckMarkType, CustomCheckBox, CustomCheckBoxRef } from '../form/CustomCheckBox';

import IconChevron from '../../../assets/icons/chevron.svg';

import styles from './Table.module.css';

export enum TableColumnType {
  CLASSIC = 'classic',
  CHECKBOX = 'checkox',
}

export enum TableSortDirection {
  ASC = 'ascendant',
  DESC = 'descendant',
  NONE = 'none',
}

const CLASSES_SORT_DIRECTION = {
  [TableSortDirection.ASC]: styles.ascendant,
  [TableSortDirection.DESC]: styles.descendant,
  [TableSortDirection.NONE]: styles.no_sort,
};

export interface TableStyle {
  style?: React.CSSProperties,
  className?: string;
}

interface TableCommonConfColumn<T extends object> {
  title?: string;
  ascendantSort?: (a: T, b: T) => number;
  descendantSort?: (a: T, b: T) => number;
  enableSort?: boolean;
  defaultSort?: TableSortDirection,
  header?: TableStyle,
  item?: TableStyle,
}

export interface TableConfColumn<T extends object> extends TableCommonConfColumn<T> {
  width?: string; // width of column. css style : 'px' , '%', ...
  minWidth?: string;
  type?: TableColumnType;
  key: string;
}

export interface TableConfContent extends TableStyle { }
export interface TableConfHeader extends TableStyle {
  cell?: TableStyle
}
export interface TableConfRow extends TableStyle {
  cell?: TableStyle
}

export interface TableConfUndefinedValue extends TableStyle {
  value?: string;
}

const DEFAULT_TABLE_CONF_UNDEFINED_VALUE: TableConfUndefinedValue = {
  value: '',
};

export interface TableConf<T extends object> {
  content?: TableConfContent;
  header?: TableConfHeader;
  row?: TableConfRow;
  columns: TableConfColumn<T>[],
  valueToShowIfUndefined?: TableConfUndefinedValue;
}

export const DEFAULT_CONF_PRESET_COLUMN: {
  [key in TableColumnType]?: Partial<TableConfColumn<any>> } = {
  [TableColumnType.CHECKBOX]: {
    width: '30px',
    enableSort: false,
  },
};

function formatColumnConfs<T extends object>(
  data: TableConfColumn<T>[],
): TableConfColumn<T>[] {
  const result: TableConfColumn<T>[] = [];
  data.forEach((d) => {
    const type = d.type ?? TableColumnType.CLASSIC;
    const conf: TableConfColumn<T> = {
      type,
      enableSort: true,
      defaultSort: TableSortDirection.NONE,
      ...DEFAULT_CONF_PRESET_COLUMN[type],
      ...d,
    };
    result.push(conf);
  });
  return result;
}

export type TableContentRef = React.MutableRefObject<HTMLDivElement | null>;

export type TableTransformValue<T extends object> = (
  columnKey: string,
  item: T,
  initialValue: any,
  data: T[],
  index: number,
) => any | undefined;

export type TableOnChangeValue<T> = (
  value: any,
  columnKey: string,
  item: T,
  data: T[],
  index: number,
) => void;

export type TableOnRenderHeaderCallback = (elements: JSX.Element[]) => React.ReactNode;
export type TableOnRenderRowCallback<T> = (
  elements: JSX.Element[],
  item: T,
  data: T[],
  index: number,
) => React.ReactNode;

export type TableOnRenderCellHeaderCallback<T extends object> = (
  element: JSX.Element | null,
  elementRef: TableContentRef,
  columnKey: string,
  conf: TableConfColumn<T>
) => React.ReactNode;
export type TableOnRenderCellRowCallback<T extends object> = (
  element: JSX.Element | null,
  elementRef: TableContentRef,
  columnKey: string,
  item: T,
  data: T[],
  index: number,
  conf: TableConfColumn<T>
) => React.ReactNode;

export type TableOnStyleHeaderCallback = () => TableStyle | void | null | undefined;
export type TableOnStyleRowCallback<T extends object> = (
  item: T,
  data: T[],
  index: number,
) => TableStyle | void | null | undefined;

export type TableOnStyleCellHeaderCallback<T extends object> = (
  columnKey: number,
  conf: TableConfColumn<T>
) => TableStyle | void | null | undefined;
export type TableOnStyleCellRowCallback<T extends object> = (
  columnKey: string,
  item: T,
  data: T[],
  index: number,
  conf: TableConfColumn<T>
) => TableStyle | void | null | undefined;

export type TableOnRowClick<T extends object> = (
  event: React.MouseEvent,
  item: T,
  data: T[],
  index: number
) => void;

export type TableOnCellRowClick<T extends object> = (
  event: React.MouseEvent,
  columnKey: string,
  item: T,
  data: T[],
  index: number
) => void;

export interface TableProps<T extends object> {
  data: T[],
  configuration: TableConf<T>,
  keyExtractor: (index: number, item: T) => string;
  transformValue?: TableTransformValue<T>;
  onChangeValue?: TableOnChangeValue<T>
  onRenderHeader?: TableOnRenderHeaderCallback;
  onRenderRow?: TableOnRenderRowCallback<T>;
  onRenderCellHeader?: TableOnRenderCellHeaderCallback<T>;
  onRenderCellRow?: TableOnRenderCellRowCallback<T>;
  onStyleHeader?: TableOnStyleHeaderCallback;
  onStyleRow?: TableOnStyleRowCallback<T>;
  onStyleCellHeader?: TableOnStyleCellHeaderCallback<T>;
  onStyleCellRow?: TableOnStyleCellRowCallback<T>;
  onRowClick?: TableOnRowClick<T>;
  onCellRowClick?: TableOnCellRowClick<T>;
  onData?: (data: T[]) => void;
  onRenderStarts?: () => void;
  onRenderEnded?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

interface TableCheckboxRefs {
  header?: CustomCheckBoxRef | null,
  items: (CustomCheckBoxRef | null | undefined)[],
}

interface TableSorting {
  columnIndex: number,
  direction: TableSortDirection,
  done: boolean;
}

interface TableElementReferences {
  headers: TableContentRef[],
  items: TableContentRef[][],
}

function Table<T extends object = {}>({
  data,
  configuration,
  keyExtractor,
  transformValue,
  onChangeValue,
  onRenderHeader,
  onRenderRow,
  onRenderCellHeader,
  onRenderCellRow,
  onStyleHeader,
  onStyleRow,
  onStyleCellHeader,
  onStyleCellRow,
  onRowClick,
  onCellRowClick,
  onData,
  onRenderStarts,
  onRenderEnded,
  className,
  style,
}: TableProps<T>) {
  const [items, setItems] = useState<T[]>([]);
  const checkboxRefs = useRef<TableCheckboxRefs>({ items: [] });
  const headerRef = useRef<HTMLDivElement>(null);
  const sortingRef = useRef<TableSorting | undefined>();
  const formatedColumnConfs = formatColumnConfs(configuration.columns);
  const elementsRef = useRef<TableElementReferences>({ headers: [], items: [] });

  onRenderStarts?.();

  const update = (value: T[]) => {
    onData?.(value);
    setItems([...value]);
  };

  const updateHeaderCheckBox = (conf: TableConfColumn<T>) => {
    const itemsCheckedCount = items.filter((curr) => curr[conf.key as keyof T]).length;
    if (itemsCheckedCount === 0) {
      checkboxRefs.current.header?.uncheck();
    } else if (itemsCheckedCount === items.length) {
      checkboxRefs.current.header?.check(CheckMarkType.Normal);
    } else {
      checkboxRefs.current.header?.check(CheckMarkType.Bar);
    }
  };

  useEffect(() => {
    checkboxRefs.current.items = new Array<CustomCheckBoxRef>(data.length);
    if (sortingRef.current) sortingRef.current.done = false;
    const headersRef: TableContentRef[] = [];
    const itemsRef: TableContentRef[][] = [];
    configuration.columns.forEach(() => headersRef.push({ current: null }));
    data.forEach(() => {
      const itemRef: TableContentRef[] = [];
      configuration.columns.forEach(() => itemRef.push({ current: null }));
      itemsRef.push(itemRef);
    });
    elementsRef.current = {
      headers: headersRef,
      items: itemsRef,
    };
    update(data);
  }, [data]);

  useEffect(() => {
    formatedColumnConfs.forEach((conf) => {
      if (conf.type === TableColumnType.CHECKBOX) {
        updateHeaderCheckBox(conf);
      }
    });
    onRenderEnded?.();
  });

  const sort = (column: number, conf: TableConfColumn<T>, direction?: TableSortDirection) => {
    if (!conf.enableSort) return;
    const headerElement = headerRef.current;
    const iconElements = headerElement
      ? Array.from(headerElement.getElementsByClassName(styles.headerSortIcon)) : [];
    let currentIconElement: Element | undefined;
    iconElements.forEach((el) => {
      el.classList.remove(CLASSES_SORT_DIRECTION[TableSortDirection.ASC]);
      el.classList.remove(CLASSES_SORT_DIRECTION[TableSortDirection.DESC]);

      const currentColumn = Number(el.getAttribute('data-column'));
      if (currentColumn === column) currentIconElement = el;
    });

    let nextDirection: TableSortDirection;
    if (direction) {
      nextDirection = direction;
    } else {
      const currentDirection = sortingRef.current?.columnIndex === column
        ? sortingRef.current.direction : TableSortDirection.NONE;

      if (currentDirection === TableSortDirection.ASC) {
        nextDirection = TableSortDirection.DESC;
      } else {
        nextDirection = TableSortDirection.ASC;
      }
    }

    currentIconElement?.classList.add(CLASSES_SORT_DIRECTION[nextDirection]);

    const defaultSort = (a: T, b: T) => {
      const { key } = conf;
      if (typeof a !== 'object' || typeof b !== 'object') return 0;
      if (!a && !b) return 0;
      if (!a) return -1;
      if (!b) return 1;
      if (typeof key === 'string' && key in a && key in b) {
        const aValue = a[key as keyof T];
        const bValue = b[key as keyof T];
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue;
        }
        if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
          const aBooleanInt = aValue ? 1 : 0;
          const bBooleanInt = bValue ? 1 : 0;
          return bBooleanInt - aBooleanInt;
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue);
        }
        if (aValue instanceof Date && bValue instanceof Date) {
          const aTime = aValue.getTime();
          const bTime = bValue.getTime();
          return (Number.isNaN(aTime) ? 0 : aTime) - (Number.isNaN(bTime) ? 0 : bTime);
        }
      }
      return 0;
    };

    let itemsSorted: T[] | undefined;
    if (nextDirection === TableSortDirection.ASC) {
      itemsSorted = items.sort((a: T, b: T) => {
        if (conf.ascendantSort) return conf.ascendantSort(a, b);
        const { key } = conf;
        const aValue = a?.[key as keyof T];
        const bValue = b?.[key as keyof T];
        if (aValue === undefined && bValue === undefined) return 0;
        if (aValue === undefined) return 1;
        if (bValue === undefined) return -1;
        return defaultSort(a, b);
      });
    } else if (nextDirection === TableSortDirection.DESC) {
      itemsSorted = items.sort((a: T, b: T) => {
        if (conf.descendantSort) return conf.descendantSort(a, b);
        const { key } = conf;
        const aValue = a?.[key as keyof T];
        const bValue = b?.[key as keyof T];
        if (aValue === undefined && bValue === undefined) return 0;
        if (aValue === undefined) return 1;
        if (bValue === undefined) return -1;
        return -defaultSort(a, b);
      });
    }

    sortingRef.current = {
      columnIndex: column,
      direction: nextDirection,
      done: true,
    };
    if (itemsSorted) update(itemsSorted);
  };

  const setWidthOfCell = (cellStyle: React.CSSProperties, conf: TableConfColumn<T>) => {
    if (!cellStyle) return;
    let width: string | undefined;
    let minWidth: string | undefined;
    if (conf.width && conf.minWidth) {
      width = `${conf.width}`;
      minWidth = `${conf.minWidth}`;
    } else if (conf.width) {
      width = `${conf.width}`;
      minWidth = `${width}`;
    } else {
      cellStyle.flexGrow = 1;
      cellStyle.flexShrink = 1;
      cellStyle.flexBasis = 0;
      if (conf.minWidth) {
        cellStyle.flexBasis = `${conf.minWidth}`;
        cellStyle.flexShrink = 0;
        cellStyle.minWidth = `${conf.minWidth}`;
      }
    }

    if (width !== undefined) cellStyle.width = width;
    if (minWidth !== undefined) cellStyle.minWidth = minWidth;
  };

  // ------------------- HEADER ------------------ //
  const renderHeader = () => {
    const renderColumn = (column: number, conf: TableConfColumn<T>) => {
      const sortIsEnabled = !!conf?.enableSort;
      let content: React.ReactNode;

      const classes = [styles.headerCell];
      if (conf.header?.className) classes.push(conf.header.className);
      if (sortIsEnabled) classes.push(styles.sort);
      const containerStyle: React.CSSProperties = {};
      setWidthOfCell(containerStyle, conf);
      if (conf.header?.style) Object.assign(containerStyle, conf.header.style);

      const renderCell = () => {
        const { type } = conf;
        if (type === TableColumnType.CHECKBOX) {
          const itemsCheckedCount = items.filter((curr) => curr[conf.key as keyof T]).length;
          const check = itemsCheckedCount > 0;
          const mark = itemsCheckedCount === items.length
            ? CheckMarkType.Normal : CheckMarkType.Bar;
          return (
            <CustomCheckBox
              isSelected={check}
              markType={mark}
              setSelected={(selected: boolean) => {
                checkboxRefs.current.items.forEach((r, i) => {
                  const item = items[i];
                  if (!r) return;
                  let newValue = selected;
                  if (transformValue) {
                    newValue = transformValue(conf.key, item, selected, items, i) ?? newValue;
                  }
                  if (newValue) r.check();
                  else r.uncheck();

                  if (conf.key in item) item[conf.key as keyof T] = newValue as any;
                  onChangeValue?.(selected, conf.key, item, items, i);

                  updateHeaderCheckBox(conf);
                });
              }}
              ref={(r) => { checkboxRefs.current.header = r; }}
            />
          );
        }
        const cellClasses = [styles.headerCellContent];
        if (configuration.header?.cell?.className) {
          classes.push(configuration.header?.cell?.className);
        }
        return (
          <div ref={elementsRef.current.headers[column]} className={cellClasses.join(' ')}>
            {conf.title}
          </div>
        );
      };

      const renderSortIcon = () => {
        if (!sortIsEnabled) return null;
        const iconClasses = [styles.headerSortIcon];
        let sortDirection: TableSortDirection = TableSortDirection.NONE;
        if (!sortingRef.current) {
          if (conf.defaultSort === TableSortDirection.ASC
            || conf.defaultSort === TableSortDirection.DESC) {
            sortDirection = conf.defaultSort;
            sortingRef.current = {
              columnIndex: column,
              direction: sortDirection,
              done: false,
            };
            sort(column, conf, sortDirection);
          }
        } else if (sortingRef.current.columnIndex === column) {
          sortDirection = sortingRef.current.direction;
          if (!sortingRef.current.done) sort(column, conf, sortDirection);
        }
        iconClasses.push(CLASSES_SORT_DIRECTION[sortDirection]);
        return (
          <img
            className={iconClasses.join(' ')}
            src={IconChevron}
            alt=""
            data-column={column}
          />
        );
      };

      if (onStyleCellHeader) {
        const additionnalStyle = onStyleCellHeader(column, conf);
        if (additionnalStyle?.className) {
          classes.push(...additionnalStyle.className.split(' '));
        }
        if (additionnalStyle?.style) {
          Object.assign(containerStyle, additionnalStyle?.style);
        }
      }

      const defaultContent: JSX.Element | null = renderCell();
      const ref = elementsRef.current?.headers[column];
      if (onRenderCellHeader) content = onRenderCellHeader(defaultContent, ref, conf.key, conf);
      else content = defaultContent;

      return (
        <div
          role="gridcell"
          tabIndex={column}
          onClick={() => sort?.(column, conf)}
          onKeyDown={() => { }}
          className={classes.join(' ')}
          style={containerStyle}
          key={`header-column-${column}`}
        >
          {content}
          {renderSortIcon()}
        </div>
      );
    };

    const defaultContent: JSX.Element[] = formatedColumnConfs.map((c, i) => renderColumn(i, c));
    let content: React.ReactNode;
    if (onRenderHeader) content = onRenderHeader(defaultContent);
    else content = defaultContent;

    const classes = [styles.header];
    if (configuration.header?.className) classes.push(configuration.header.className);
    const headerStyle: React.CSSProperties = {};
    if (configuration.header?.style) Object.assign(headerStyle, configuration.header.style);
    if (onStyleHeader) {
      const tableHeaderStyle = onStyleHeader();
      if (tableHeaderStyle?.className) classes.push(...tableHeaderStyle.className.split(' '));
      if (tableHeaderStyle?.style) Object.assign(headerStyle, tableHeaderStyle.style);
    }
    return (
      <div
        className={classes.join(' ')}
        style={headerStyle}
        ref={headerRef}
      >
        {content}
      </div>
    );
  };

  // ------------------- CONTENT ------------------ //
  const renderRow = (index: number, item: T) => {
    const key = keyExtractor(index, item);
    const renderCell = (column: number, conf: TableConfColumn<T>) => {
      let content: React.ReactNode;

      const classes = [styles.rowCell];
      if (conf.item?.className) classes.push(conf.item?.className);
      const containerStyle: React.CSSProperties = {};
      setWidthOfCell(containerStyle, conf);
      if (conf.item?.style) Object.assign(containerStyle, conf.item?.style);

      const renderContent = () => {
        const { type } = conf;
        if (type === TableColumnType.CHECKBOX) {
          let check: boolean = !!item[conf.key as keyof T];
          if (transformValue) {
            check = transformValue(conf.key, item, check, items, index) ?? check;
            item[conf.key as keyof T] = check as any;
          }

          return (
            <CustomCheckBox
              isSelected={check}
              setSelected={(selected: boolean) => {
                const newValue = transformValue?.(conf.key, item, selected, items, index)
                  ?? selected;
                if (newValue !== selected) {
                  if (newValue) checkboxRefs.current.items[index]?.check();
                  else checkboxRefs.current.items[index]?.uncheck();
                }
                item[conf.key as keyof T] = newValue;
                onChangeValue?.(newValue, conf.key, item, items, index);
                updateHeaderCheckBox(conf);
              }}
              ref={(r) => { checkboxRefs.current.items[index] = r; }}
            />
          );
        }
        let value: any = item[conf.key as keyof T];
        const valueChanged = transformValue?.(conf.key, item, value, items, index);
        if (valueChanged !== undefined) value = valueChanged;

        if (value === undefined) {
          const confUndefinedValue: TableConfUndefinedValue = {
            ...DEFAULT_TABLE_CONF_UNDEFINED_VALUE,
            ...(configuration.valueToShowIfUndefined || {}),
          };
          value = confUndefinedValue.value;
          if (confUndefinedValue.className) classes.push(confUndefinedValue.className);
          if (confUndefinedValue.style) Object.assign(containerStyle, confUndefinedValue.style);
        }
        const cellClasses = [styles.rowCellContent];
        if (configuration.row?.cell?.className) classes.push(configuration.row?.cell?.className);
        return (
          <div
            ref={elementsRef.current.items[index][column]}
            className={cellClasses.join(' ')}
            style={configuration.row?.cell?.style}
          >
            {`${value}`}
          </div>
        );
      };

      if (onStyleCellRow) {
        const additionnalStyle = onStyleCellRow(conf.key, item, items, index, conf);
        if (additionnalStyle?.className) {
          classes.push(...additionnalStyle.className.split(' '));
        }
        if (additionnalStyle?.style) {
          Object.assign(containerStyle, additionnalStyle?.style);
        }
      }

      const defaultContent: JSX.Element | null = renderContent();
      if (onRenderCellRow) {
        const ref = elementsRef.current.items[index][column];
        content = onRenderCellRow(defaultContent, ref, conf.key, item, items, index, conf);
      } else content = defaultContent;

      return (
        <div
          role="gridcell"
          tabIndex={index}
          onClick={(ev: React.MouseEvent) => onCellRowClick?.(ev, conf.key, item, items, index)}
          onKeyDown={() => { }}
          className={classes.join(' ')}
          style={containerStyle}
          key={`${key}-column-${column}`}
        >
          {content}
        </div>
      );
    };

    const defaultContent: JSX.Element[] = formatedColumnConfs.map((c, i) => renderCell(i, c));
    let content: React.ReactNode;
    if (onRenderRow) content = onRenderRow(defaultContent, item, items, index);
    else content = defaultContent;

    const classes = [styles.row];
    if (configuration.row?.className) classes.push(configuration.row.className);
    const itemStyle: React.CSSProperties = {};
    if (configuration.row?.style) Object.assign(itemStyle, configuration.row.style);
    if (onStyleRow) {
      const tableItemStyle = onStyleRow(item, items, index);
      if (tableItemStyle?.className) classes.push(...tableItemStyle.className.split(' '));
      if (tableItemStyle?.style) Object.assign(itemStyle, tableItemStyle.style);
    }
    return (
      <div
        role="tablist"
        tabIndex={index}
        onClick={(ev: React.MouseEvent) => onRowClick?.(ev, item, items, index)}
        onKeyDown={() => { }}
        className={classes.join(' ')}
        style={itemStyle}
        key={key}
      >
        {content}
      </div>
    );
  };

  const renderContent = () => {
    if (!items.length) return null;

    const content = items.map((item: T, index: number) => renderRow(index, item));
    const classes = [styles.content];
    if (configuration.content?.className) classes.push(configuration.content?.className);
    return (
      <div
        className={classes.join(' ')}
        style={configuration.content?.style}
      >
        {content}
      </div>
    );
  };

  // ------------------- RENDER ------------------ //
  const content = renderContent(); // Render content before header to init it (checkox)
  const header = renderHeader();

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      <div className={styles.table}>
        {header}
        {content}
      </div>
    </div>
  );
}

Table.defaultProps = {
  transformValue: undefined,
  onChangeValue: undefined,
  onRenderHeader: undefined,
  onRenderRow: undefined,
  onRenderCellHeader: undefined,
  onRenderCellRow: undefined,
  onStyleHeader: undefined,
  onStyleRow: undefined,
  onStyleCellHeader: undefined,
  onStyleCellRow: undefined,
  onRowClick: undefined,
  onCellRowClick: undefined,
  onData: undefined,
  onRenderStarts: undefined,
  onRenderEnded: undefined,
  className: undefined,
  style: undefined,
};

export default Table;
