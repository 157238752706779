import React, { useRef, useState } from 'react';
import { useElementSize } from '../../hooks/window';
import Pagination from './Pagination';

const containerStyle : React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};
const paginationStyle : React.CSSProperties = {
  width: '100%',
  marginBottom: '10px',
};

const getSupportItemStyle = (
  rows : number,
  colums : number,
  gap : number,
) : React.CSSProperties => {
  const height = `calc(${(100 / rows).toFixed(5)}% - ${gap}px)`;
  const width = `calc(${(100 / colums).toFixed(5)}% - ${gap}px)`;
  return ({
    position: 'relative',
    height,
    width,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
};

const getGridStyle = (gap : number) : React.CSSProperties => ({
  flex: 1,
  height: '0px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: `${gap}px`,
});

const DEFAULT_MIN_ROW = 1;
const DEFAULT_MAX_ROW = 3;
const DEFAULT_MIN_COLUMN = 1;
const DEFAULT_MAX_COLUMN = 3;
const DEFAULT_MIN_RESOLUTION = 200 * 200;
const DEFAULT_TARGET_RATIO = 16 / 9;
const DEFAULT_MIN_RATIO = 10 / 9;
const DEFAULT_MAX_RATIO = 22 / 9;

const DEFAULT_ROW = 3;
const DEFAULT_COLUMN = 3;
const DEFAULT_AUTOADJUST = true;
const DEFAULT_FREEZE_AUTOADJUST = false;

export interface GridListDetails {
  rows: number;
  columns: number;
  itemWidth: number;
  itemHeight: number;
  itemsForCurrentPage: number;
  itemsByPage: number;
  currentPage: number;
  pages: number;
}
export type GridListRenderItemFunc<T = any> = (
  index : number, item : T | undefined, details : GridListDetails) => React.ReactNode;
export type GridListKeyExtractor<T = any> = (index : number, item : T) => string;
export interface GridListProps<T = any> {
  data: Array<T>;
  autoAdjust: boolean,
  freezeAutoAdjust: boolean,
  row: number,
  column: number,
  minRow: number;
  maxRow: number;
  minColumn: number;
  maxColumn: number;
  minContentResolution?: number;
  minContentRatio?: number;
  maxContentRatio?: number;
  targetContentRatio?: number;
  offsetRatioWidthItem?: number;
  offsetRatioHeightItem?: number;
  renderItem : GridListRenderItemFunc<T>;
  keyExtractor: GridListKeyExtractor<T>;
  paginationTextColor?: string;
  paginationTextColorSelected?: string;
  className?: string;
  style?: React.CSSProperties;
  gap?: number;
}

function GridList<T=any>({
  data,
  autoAdjust,
  freezeAutoAdjust,
  row,
  column,
  minRow,
  maxRow,
  minColumn,
  maxColumn,
  minContentResolution,
  minContentRatio,
  maxContentRatio,
  targetContentRatio,
  offsetRatioWidthItem,
  offsetRatioHeightItem,
  renderItem,
  keyExtractor,
  paginationTextColor,
  paginationTextColorSelected,
  className,
  style,
  gap,
}: GridListProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const [pageSelected, setPageSelected] = useState<number>(0);
  const elementSize = useElementSize(ref, !freezeAutoAdjust);
  const MIN_RESOLUTION = minContentResolution === undefined
    ? DEFAULT_MIN_RESOLUTION : minContentResolution;
  const MIN_RATIO = minContentRatio === undefined ? DEFAULT_MIN_RATIO : minContentRatio;
  const MAX_RATIO = maxContentRatio === undefined ? DEFAULT_MAX_RATIO : maxContentRatio;
  const TARGET_RATIO = targetContentRatio === undefined ? DEFAULT_TARGET_RATIO : targetContentRatio;
  const OFFSET_RATIO_WIDTH = offsetRatioWidthItem || 0;
  const OFFSET_RATIO_HEIGHT = offsetRatioHeightItem || 0;

  const getGrid = () : [number, number, number, number] => {
    const gridWidth = elementSize.width || 0;
    const gridHeight = elementSize.height || 0;

    const getCaseHeight = (r : number) : number => gridHeight / r;
    const getCaseWidth = (c : number) : number => gridWidth / c;
    const getCaseResolution = (r : number, c : number) : number => (
      getCaseHeight(r) * getCaseWidth(c)
    );
    const getCaseRatio = (r : number, c : number) : number => (
      (getCaseWidth(c) + OFFSET_RATIO_WIDTH) / (getCaseHeight(r) + OFFSET_RATIO_HEIGHT)
    );
    const isBestRatio = (ratioRef : number, ratio : number) => (
      Math.abs(ratio - TARGET_RATIO) < Math.abs(ratioRef - TARGET_RATIO)
    );
    if (!autoAdjust) return [row, column, getCaseWidth(column), getCaseHeight(row)];
    if (!gridWidth || !gridHeight) {
      return [minRow, minColumn, getCaseWidth(minColumn), getCaseHeight(minRow)];
    }

    let newRow : number = minRow;
    let newColumn : number = minColumn;
    let bestRatio : number | undefined;
    let bestWidth : number = 0;
    let bestHeight : number = 0;

    if (data.length > 1) {
      for (let r = maxRow; r >= minRow; r -= 1) {
        for (let c = maxColumn; c >= minColumn; c -= 1) {
          const ratio = getCaseRatio(r, c);
          if ((getCaseResolution(r, c) > MIN_RESOLUTION)
          && ratio >= MIN_RATIO && ratio <= MAX_RATIO) {
            if ((!bestRatio || isBestRatio(bestRatio, ratio))
            && r * c >= newRow * newColumn) {
              bestRatio = ratio;
              bestWidth = getCaseWidth(c);
              bestHeight = getCaseHeight(r);
              newRow = r;
              newColumn = c;
            }
          }
        }
      }
    } else {
      newRow = 1;
      newColumn = 1;
      bestRatio = getCaseRatio(newRow, newColumn);
      bestWidth = getCaseWidth(newColumn);
      bestHeight = getCaseHeight(newRow);
    }

    // Get as few empty case as possible
    if (data.length < newRow * newColumn) {
      const currentRow = newRow;
      const currentColumn = newColumn;
      let bestEmptyCount : number = currentRow * currentColumn;
      bestRatio = undefined;
      for (let r = currentRow; r >= minRow; r -= 1) {
        for (let c = currentColumn; c >= minColumn; c -= 1) {
          const ratio = getCaseRatio(r, c);
          const emptyCount = r * c - data.length;
          let newBest = false;

          if (emptyCount >= 0 && emptyCount < bestEmptyCount) {
            newBest = true;
          } else if (emptyCount === bestEmptyCount) {
            if (!bestRatio || isBestRatio(bestRatio, ratio)) {
              newBest = true;
            }
          }

          if (newBest) {
            bestEmptyCount = emptyCount;
            bestRatio = ratio;
            bestWidth = getCaseWidth(c);
            bestHeight = getCaseHeight(r);
            newRow = r;
            newColumn = c;
          }
        }
      }
    }

    return [newRow, newColumn, bestWidth, bestHeight];
  };

  const [nbOfRows, nbOfColumns, itemWidth, itemHeight] = getGrid();
  const elementsByPage = nbOfRows * nbOfColumns;
  const pageCount = Math.ceil(data.length / elementsByPage);

  if (pageSelected >= pageCount) {
    const newPageSelected = Math.max(0, pageCount - 1);
    if (newPageSelected !== pageSelected) setPageSelected(newPageSelected);
  }

  const renderPagination = () => (
    <Pagination
      textColor={paginationTextColor}
      textColorSelected={paginationTextColorSelected}
      selected={pageSelected}
      count={pageCount}
      onSelected={(selected : number) => setPageSelected(selected)}
      style={paginationStyle}
    />
  );

  const render = () => {
    const elements : React.ReactNode[] = [];
    const extractor = (index : number) => {
      if (keyExtractor) {
        return keyExtractor(index, data[index]);
      }
      return `grid_${index}`;
    };
    const offsetIndex = (elementsByPage * pageSelected);
    const itemForCurrentPage = Math.max(0, data.length - offsetIndex);
    for (let i = 0; i < elementsByPage; i += 1) {
      const index = offsetIndex + i;
      const item = index < data.length ? data[index] : undefined;
      const details : GridListDetails = {
        rows: nbOfRows,
        columns: nbOfColumns,
        itemWidth,
        itemHeight,
        itemsForCurrentPage: itemForCurrentPage,
        itemsByPage: elementsByPage,
        currentPage: pageSelected,
        pages: pageCount,
      };
      const itemRendered = renderItem(index, item, details);
      if (itemRendered) {
        elements.push(
          <div
            key={extractor(index)}
            style={getSupportItemStyle(nbOfRows, nbOfColumns, gap || 0)}
          >
            { itemRendered }
          </div>,
        );
      }
    }
    return (
      <div
        ref={ref}
        className={className}
        style={{ ...containerStyle, ...(style || {}) }}
      >
        <div style={getGridStyle(gap || 0)}>
          {elements}
        </div>
        {pageCount > 1 ? renderPagination() : null}
      </div>
    );
  };

  return render();
}

GridList.defaultProps = {
  autoAdjust: DEFAULT_AUTOADJUST,
  freezeAutoAdjust: DEFAULT_FREEZE_AUTOADJUST,
  row: DEFAULT_ROW,
  column: DEFAULT_COLUMN,
  minRow: DEFAULT_MIN_ROW,
  maxRow: DEFAULT_MAX_ROW,
  minColumn: DEFAULT_MIN_COLUMN,
  maxColumn: DEFAULT_MAX_COLUMN,
  minContentResolution: DEFAULT_MIN_RESOLUTION,
  minContentRatio: DEFAULT_MIN_RATIO,
  maxContentRatio: DEFAULT_MAX_RATIO,
  targetContentRatio: DEFAULT_TARGET_RATIO,
  offsetRatioWidthItem: 0,
  offsetRatioHeightItem: 0,
  keyExtractor: undefined,
  paginationTextColor: undefined,
  paginationTextColorSelected: undefined,
  className: undefined,
  style: undefined,
  gridStyle: undefined,
  onRender: undefined,
  gap: undefined,
};

export default GridList;
