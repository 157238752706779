import { RootState } from '../../app/store';

export const selectUser = (state: RootState) => state.session.user;

export const selectUserOrganization = (state: RootState) => state.session.user?._organization;

export const selectOrganization = (state: RootState) => state.session.organization;

export const selectToken = (state: RootState) => state.session.token;

export const selectRefreshToken = (state: RootState) => state.session.refreshToken;

export const selectIsRefreshingToken = (state: RootState) => state.session.isRefreshingToken;

export const selectAuthStatus = (state: RootState) => state.session.authStatus;

export const selectPasswordResetState = (state: RootState) => state.session.passwordResetState;

export const selectIsFetching = (state: RootState) => state.session.isFetching;

export default {
  selectUser,
  selectUserOrganization,
  selectOrganization,
  selectToken,
  selectRefreshToken,
  selectIsRefreshingToken,
  selectAuthStatus,
  selectPasswordResetState,
  selectIsFetching,
};
