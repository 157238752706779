import { DeviceUpdateSessionStatus, VersionState } from '@kalyzee/kast-websocket-module';
import React, { useImperativeHandle, useRef } from 'react';
import { compareVersions } from 'compare-versions';
import Colors from '../../../constants/colors';
import { useElementSize } from '../../../hooks/window';
import MessageOnHover, { MessageOnHoverMode } from '../../utils/MessageOnHover';
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from '../../utils/Table';
import { resetAllSvgAnimations } from '../../../helpers/element';
import { TableUpdateDeviceAction, TableUpdateDevicesData } from './TableUpdateDevices.constant';
import TableUpdateDevicesStateCell from './TableUpdateDevicesStateCell';
import { useRender } from '../../../hooks/component';
import TableUpdateDevicesActionCell from './TableUpdateDevicesActionCell';
import { useAppTranslation } from '../../../hooks/translation';
import { Strings } from '../../../constants/translation';

import { ReactComponent as IconWarning } from '../../../../assets/icons/warning.svg';
import { ReactComponent as IconLoading } from '../../../../assets/icons/loading.svg';
import { ReactComponent as IconError } from '../../../../assets/icons/error.svg';
import { ReactComponent as IconScheduled } from '../../../../assets/icons/schedule.svg';

import styles from './TableUpdateDevices.module.css';
import commonStyles from '../TableCommon.module.css';

const DATE_FORMAT = { year: 'numeric', month: 'long', day: '2-digit' };

export interface TableUpdateDevicesRef {
  render: () => void;
}

export interface TableUpdateDevicesProps {
  data: TableUpdateDevicesData[];
  onItemChecked?: (item: TableUpdateDevicesData) => void;
  onAction?: (action: TableUpdateDeviceAction, item: TableUpdateDevicesData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableUpdateDevices = React.forwardRef((
  {
    data,
    onItemChecked,
    onAction,
    className,
    style,
  }: TableUpdateDevicesProps,
  forwardRef: React.ForwardedRef<TableUpdateDevicesRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const render = useRender();
  const size = useElementSize(containerRef);

  let iconLoadingRef: TableContentRef[] = [];

  useImperativeHandle(forwardRef, () => ({
    render,
  }));

  const generateConfiguration = () => {
    const currentWidth = (size?.width ?? 0);
    let columnConfiguration: TableConfColumn<TableUpdateDevicesData>[] = [
      {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'name',
        width: '15%',
        minWidth: '7.5rem',
        title: t(Strings.UPDATE_TABLE_COLUMN_TITLE_DEVICES),
        header: { className: commonStyles.tableHeaderCellDevice },
        item: { className: commonStyles.tableCellDevice },
      }, {
        type: TableColumnType.CLASSIC,
        key: 'version',
        width: '10%',
        minWidth: '5rem',
        title: t(Strings.UPDATE_TABLE_COLUMN_TITLE_VERSION),
        ascendantSort: (a: TableUpdateDevicesData, b: TableUpdateDevicesData) => {
          if (a.state === VersionState.UP_TO_DATE && b.state !== VersionState.UP_TO_DATE) return 1;
          if (b.state === VersionState.UP_TO_DATE && a.state !== VersionState.UP_TO_DATE) return -1;
          const aVersionCode = a.versionCode ?? '0';
          const bVersionCode = b.versionCode ?? '0';
          return compareVersions(aVersionCode, bVersionCode);
        },
        descendantSort: (a: TableUpdateDevicesData, b: TableUpdateDevicesData) => {
          if (a.state === VersionState.UP_TO_DATE && b.state !== VersionState.UP_TO_DATE) return -1;
          if (b.state === VersionState.UP_TO_DATE && a.state !== VersionState.UP_TO_DATE) return 1;
          const aVersionCode = a.versionCode ?? '0';
          const bVersionCode = b.versionCode ?? '0';
          return -compareVersions(aVersionCode, bVersionCode);
        },
        defaultSort: TableSortDirection.DESC,
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'date',
        width: '20%',
        title: t(Strings.UPDATE_TABLE_COLUMN_TITLE_DATE),
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'other',
        title: t(Strings.UPDATE_TABLE_COLUMN_TITLE_STATE),
        width: currentWidth > 600 ? '19rem' : '14rem',
        enableSort: false,
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'action',
        width: '10rem',
        enableSort: false,
      },
    ];

    if (currentWidth < 800) {
      columnConfiguration = columnConfiguration.filter((c) => c.key !== 'date');
    }

    const tableConfiguration: TableConf<TableUpdateDevicesData> = {
      columns: columnConfiguration,
      header: {
        className: commonStyles.tableHeader,
        cell: {
          className: commonStyles.tableHeaderCell,
        },
      },
      row: {
        className: commonStyles.tableRow,
        cell: {
          className: commonStyles.tableRowCell,
        },
      },
      content: { className: commonStyles.tableContent },
      valueToShowIfUndefined: { value: '-', className: commonStyles.tableUndefinedValue },
    };

    return tableConfiguration;
  };

  // Transform value before to display it
  const transformValue = (columnKey: string, item: TableUpdateDevicesData, value: any) => {
    if (columnKey === 'checked') {
      return (
        item.online
        && item.state !== VersionState.UP_TO_DATE
        && !item.running
      ) ? value : false;
    }
    if (columnKey === 'date') {
      return item.date?.toLocaleDateString(undefined, DATE_FORMAT as any);
    }
    return undefined;
  };

  // Called when a value is changed. Checkboxes here
  const valueChanged = (value: any, columnKey: string, item: TableUpdateDevicesData) => {
    if (columnKey === 'checked') onItemChecked?.(item);
  };

  const customRenderCell = (
    element: JSX.Element | null,
    elementRef: TableContentRef,
    columnKey: string,
    item: TableUpdateDevicesData,
  ) => {
    if (columnKey === 'version') {
      let content = element;
      let mode: MessageOnHoverMode;
      let message: string;
      if (item.state === VersionState.UNKNOWN_VERSION
        || item.state === VersionState.NEWER) {
        mode = MessageOnHoverMode.WARNING;
        message = t(Strings.UPDATE_TABLE_VERSION_HOVER_UNKNOWN);
      } else if (item.state === VersionState.TO_UPDATE) {
        mode = MessageOnHoverMode.INFO;
        message = t(Strings.UPDATE_TABLE_VERSION_HOVER_TO_UPDATE, { version: item.newVersion });
      } else if (item.state === VersionState.UP_TO_DATE) {
        mode = MessageOnHoverMode.SUCCESS;
        message = t(Strings.UPDATE_TABLE_VERSION_HOVER_UP_TO_DATE);
      } else {
        mode = MessageOnHoverMode.ERROR;
        message = t(Strings.UPDATE_TABLE_VERSION_HOVER_ERROR);
      }

      if (!item.version) {
        let icon: React.ReactNode = null;
        if (mode === MessageOnHoverMode.WARNING) {
          icon = (
            <IconWarning
              width={25}
              fill={Colors.getBroom()}
            />
          );
        } else if (mode === MessageOnHoverMode.ERROR) {
          icon = (
            <IconError
              width={25}
              fill={Colors.getTorchRed()}
            />
          );
        }
        content = (
          <div
            ref={(el) => {
              elementRef.current = el;
            }}
          >
            {
              item.online ? icon : element
            }
          </div>
        );
      }
      return (
        <div style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'default' }}>
          {item.online ? (
            <MessageOnHover
              targetRef={elementRef}
              message={message}
              mode={mode}
              hoverOnParentLevel={2}
            />
          ) : null}
          {content}
        </div>
      );
    }
    if (columnKey === 'other') {
      return (
        <TableUpdateDevicesStateCell
          data={item}
        />
      );
    }
    if (columnKey === 'action') {
      if (item.info === undefined) return null;
      return (
        <TableUpdateDevicesActionCell
          data={item}
          onStartUpdate={() => {
            onAction?.(TableUpdateDeviceAction.START_UPDATE, item);
          }}
          onInstall={() => {
            onAction?.(TableUpdateDeviceAction.INSTALL, item);
          }}
          onAbort={() => {
            onAction?.(TableUpdateDeviceAction.ABORT, item);
          }}
          onCleanError={() => {
            onAction?.(TableUpdateDeviceAction.CLEAN_ERROR, item);
          }}
        />
      );
    }
    if (columnKey === 'checked') {
      if (item.error || item.info === undefined) {
        return (
          <div ref={elementRef} style={{ color: Colors.getGrayDusty() }}>
            -
          </div>
        );
      }
      if (item.scheduled) {
        return (
          <div ref={elementRef} style={{ marginTop: '5px' }}>
            <IconScheduled
              width="20px"
              fill={Colors.getMainWhite(0.9)}
            />
          </div>
        );
      }
      if (item.running && item.online) {
        iconLoadingRef.push(elementRef);
        return (
          <div ref={elementRef}>
            <IconLoading
              width="20px"
            />
          </div>
        );
      }
      if (item.state === VersionState.UP_TO_DATE) {
        return (
          <div ref={elementRef} style={{ color: Colors.getMountainMeadow(), fontSize: '1.7rem' }}>
            ✓
          </div>
        );
      }
    }
    return element;
  };

  const addCustomStyleOnCell = (
    columnKey: string,
    item: TableUpdateDevicesData,
  ) => {
    const result: TableStyle = {};
    const cellStyle: React.CSSProperties = {};
    result.style = cellStyle;
    if (columnKey === 'checked') {
      if (!item.online) {
        cellStyle.cursor = 'default';
        cellStyle.pointerEvents = 'none';
      } else if (item.state === VersionState.UP_TO_DATE) {
        cellStyle.cursor = 'default';
        cellStyle.pointerEvents = 'none';
        cellStyle.opacity = 0.5;
      }
    } else if (columnKey === 'version') {
      if (!item.info?.osVersion) return result;
      let color: string = Colors.getTreePoppy();
      if (item.state === VersionState.TO_UPDATE) color = Colors.getTorchRed();
      else if (item.state === VersionState.UP_TO_DATE) color = Colors.getMountainMeadow();
      cellStyle.color = color;
    }
    return result;
  };

  const addCustomStyleOnRow = (
    item: TableUpdateDevicesData,
    currData: TableUpdateDevicesData[],
    index: number,
  ) => {
    const result: TableStyle = {};
    const rowStyle: React.CSSProperties = {};
    result.style = rowStyle;
    if (!item.online) {
      rowStyle.opacity = 0.5;
      rowStyle.backgroundColor = Colors.getClayEbonyMedium(0.4);
      if (index === 0 || currData[index - 1].online) {
        rowStyle.borderTopLeftRadius = '10px';
        rowStyle.borderTopRightRadius = '10px';
      }
      if (index + 1 >= currData.length || currData[index + 1].online) {
        rowStyle.borderBottomLeftRadius = '10px';
        rowStyle.borderBottomRightRadius = '10px';
      }
    }
    return result;
  };

  const sortByDeviceOnline = (a: TableUpdateDevicesData, b: TableUpdateDevicesData) => {
    const aOnline = a.online ? 1 : 0;
    const bOnline = b.online ? 1 : 0;
    if (!aOnline && !bOnline) {
      if (a.session?.status === DeviceUpdateSessionStatus.INSTALLING) return -1;
    }
    return bOnline - aOnline;
  };

  const onRenderTableStarts = () => {
    iconLoadingRef = [];
  };

  const onRenderTableEnded = () => {
    iconLoadingRef.forEach((ref) => {
      const el = ref?.current;
      if (el) resetAllSvgAnimations(el);
    });
  };

  const renderTable = () => (
    <Table
      className={commonStyles.table}
      data={data}
      keyExtractor={(_, item) => `key-${item.deviceId}`}
      configuration={generateConfiguration()}
      transformValue={transformValue}
      onData={(d) => d.sort(sortByDeviceOnline)}
      onRenderCellRow={customRenderCell}
      onStyleCellRow={addCustomStyleOnCell}
      onStyleRow={addCustomStyleOnRow}
      onChangeValue={valueChanged}
      onRenderStarts={() => onRenderTableStarts}
      onRenderEnded={onRenderTableEnded}
    />
  );

  const classes = [commonStyles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderTable()}
    </div>
  );
});

TableUpdateDevices.defaultProps = {
  onItemChecked: undefined,
  onAction: undefined,
  className: undefined,
  style: undefined,
};

export default TableUpdateDevices;
