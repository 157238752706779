import React, { createRef, useImperativeHandle, useRef } from 'react';
import Colors from '../../../constants/colors';
import { useElementSize } from '../../../hooks/window';
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from '../../utils/Table';
import { useRender } from '../../../hooks/component';
import { useAppTranslation } from '../../../hooks/translation';
import { TableDevicesData } from './TableDevices.constant';
import { Strings } from '../../../constants/translation';
import { ReactComponent as IconLoading } from '../../../../assets/icons/loading.svg';

import styles from './TableDevices.module.css';
import commonStyles from '../TableCommon.module.css';
import Touchable from '../../utils/Touchable';
import MessageOnHover from '../../utils/MessageOnHover';

export interface TableDevicesRef {
  render: () => void;
}

export interface TableDevicesProps {
  data: TableDevicesData[];
  onUpdateDeviceName?: (item: TableDevicesData, name: string) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableDevices = React.forwardRef((
  {
    data,
    onUpdateDeviceName,
    className,
    style,
  }: TableDevicesProps,
  forwardRef: React.ForwardedRef<TableDevicesRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const render = useRender();
  const size = useElementSize(containerRef);

  useImperativeHandle(forwardRef, () => ({
    render,
  }));

  const generateConfiguration = () => {
    const columnConfiguration: TableConfColumn<TableDevicesData>[] = [
      /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
      {
        type: TableColumnType.CLASSIC,
        key: 'online',
        title: '',
        width: '30px',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'name',
        width: '20%',
        minWidth: '18rem',
        title: t(Strings.DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_NAME),
        header: { className: commonStyles.tableHeaderCellDevice },
        item: { className: commonStyles.tableCellDevice },
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'roomName',
        title: t(Strings.DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_ROOM),
        minWidth: '15rem',
        defaultSort: TableSortDirection.ASC,
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'hardwareId',
        title: t(Strings.DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_HARDWARE_ID),
        width: '20%',
        minWidth: '8rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'deviceId',
        title: t(Strings.DEVICE_DESCRIPTION_TABLE_COLUMN_TITLE_SOFTWARE_ID),
        width: '20%',
        minWidth: '18rem',
      },
    ];

    const tableConfiguration: TableConf<TableDevicesData> = {
      columns: columnConfiguration,
      header: {
        className: commonStyles.tableHeader,
        cell: {
          className: commonStyles.tableHeaderCell,
        },
      },
      row: {
        className: commonStyles.tableRow,
        cell: {
          className: commonStyles.tableRowCell,
        },
      },
      content: { className: commonStyles.tableContent },
      valueToShowIfUndefined: { value: '-', className: commonStyles.tableUndefinedValue },
    };

    return tableConfiguration;
  };

  const customRenderCell = (
    element: JSX.Element | null,
    elementRef: TableContentRef,
    columnKey: string,
    item: TableDevicesData,
  ) => {
    if (columnKey === 'online') {
      const onlineContainerRef = createRef<HTMLDivElement>();
      return (
        <div className={styles.onlineContainer} ref={onlineContainerRef}>
          <div
            className={styles.onlineIndicator}
            style={{ color: item.online ? Colors.getMainGreen() : 'rgb(151, 151, 151)' }}
          >
            ●
          </div>
          <MessageOnHover
            targetRef={onlineContainerRef}
            message={item.online
              ? t(Strings.DEVICE_DESCRIPTION_TABLE_STATE_ONLINE)
              : t(Strings.DEVICE_DESCRIPTION_TABLE_STATE_OFFLINE)}
            delay={100}
          />
        </div>
      );
    }
    if (columnKey === 'name') {
      if (item.updatingName) {
        return (
          <div>
            <IconLoading
              width="20px"
            />
          </div>
        );
      }
      const inputRef = createRef<HTMLInputElement>();
      const buttonsRef = createRef<HTMLInputElement>();
      return (
        <div
          className={styles.nameContainer}
        >
          <input
            className={styles.nameInput}
            ref={inputRef}
            defaultValue={item.name}
            placeholder={t(Strings.DEVICE_DESCRIPTION_TABLE_INPUT_PLACEHOLDER_NAME)}
            onChange={(event) => {
              if (inputRef.current && buttonsRef.current) {
                buttonsRef.current.style.display = inputRef.current.value === item.name ? 'none' : 'flex';
              }
            }}
          />
          <div
            ref={buttonsRef}
            className={styles.nameButtonsContainer}
            style={{ display: 'none' }}
          >
            <Touchable
              className={styles.nameValidateButton}
              onPress={() => {
                if (inputRef.current) {
                  inputRef.current.blur();
                  onUpdateDeviceName?.(item, inputRef.current.value);
                }
              }}
            >
              ✔
            </Touchable>
            <Touchable
              className={styles.nameUnvalidateButton}
              onPress={() => {
                if (inputRef.current && buttonsRef.current) {
                  inputRef.current.value = item.name ?? '';
                  inputRef.current.blur();
                  buttonsRef.current.style.display = 'none';
                }
              }}
            >
              ✘
            </Touchable>
          </div>
        </div>
      );
    }
    return element;
  };

  const addCustomStyleOnCell = (
    columnKey: string,
    item: TableDevicesData,
  ) => {
    const result: TableStyle = {};
    return result;
  };

  const addCustomStyleOnRow = (
    item: TableDevicesData,
    currData: TableDevicesData[],
    index: number,
  ) => {
    const result: TableStyle = {};
    const rowStyle: React.CSSProperties = {};
    result.style = rowStyle;
    return result;
  };

  const onRenderTableStarts = () => {
  };

  const onRenderTableEnded = () => {
  };

  const renderTable = () => (
    <Table
      className={commonStyles.table}
      data={data}
      keyExtractor={(_, item) => `key-${item.deviceId}`}
      configuration={generateConfiguration()}
      onRenderCellRow={customRenderCell}
      onStyleCellRow={addCustomStyleOnCell}
      onStyleRow={addCustomStyleOnRow}
      onRenderStarts={() => onRenderTableStarts}
      onRenderEnded={onRenderTableEnded}
    />
  );

  const classes = [commonStyles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderTable()}
    </div>
  );
});

TableDevices.defaultProps = {
  onUpdateDeviceName: undefined,
  className: undefined,
  style: undefined,
};

export default TableDevices;
