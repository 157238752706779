import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Strings } from '../../common/constants/translation';
import { useAppTranslation } from '../../common/hooks/translation';

import styles from './AuthForm.module.css';
import { requestCreateRecovery, requestGetRecoveryState, requestTerminateRecovery } from './actions';
import { selectIsFetching, selectPasswordResetState } from './selectors';
import { PasswordResetState } from './interfaces';
import { resetPasswordResetState } from './slice';

export interface ResetPasswordFormProps {
  recoveryKey?: string,
}

const ResetPasswordForm = ({ recoveryKey }: ResetPasswordFormProps) => {
  const isFetching = useAppSelector(selectIsFetching);
  const passwordResetState = useAppSelector(selectPasswordResetState);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');

  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();

  const validateEmail = () => {
    if (email.includes('@')) {
      if (emailError !== '') setEmailError('');
      return true;
    }
    setEmailError(t(Strings.PASSWORD_RECOVERY_BAD_EMAIL));
    return false;
  };

  const validatePasswords = () => {
    if (password) {
      if (password === passwordConfirm) {
        return true;
      }
      setPasswordConfirmError(t(Strings.PASSWORD_RECOVERY_NOT_SAME_PASSWORD));
    } else {
      setPasswordError(t(Strings.PASSWORD_RECOVERY_BAD_PASSWORD));
    }
    return false;
  };

  const resetErrors = () => {
    if (emailError) setEmailError('');
    if (passwordError) setPasswordError('');
    if (passwordConfirmError) setPasswordConfirmError('');
  };

  useEffect(() => {
    dispatch(resetPasswordResetState());
    if (recoveryKey) {
      dispatch(requestGetRecoveryState({ key: recoveryKey }));
    }
  }, []);

  useEffect(() => {
    resetErrors();
  }, [email, password, passwordConfirm]);

  const handleSubmitEmail = () => {
    if (validateEmail()) dispatch(requestCreateRecovery({ email }));
  };

  const handleSubmitPassword = () => {
    if (recoveryKey) {
      if (validatePasswords()) dispatch(requestTerminateRecovery({ key: recoveryKey, password }));
    }
  };

  const showInfoBox = () => passwordResetState !== PasswordResetState.Idle
    && passwordResetState !== PasswordResetState.UserInput;

  const renderPasswordResetForm = () => (
    <>
      <div className={styles.row}>{t(Strings.PASSWORD)}</div>
      <input
        type="password"
        className={styles.input}
        placeholder={t(Strings.PASSWORD_RECOVERY_EXAMPLE_PASSWORD)}
        onChange={(event) => setPassword(event.target.value)}
        disabled={showInfoBox()}
      />
      {passwordError !== null && <span className={styles.fieldError}>{passwordError}</span>}
      <div className={styles.row}>
        {t(Strings.PASSWORD_RECOVERY_CONFIRM_PASSWORD)}
      </div>
      <input
        type="password"
        className={styles.input}
        placeholder={t(Strings.PASSWORD_RECOVERY_EXAMPLE_PASSWORD)}
        onChange={(event) => setPasswordConfirm(event.target.value)}
        disabled={showInfoBox()}
      />
      {
        passwordConfirmError !== null && (
          <span className={styles.fieldError}>{passwordConfirmError}</span>
        )
      }
    </>
  );

  const renderEmailForm = () => (
    <>
      <div className={styles.row}>{t(Strings.EMAIL)}</div>
      <input
        className={styles.input}
        placeholder={t(Strings.EMAIL)}
        onChange={(event) => setEmail(event.target.value)}
        disabled={showInfoBox()}
      />
      {emailError !== null && <span className={styles.fieldError}>{emailError}</span>}
    </>
  );

  const renderForm = () => (recoveryKey ? renderPasswordResetForm() : renderEmailForm());

  const renderSubmitButton = () => (recoveryKey ? (
    <button
      className={styles.button}
      type="button"
      onClick={handleSubmitPassword}
      color="#5b41f0"
      disabled={isFetching}
    >
      {t(Strings.PASSWORD_RECOVERY_CONFIRM)}
    </button>
  ) : (
    <button
      className={styles.button}
      type="button"
      onClick={handleSubmitEmail}
      color="#5b41f0"
      disabled={isFetching}
    >
      {t(Strings.PASSWORD_RECOVERY_CONFIRM)}
    </button>
  ));

  const renderInfoBox = () => {
    if (passwordResetState === PasswordResetState.EmailSent) {
      return (
        <div className={styles.infoBox}>
          <div>
            {t(Strings.PASSWORD_RECOVERY_EMAIL_SENT)}
            {' '}
            <b>{email}</b>
            .
          </div>
        </div>
      );
    }
    if (passwordResetState === PasswordResetState.Completed) {
      return (
        <div className={styles.infoBox}>
          <div>{t(Strings.PASSWORD_RECOVERY_SUCCESS)}</div>
        </div>
      );
    }
    if (passwordResetState === PasswordResetState.ResetRefused) {
      return (
        <div className={styles.infoBoxError}>
          <div>{t(Strings.PASSWORD_RECOVERY_REFUSED)}</div>
        </div>
      );
    }
    return (
      <div className={styles.infoBoxError}>
        <div>{t(Strings.PASSWORD_RECOVERY_INVALID_KEY)}</div>
      </div>
    );
  };

  if (recoveryKey && passwordResetState === PasswordResetState.Idle) return null;
  // Waiting for user input or key refused

  return (
    <div className={styles.authContainer}>
      <form className={styles.form}>
        {renderForm()}
        {showInfoBox() ? renderInfoBox() : renderSubmitButton()}
      </form>
    </div>
  );
};

ResetPasswordForm.defaultProps = {
  recoveryKey: undefined,
};

export default ResetPasswordForm;
