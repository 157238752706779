import { RootState } from '../../app/store';

export const selectDevices = (state: RootState) => state.device.devices;
export const selectDevicesInfo = (state: RootState) => state.device.informations;
export const selectDevicesUpdateSessions = (state: RootState) => state.device.updateSessions;
export const selectDevicesUpdateProgressions = (state: RootState) => (
  state.device.updateProgressions
);

export const selectDevicesUpdateProgressionsForSession = (sessionId?: string) => (
  (state: RootState) => (
    state.device.updateProgressions?.find((p) => p.sessionId === sessionId)
  )
);

export default {
  selectDevices,
  selectDevicesInfo,
  selectDevicesUpdateSessions,
  selectDevicesUpdateProgressions,
};
