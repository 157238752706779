import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageContainer from '../../../common/components/page/PageContainer';
import TableDevices, { TableDevicesRef } from '../../../common/components/table/description/TableDevices';
import { TableDevicesData } from '../../../common/components/table/description/TableDevices.constant';
import { Strings } from '../../../common/constants/translation';
import { toastError, toastSuccess } from '../../../common/helpers/toast';
import { awaitWithMinimumDelay } from '../../../common/helpers/utils';
import { useAppTranslation } from '../../../common/hooks/translation';
import { updateDevice } from '../../../features/device/api';
import { useDevices } from '../../../features/device/hooks';
import { Device } from '../../../features/device/interfaces';
import { updateDeviceName } from '../../../features/device/slice';
import styles from './devices.module.css';

const DevicesPage = () => {
  const { t } = useAppTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const devices: Device[] = useDevices();
  const tableDataRef = useRef<TableDevicesData[]>([]);
  const [itemsChecked, setItemChecked] = useState<TableDevicesData[]>([]);
  const tableRef = useRef<TableDevicesRef>();

  if (devices.length && loading) {
    setLoading(false);
  }

  const updateItemChecked = () => {
    const current = tableDataRef.current.filter((d) => d.checked);
    let update = false;
    if (current.length === itemsChecked.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currItem1 of current) {
        if (!itemsChecked.find((currItem2) => currItem1.deviceId === currItem2.deviceId)) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) setItemChecked(current);
  };
  updateItemChecked();
  const onUpdateDeviceName = async (item: TableDevicesData, name: string) => {
    item.updatingName = true;
    tableRef.current?.render();
    let error = true;

    try {
      // TODO !!!! Use saga ?
      const response: any = await awaitWithMinimumDelay(
        updateDevice(item.deviceId, { name }),
        500,
      );
      if (response?.name === name) {
        error = false;
      }
    } catch (err) { /* console.error('Error : ', err); */ }

    if (!error) {
      dispatch(updateDeviceName({ id: item.deviceId, name }));
      item.name = name;
      toastSuccess(t(Strings.DEVICE_DESCRIPTION_UPDATE_DEVICE_NAME_TOAST_SUCCESS));
    } else {
      toastError(t(Strings.DEVICE_DESCRIPTION_UPDATE_DEVICE_NAME_TOAST_ERROR));
    }

    item.updatingName = false;
    tableRef.current?.render();
  };

  const generateTableData = (): TableDevicesData[] => {
    if (loading) return tableDataRef.current;
    const result: TableDevicesData[] = [];
    devices.forEach((device) => {
      const deviceId = device.id;
      const currentData: TableDevicesData | undefined = tableDataRef.current.find(
        (d) => d.deviceId === deviceId,
      );
      const online = !!device?.online;
      const newData: TableDevicesData = {
        deviceId,
        device,
        online,
        checked: currentData?.checked ?? false,
        updatingName: false,
        name: device.name,
        hardwareId: device.hardwareId,
        roomName: device.room?.name,
      };
      const data: TableDevicesData = Object.assign(currentData ?? {}, newData);
      result.push(data);
    });
    tableDataRef.current = result;
    return tableDataRef.current;
  };

  const renderTable = () => (
    <TableDevices
      className={styles.table}
      ref={tableRef}
      onUpdateDeviceName={onUpdateDeviceName}
      data={generateTableData()}
    />
  );

  // ---------------- NO DATA ------------ //

  const renderNoDevices = () => (
    <div className={styles.emptyDataMessage}>
      {t(Strings.DEVICE_DESCRIPTION_NO_DEVICES)}
    </div>
  );

  const notEmptyData = devices.length > 0;
  return (
    <PageContainer
      title={t(Strings.DEVICE_DESCRIPTION_PAGE_TITLE)}
      subtitle={t(Strings.DEVICE_DESCRIPTION_PAGE_SUBTITLE)}
      loading={loading}
    >
      <div
        className={styles.container}
      >
        {notEmptyData ? renderTable() : renderNoDevices()}
      </div>
    </PageContainer>
  );
};

export default DevicesPage;
