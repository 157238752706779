import React, { useCallback, useEffect, useRef, useState } from 'react';
import { OnClickDetails, useOnClick, useOuterClickManyTargets } from '../../hooks/click';

import { useElementHovered, useElementSize, useWindowSize, ElementHoveredDetails, Positions } from '../../hooks/window';

const DEFAULT_CLASSNAME = 'element-on-hover';

const containerStyle : React.CSSProperties = {
  position: 'fixed',
  zIndex: 10000,
  maxWidth: '100vw',
  maxHeight: '100vh',
};

export interface ElementOnHoverProps<T extends HTMLElement> {
  targetRef: React.RefObject<T> | React.MutableRefObject<T | undefined>
  /**
   * Allow to detect hover on parent of target. Hover used will be on :
   * 0 => no parent.
   * 1 => parent of target
   * 2 => parent of parent of target
   * ...
   */
  hoverOnParentLevel?: number;
  delay?: number;
  enableClick?: boolean;
  positionFixed?: { x?: number, y?: number }
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export function ElementOnHover<T extends HTMLElement>({
  targetRef,
  hoverOnParentLevel,
  delay,
  enableClick,
  positionFixed,
  children,
  className,
  style,
} : ElementOnHoverProps<T>) {
  const windowSize = useWindowSize();
  const [show, setShow] = useState<boolean>(false);
  const showUpdatedAt = useRef<Date>();
  const divRef = useRef<HTMLDivElement>(null);
  const delayTimeout: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef<NodeJS.Timeout>();
  const positionsRef = useRef<Positions>();

  const getDivPosition = () : [number, number] | null => {
    if (!divRef.current || !positionsRef.current?.absolute) return null;
    const divElement = divRef.current;
    let { x, y } = positionsRef.current.absolute;
    const margeY = 10;
    const margeX = 20;

    if (divElement && windowSize.width && windowSize.height) {
      const width = divElement.clientWidth;
      const height = divElement.clientHeight;

      if (width > 0 && height > 0) {
        if (y - margeY - height > margeY) {
          y = y - margeY - height;
        } else {
          y += margeY;
        }

        if (x - width / 2 < margeX) {
          x = margeX;
        } else {
          x -= width / 2;
        }

        if (x + width > windowSize.width - margeX) {
          x = windowSize.width - width - margeX;
        }

        return [x, y];
      }
    }
    return null;
  };

  useElementHovered<T>(targetRef, (details: ElementHoveredDetails) => {
    positionsRef.current = details.positions;
    const pos = getDivPosition();
    if (divRef.current && pos) {
      const [x, y] = pos;
      Object.assign(divRef.current.style, {
        top: `${y}px`,
        left: `${x}px`,
      });
      if (show) {
        Object.assign(divRef.current.style, {
          visible: 'visible',
        });
      }
    }
    if (details.isHovered) {
      if (!show) {
        if (delay) {
          if (!delayTimeout.current) {
            delayTimeout.current = setTimeout(() => {
              clearTimeout(delayTimeout.current);
              delayTimeout.current = undefined;
              setShow(true);
            }, delay);
          }
        } else {
          setShow(true);
        }
      }
    } else {
      if (delayTimeout.current) clearTimeout(delayTimeout.current);
      delayTimeout.current = undefined;
      if (show) setShow(false);
    }
  }, { enableTouch: false, hoverOnParentLevel });

  useOnClick(targetRef, (details: OnClickDetails) => {
    if (!enableClick) return;
    if (show) return;
    positionsRef.current = details.positions;
    const pos = getDivPosition();
    if (divRef.current && pos) {
      const [x, y] = pos;
      Object.assign(divRef.current.style, {
        top: `${y}px`,
        left: `${x}px`,
      });
      Object.assign(divRef.current.style, {
        visible: 'visible',
      });
    }
    setShow(true);
  });

  const outerClickTargets : any = [divRef];
  if (enableClick) outerClickTargets.push(targetRef);
  useOuterClickManyTargets<HTMLElement>(outerClickTargets, useCallback(() => {
    if (show) {
      if (showUpdatedAt.current
        && new Date().getTime() - showUpdatedAt.current.getTime() > 100) {
        setShow(false);
      }
    }
  }, [show, setShow]));
  useElementSize(divRef);

  useEffect(() => {
    showUpdatedAt.current = new Date();
  }, [show]);

  const render = () => {
    const divStyle : React.CSSProperties = { ...containerStyle };
    if (style) Object.assign(divStyle, style);
    Object.assign(divStyle, { visibility: 'hidden' });
    // Set position
    if (positionFixed) {
      Object.assign(divStyle, {
        top: `${positionFixed.y ?? 0}px`,
        left: `${positionFixed.x ?? 0}px`,
      });
    } else if (positionsRef.current?.absolute) {
      const pos = getDivPosition();
      if (pos) {
        const [x, y] = pos;
        Object.assign(divStyle, {
          visibility: 'visible',
          top: `${y}px`,
          left: `${x}px`,
        });
      }
    }

    if (!show) Object.assign(divStyle, { display: 'none', pointerEvents: 'none' });
    return (
      <div
        ref={divRef}
        style={divStyle}
        className={className}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          if (show) setShow(false);
          e.preventDefault();
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            if (show) setShow(false);
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        { children }
      </div>
    );
  };

  return render();
}

ElementOnHover.defaultProps = {
  hoverOnParentLevel: 0,
  enableClick: true,
  delay: 500,
  className: DEFAULT_CLASSNAME,
  style: undefined,
};

export default ElementOnHover;
