import React, { ForwardedRef, useImperativeHandle, useRef } from 'react';
import Touchable, { TouchableRef } from './Touchable';

const DEFAULT_DISABLED = false;

const containerStyle : React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const imgStyle : React.CSSProperties = {
  width: '100%',
  height: '100%',
};
const defaultAdditionalStylePressed : React.CSSProperties = {
  opacity: 0.5,
};

export interface TouchableImageProps {
  src: string;
  alt: string;
  onPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  disabled?: boolean;
  additionalStylePressed?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
  imageClassName?: string;
  imageStyle?: React.CSSProperties;
}

export interface TouchableImageRef {
  getHTMLElement: () => HTMLElement | undefined;
}

const TouchableImage = React.forwardRef(({
  src,
  alt,
  onPress,
  onPressIn,
  onPressOut,
  disabled,
  additionalStylePressed,
  className,
  style,
  imageClassName,
  imageStyle,
} : TouchableImageProps, forwardRef: ForwardedRef<TouchableImageRef | undefined>) => {
  const innerRef = useRef<TouchableRef>();

  const getHTMLElement = () => innerRef.current?.getHTMLElement();

  useImperativeHandle(forwardRef, () => ({
    getHTMLElement,
  }));

  return (
    <Touchable
      style={{ ...containerStyle, ...(style || {}) }}
      className={className}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      additionalStylePressed={additionalStylePressed}
      disabled={disabled}
    >
      <img
        style={{ ...imgStyle, ...(imageStyle || {}) }}
        className={imageClassName}
        src={src}
        alt={alt}
      />
    </Touchable>
  );
});

TouchableImage.defaultProps = {
  className: 'touchable-image',
  imageClassName: undefined,
  imageStyle: undefined,
  style: undefined,
  additionalStylePressed: defaultAdditionalStylePressed,
  onPress: undefined,
  onPressIn: undefined,
  onPressOut: undefined,
  disabled: DEFAULT_DISABLED,
};

export default TouchableImage;
