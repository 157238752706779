import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterContext } from '../../../app/RouterContext';
import { ReactComponent as IconBack } from '../../../assets/icons/back.svg';
import Touchable from './Touchable';

import styles from './BackButton.module.css';

export interface BackButtonProps {
  enableRedirectionIfCantGoBack?: boolean;
  redirectionIfCantGoBack?: string;
  className?: string;
  style?: React.CSSProperties;
}

const BackButton = ({
  enableRedirectionIfCantGoBack,
  redirectionIfCantGoBack,
  className,
  style,
}: BackButtonProps) => {
  const navigate = useNavigate();
  const route = useContext(RouterContext);

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <Touchable
      onPress={() => {
        let nextRoute = route?.from;
        /* eslint-disable react/destructuring-assignment */
        if (!nextRoute || nextRoute === route.current) {
          if (!enableRedirectionIfCantGoBack) return;
          nextRoute = redirectionIfCantGoBack;
        }
        navigate(nextRoute ?? '/');
      }}
      className={classes.join(' ')}
      style={style}
    >
      <IconBack width="100%" height="100%" />
    </Touchable>
  );
};

BackButton.defaultProps = {
  enableRedirectionIfCantGoBack: true,
  redirectionIfCantGoBack: '/',
  className: undefined,
  style: undefined,
};

export default BackButton;
