import React, { useImperativeHandle, useRef } from 'react';
import Colors from '../../../constants/colors';
import { useElementSize } from '../../../hooks/window';
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from '../../utils/Table';
import { useRender } from '../../../hooks/component';
import { useAppTranslation } from '../../../hooks/translation';
import { TableNetworkDevicesData } from './TableNetworkDevices.constant';
import { Strings } from '../../../constants/translation';

import styles from './TableNetworkDevices.module.css';
import commonStyles from '../TableCommon.module.css';

export interface TableNetworkDevicesRef {
  render: () => void;
}

export interface TableNetworkDevicesProps {
  data: TableNetworkDevicesData[];
  onItemChecked?: (item: TableNetworkDevicesData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableNetworkDevices = React.forwardRef((
  {
    data,
    onItemChecked,
    className,
    style,
  }: TableNetworkDevicesProps,
  forwardRef: React.ForwardedRef<TableNetworkDevicesRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const render = useRender();
  const size = useElementSize(containerRef);

  useImperativeHandle(forwardRef, () => ({
    render,
  }));

  const generateConfiguration = () => {
    const columnConfiguration: TableConfColumn<TableNetworkDevicesData>[] = [
      /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
      {
        type: TableColumnType.CLASSIC,
        key: 'name',
        width: '15%',
        minWidth: '7.5rem',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_DEVICES),
        header: { className: commonStyles.tableHeaderCellDevice },
        item: { className: commonStyles.tableCellDevice },
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'localIp',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_LOCAL_IP),
        width: '7.5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'externalIp',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_EXTERNAL_IP),
        width: '7.5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'dns',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_DNS),
        width: '7.5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'gateway',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_GATEWAY),
        width: '7.5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'netmask',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_NETMASK),
        width: '7.5rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'mac',
        title: t(Strings.NETWORK_TABLE_COLUMN_TITLE_MAC_ADDRESS),
        minWidth: '11rem',
      },
    ];

    const tableConfiguration: TableConf<TableNetworkDevicesData> = {
      columns: columnConfiguration,
      header: {
        className: commonStyles.tableHeader,
        cell: {
          className: commonStyles.tableHeaderCell,
        },
      },
      row: {
        className: commonStyles.tableRow,
        cell: {
          className: commonStyles.tableRowCell,
        },
      },
      content: { className: commonStyles.tableContent },
      valueToShowIfUndefined: { value: '-', className: commonStyles.tableUndefinedValue },
    };

    return tableConfiguration;
  };

  // Called when a value is changed. Checkboxes here
  const valueChanged = (value: any, columnKey: string, item: TableNetworkDevicesData) => {
    if (columnKey === 'checked') onItemChecked?.(item);
  };

  const customRenderCell = (
    element: JSX.Element | null,
    elementRef: TableContentRef,
    columnKey: string,
    item: TableNetworkDevicesData,
  ) => {
    if (['localIp', 'externalIp', 'dns', 'gateway'].includes(columnKey)) {
      if (!item.online) return '-';
      const ip = item[columnKey as keyof TableNetworkDevicesData];
      if (!ip) return element;
      const url = `http://${ip}`;
      return (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={styles.link_ip}
        >
          {element}
        </a>
      );
    }
    return element;
  };

  const addCustomStyleOnCell = (
    columnKey: string,
    item: TableNetworkDevicesData,
  ) => {
    const result: TableStyle = {};
    return result;
  };

  const addCustomStyleOnRow = (
    item: TableNetworkDevicesData,
    currData: TableNetworkDevicesData[],
    index: number,
  ) => {
    const result: TableStyle = {};
    const rowStyle: React.CSSProperties = {};
    result.style = rowStyle;
    if (!item.online) {
      rowStyle.opacity = 0.5;
      rowStyle.backgroundColor = Colors.getClayEbonyMedium(0.4);
      if (index === 0 || currData[index - 1].online) {
        rowStyle.borderTopLeftRadius = '10px';
        rowStyle.borderTopRightRadius = '10px';
      }
      if (index + 1 >= currData.length || currData[index + 1].online) {
        rowStyle.borderBottomLeftRadius = '10px';
        rowStyle.borderBottomRightRadius = '10px';
      }
    }
    return result;
  };

  const sortByDeviceOnline = (a: TableNetworkDevicesData, b: TableNetworkDevicesData) => {
    const aOnline = a.online ? 1 : 0;
    const bOnline = b.online ? 1 : 0;
    return bOnline - aOnline;
  };

  const onRenderTableStarts = () => {
  };

  const onRenderTableEnded = () => {
  };

  const renderTable = () => (
    <Table
      className={commonStyles.table}
      data={data}
      keyExtractor={(_, item) => `key-${item.deviceId}`}
      configuration={generateConfiguration()}
      onData={(d) => d.sort(sortByDeviceOnline)}
      onRenderCellRow={customRenderCell}
      onStyleCellRow={addCustomStyleOnCell}
      onStyleRow={addCustomStyleOnRow}
      onChangeValue={valueChanged}
      onRenderStarts={() => onRenderTableStarts}
      onRenderEnded={onRenderTableEnded}
    />
  );

  const classes = [commonStyles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderTable()}
    </div>
  );
});

TableNetworkDevices.defaultProps = {
  onItemChecked: undefined,
  className: undefined,
  style: undefined,
};

export default TableNetworkDevices;
