import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectError } from './selectors';
import { setError } from './slice';

export const useAppError = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);
  const reset = () => dispatch(setError(undefined));
  return { error, reset };
};

export default {
  useAppError,
};
