import { VideoState } from '@kalyzee/kast-react-player-module';
import React, { useEffect, useRef, useState } from 'react';
import Colors from '../../constants/colors';
import { Strings } from '../../constants/translation';
import { useAppTranslation } from '../../hooks/translation';
import Fonts from '../../../constants/fonts';
import MessageOnHover, { MessageOnHoverMode } from '../utils/MessageOnHover';
import TouchableImage from '../utils/TouchableImage';
import NoVideo from '../../../assets/icons/no_camera.svg';
import Loading from '../../../assets/icons/loading.svg';
import NoConnected from '../../../assets/icons/no_connected.svg';
import Retry from '../../../assets/icons/reload.svg';
import { usePrevious } from '../../hooks/utils';

const DEFAULT_CLASSNAME = 'video-overlay';

const SHOW_RETRY_LOADING_STATE_DELAY = 30000; // in ms

const containerStyle : React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

const loadingStyle : React.CSSProperties = {
  backgroundColor: Colors.getCodGray(),
  fontFamily: Fonts.getSignika(),
  fontSize: '0.9em',
};

const loadingImgStyle : React.CSSProperties = {
  width: '20px',
  height: '20px',
};

const noVideoStyle : React.CSSProperties = {
  backgroundColor: Colors.getCodGray(),
};

const noVideoImgStyle : React.CSSProperties = {
  width: '40px',
  height: '30px',
  marginBottom: '2px',
};

const noVideoMsgStyle : React.CSSProperties = {
  fontFamily: Fonts.getSignika(),
  fontSize: '0.8em',
  color: Colors.getMainWhite(),
  marginTop: '5px',
};

const noConnectedStyle : React.CSSProperties = {
  backgroundColor: Colors.getCodGray(),
};

const noConnectedImgStyle : React.CSSProperties = {
  width: '30px',
  height: '30px',
  marginBottom: '2px',
};

const noConnectedMsgStyle : React.CSSProperties = {
  fontFamily: Fonts.getSignika(),
  fontSize: '0.8em',
  color: Colors.getMainWhite(),
  marginTop: '5px',
};

const retryButtonStyle : React.CSSProperties = {
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  width: '20px',
  height: '20px',
};

const messageContainerStyle : React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export interface VideoOverlayProps {
  state: VideoState | 'NO_CONNECTED';
  onRetry?: () => void;
  className?: string;
  style?: React.CSSProperties;
}
const VideoOverlay = ({
  state,
  onRetry,
  className,
  style,
} : VideoOverlayProps) => {
  const { t } = useAppTranslation();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [showRetry, setShowRetry] = useState<boolean>(false);
  const previewState = usePrevious<VideoState | 'NO_CONNECTED'>(state);

  useEffect(() => {
    let timeout : NodeJS.Timeout;
    if (state === VideoState.STOPPED) {
      setShowRetry(true);
    } else if (state === VideoState.LOADING) {
      if (previewState !== state) setShowRetry(false);
      timeout = setTimeout(() => {
        setShowRetry(true);
      }, SHOW_RETRY_LOADING_STATE_DELAY);
    } else {
      setShowRetry(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [state, showRetry]);

  const divStyle : React.CSSProperties = { ...containerStyle };
  if (style) Object.assign(divStyle, style);

  const retry = () => {
    setShowRetry(false);
    onRetry?.();
  };

  const renderRetryButton = () => {
    if (!showRetry) return null;
    return (
      <TouchableImage
        style={retryButtonStyle}
        src={Retry}
        onPress={retry}
        alt="retry"
      />
    );
  };

  const renderRunning = () => null;

  const renderLoading = () => (
    <div
      className={className}
      style={{ ...loadingStyle, ...divStyle }}
    >
      { t(Strings.LOADING) }
      <img
        style={loadingImgStyle}
        src={Loading}
        alt="loading"
      />
      { renderRetryButton() }
    </div>
  );

  const renderStopped = () => (
    <div
      className={className}
      style={{ ...noVideoStyle, ...divStyle }}
    >
      <MessageOnHover
        mode={MessageOnHoverMode.ERROR}
        message={t(Strings.NO_VIDEO_SIGNAL_DESCRIPTION)}
        targetRef={messageContainerRef}
      />
      <div
        ref={messageContainerRef}
        style={messageContainerStyle}
      >
        <img
          style={noVideoImgStyle}
          src={NoVideo}
          alt="no video"
        />
        <div style={noVideoMsgStyle}>
          {t(Strings.NO_VIDEO_SIGNAL)}
        </div>
      </div>

      { renderRetryButton() }
    </div>
  );

  const renderNoConnected = () => (
    <div
      className={className}
      style={{ ...noConnectedStyle, ...divStyle }}
    >
      <MessageOnHover
        mode={MessageOnHoverMode.WARNING}
        message={t(Strings.NO_CONNECTED_DESCRIPTION)}
        targetRef={messageContainerRef}
      />
      <div
        ref={messageContainerRef}
        style={messageContainerStyle}
      >
        <img
          style={noConnectedImgStyle}
          src={NoConnected}
          alt="no connected"
        />
        <div style={noConnectedMsgStyle}>
          { t(Strings.NO_CONNECTED) }
        </div>
      </div>
    </div>
  );

  const render = () => {
    if (state === VideoState.LOADING) return renderLoading();
    if (state === VideoState.RUNNING) return renderRunning();
    if (state === VideoState.STOPPED) return renderStopped();
    if (state === 'NO_CONNECTED') return renderNoConnected();
    return null;
  };

  return render();
};

VideoOverlay.defaultProps = {
  onRetry: undefined,
  className: DEFAULT_CLASSNAME,
  style: undefined,
};

export default VideoOverlay;
