import React, { useEffect, useRef, useState } from 'react';
import { DeviceUpdateAbortRequest, DeviceUpdateAbortResult,
  DeviceUpdateInstallRequest,
  DeviceUpdateInstallResult,
  DeviceUpdateScheduleSilentRequest,
  DeviceUpdateScheduleSilentResult,
  DeviceUpdateSession,
  DeviceUpdateSessionMode,
  DeviceUpdateSessionStatus, DeviceUpdateStartDownloadRequest, DeviceUpdateStartDownloadResult,
  SoftwareVersionInformation, VersionState } from '@kalyzee/kast-websocket-module';
import { useSocketAppDispatch } from '../../../app/hooks';
import PageContainer from '../../../common/components/page/PageContainer';
import { abortUpdate, installUpdate, requestDevicesInformation, requestDevicesUpdateSessions, scheduleUpdate, startDownloadUpdate } from '../../../features/device/actions';
import { useDevices, useDevicesInfo, useDevicesUpdateSessions } from '../../../features/device/hooks';
import { Device, DeviceInformation } from '../../../features/device/interfaces';
import { useSocket } from '../../../features/socket/hooks';
import { SocketStatus } from '../../../features/socket/interfaces';
import { usePrevious } from '../../../common/hooks/utils';
import Button from '../../../common/components/utils/Button';
import TableUpdateDevices, { TableUpdateDevicesRef } from '../../../common/components/table/update/TableUpdateDevices';
import { TableUpdateDeviceAction, TableUpdateDevicesData } from '../../../common/components/table/update/TableUpdateDevices.constant';
import MessageOnHover, { MessageOnHoverMode } from '../../../common/components/utils/MessageOnHover';
import Colors from '../../../common/constants/colors';
import { useRender } from '../../../common/hooks/component';
import { PopupButtonElement, PopupButtonType, PopupIconType, PopupId, PopupRef } from '../../../common/components/utils/Popup';
import { showPopup } from '../../../common/components/utils/PopupContainer';
import { useAppTranslation } from '../../../common/hooks/translation';
import { Strings } from '../../../common/constants/translation';
import TimePicker, { TimePickerRef } from '../../../common/components/utils/TimePicker';

import { ReactComponent as IconSuccess } from '../../../assets/icons/success.svg';
import { ReactComponent as IconError } from '../../../assets/icons/error.svg';
import { ReactComponent as IconInfo } from '../../../assets/icons/info.svg';
import { ReactComponent as IconWarning } from '../../../assets/icons/warning.svg';

import styles from './devices.update.module.css';
import { getDeviceName } from '../../../features/device/utils';

const DATE_FORMAT = { year: 'numeric', month: 'long', day: '2-digit' };

const DevicesUpdatePage = () => {
  const { t } = useAppTranslation();
  const render = useRender();
  const [loading, setLoading] = useState<boolean>(true);
  const { socketStatus } = useSocket();
  const socketDispatch = useSocketAppDispatch();
  const devices: Device[] = useDevices();
  const lastDevices: Device[] | undefined = usePrevious(devices);
  const information: DeviceInformation[] = useDevicesInfo();
  const sessions: DeviceUpdateSession[] = useDevicesUpdateSessions();
  const tableRef = useRef<TableUpdateDevicesRef>();
  const tableDataRef = useRef<TableUpdateDevicesData[]>([]);
  const softwares: SoftwareVersionInformation[] = [];
  const newSoftwares: SoftwareVersionInformation[] = [];
  const [itemsChecked, setItemChecked] = useState<TableUpdateDevicesData[]>([]);

  information.forEach((info, i) => {
    const currentSoftware = info.osVersion?.currentSoftwareInformation;
    const lastSoftware = info.osVersion?.lastSoftwareInformation;
    if (lastSoftware) {
      if (!softwares.find((s) => s.id === lastSoftware.id)) {
        softwares.push(lastSoftware);
      }
      if (currentSoftware?.id !== lastSoftware?.id
        && info.osVersion?.state !== VersionState.UP_TO_DATE) {
        if (!newSoftwares.find((s) => s.id === lastSoftware.id)) {
          newSoftwares.push(lastSoftware);
        }
      }
    }
  });

  if (devices.length && sessions && loading) {
    setLoading(false);
  }

  const updateItemChecked = () => {
    const current = tableDataRef.current.filter((d) => d.checked);
    let update = false;
    if (current.length === itemsChecked.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currItem1 of current) {
        if (!itemsChecked.find((currItem2) => currItem1.deviceId === currItem2.deviceId)) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) setItemChecked(current);
  };
  updateItemChecked();

  const getSession = (deviceId: string): DeviceUpdateSession | undefined => {
    const sessionsSorted = [...sessions].sort((
      s1: DeviceUpdateSession,
      s2: DeviceUpdateSession,
    ) => {
      if (s1.status === DeviceUpdateSessionStatus.ABORTED
        && s2.status !== DeviceUpdateSessionStatus.ABORTED) return 1;
      if (s2.status === DeviceUpdateSessionStatus.ABORTED
        && s1.status !== DeviceUpdateSessionStatus.ABORTED) return -1;
      if (s1.status !== DeviceUpdateSessionStatus.ABORTED
        && s2.status !== DeviceUpdateSessionStatus.ABORTED) {
        // MANDATORY IN PRIORITY
        if (s1.mode === DeviceUpdateSessionMode.MANDATORY
          && s2.mode === DeviceUpdateSessionMode.SILENT) return -1;
        if (s1.mode === DeviceUpdateSessionMode.SILENT
          && s2.mode === DeviceUpdateSessionMode.MANDATORY) return 1;
      }

      const lastUpdated1 = s1?.updatedAt ? new Date(s1.updatedAt).getTime() : 0;
      const lastUpdated2 = s2?.updatedAt ? new Date(s2.updatedAt).getTime() : 0;
      return lastUpdated2 - lastUpdated1;
    });
    return sessionsSorted.find((s) => s._device === deviceId);
  };

  const refreshDeviceInformation = () => (socketDispatch(requestDevicesInformation({
    information: true,
    versions: true,
  })));
  const refreshDeviceUpdateSessions = () => (socketDispatch(requestDevicesUpdateSessions()));

  const startDownloadUpdateForDevice = async (
    item: TableUpdateDevicesData,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId } = item;
    const softwareId = item.info?.osVersion?.lastSoftwareInformation?.id;
    if (!softwareId) {
      item.error = t(Strings.UPDATE_START_UPDATE_ERROR);
      if (rerender) tableRef.current?.render();
      return false;
    }

    item.running = true;
    if (rerender) tableRef.current?.render();

    const payload: DeviceUpdateStartDownloadRequest = {
      deviceId,
      softwareVersionId: softwareId,
    };
    const result: DeviceUpdateStartDownloadResult = await socketDispatch(
      startDownloadUpdate(payload),
    );

    if (result.error) {
      item.running = false;
      item.error = t(Strings.UPDATE_START_UPDATE_ERROR);
    }
    if (rerender) tableRef.current?.render();
    return true;
  };

  const scheduleUpdateForDevice = async (
    item: TableUpdateDevicesData,
    start: Date,
    end: Date,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId } = item;
    const softwareId = item.info?.osVersion?.lastSoftwareInformation?.id;
    if (!softwareId) {
      item.error = t(Strings.UPDATE_START_UPDATE_ERROR);
      if (rerender) tableRef.current?.render();
      return false;
    }

    item.running = true;
    if (rerender) tableRef.current?.render();

    const payload: DeviceUpdateScheduleSilentRequest = {
      deviceId,
      softwareVersionId: softwareId,
      startTime: start,
      endTime: end,
    };
    const result: DeviceUpdateScheduleSilentResult = await socketDispatch(
      scheduleUpdate(payload),
    );

    if (result.error) {
      item.error = t(Strings.UPDATE_START_UPDATE_ERROR);
    } else {
      item.scheduled = true;
    }
    item.running = false;

    if (rerender) tableRef.current?.render();
    return true;
  };

  const installUpdateForDevice = async (
    item: TableUpdateDevicesData,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId, session } = item;
    if (!session) {
      item.error = t(Strings.UPDATE_START_INSTALL_ERROR);
      if (rerender) tableRef.current?.render();
      return false;
    }

    item.running = true;
    if (rerender) tableRef.current?.render();

    const payload: DeviceUpdateInstallRequest = {
      deviceId,
      sessionId: session.id,
    };
    const result: DeviceUpdateInstallResult = await socketDispatch(installUpdate(payload));

    if (result.error) {
      item.running = false;
      item.error = t(Strings.UPDATE_START_INSTALL_ERROR);
    }
    if (rerender) tableRef.current?.render();
    return true;
  };
  const abortUpdateForDevice = async (
    item: TableUpdateDevicesData,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId, session } = item;
    if (!session) {
      item.error = t(Strings.UPDATE_ABORT_ERROR);
      return false;
    }

    const lastRunningValue = item.running;
    item.running = true;
    if (rerender) tableRef.current?.render();
    const payload: DeviceUpdateAbortRequest = {
      deviceId,
      sessionId: session.id,
    };
    const result: DeviceUpdateAbortResult = await socketDispatch(abortUpdate(payload));

    if (result.error) {
      item.running = lastRunningValue;
      item.error = t(Strings.UPDATE_ABORT_ERROR);
    } else {
      item.running = false;
    }
    if (rerender) tableRef.current?.render();
    return true;
  };

  useEffect(() => {
    if (socketStatus === SocketStatus.Online) {
      // First request here
      refreshDeviceInformation();
      refreshDeviceUpdateSessions();
    }
  }, [socketStatus]);

  useEffect(() => {
    if (lastDevices) {
      let newDeviceConnected = false;
      devices.forEach((d) => {
        const last = lastDevices.find((l) => l.id === d.id);
        if (!last || (!last.online && d.online)) {
          newDeviceConnected = true;
        }
      });

      if (newDeviceConnected) {
        refreshDeviceInformation();
        refreshDeviceUpdateSessions();
      }
    }
  }, [devices]);

  const getVersion = (data: any): string => (
    data?.versionLabel || data?.versionName || data?.versionCode
  );

  const generateTableData = (): TableUpdateDevicesData[] => {
    if (loading) return tableDataRef.current;
    const result: TableUpdateDevicesData[] = [];
    devices.forEach((device) => {
      const deviceId = device.id;
      const info = information.find((i) => i.deviceId === deviceId);

      const currentData: TableUpdateDevicesData | undefined = tableDataRef.current.find(
        (d) => d.deviceId === deviceId,
      );
      const version = getVersion(info?.osVersion?.currentSoftwareInformation);
      const versionCode = info?.osVersion?.currentSoftwareInformation?.versionCode;
      const newVersion = getVersion(info?.osVersion?.lastSoftwareInformation);
      const state = info?.osVersion?.state;
      const builtAt = info?.osVersion?.information.builtAt;
      const date = builtAt ? new Date(builtAt) : undefined;
      const online = !!device?.online;
      let running = currentData?.running ?? false;
      let scheduled = false;
      const session = getSession(deviceId);
      if (session) {
        if (session.mode === DeviceUpdateSessionMode.MANDATORY) {
          if (session.status === DeviceUpdateSessionStatus.WAITING
            || session.status === DeviceUpdateSessionStatus.DOWNLOADING
            || session.status === DeviceUpdateSessionStatus.INSTALLING) {
            running = true;
          } else {
            running = false;
          }

          if (!online && session.status !== DeviceUpdateSessionStatus.INSTALLING) {
            running = false;
          }
        } else if (session.mode === DeviceUpdateSessionMode.SILENT) {
          if (session.status !== DeviceUpdateSessionStatus.FAILED
            && session.status !== DeviceUpdateSessionStatus.ABORTED) {
            scheduled = true;
          }
        }
      } else if (state === VersionState.UP_TO_DATE) {
        running = false;
      } else if (!online) {
        running = false;
      } else if (currentData?.session) {
        running = false;
      }

      let checked = currentData?.checked ?? false;
      if (scheduled) {
        checked = false;
      }
      const error = currentData?.error;
      const newData: TableUpdateDevicesData = {
        deviceId,
        device,
        info,
        session,
        name: getDeviceName(device, false),
        date,
        version,
        versionCode,
        newVersion,
        state,
        online,
        checked,
        running,
        scheduled,
        error,
      };
      const data: TableUpdateDevicesData = Object.assign(currentData ?? {}, newData);
      result.push(data);
    });
    tableDataRef.current = result;
    return tableDataRef.current;
  };

  // ---------------- POPUP ------------ //

  const showPopupBeforeInstall = (
    items: TableUpdateDevicesData[],
    successCallback?: () => void,
  ) => {
    const displayed = showPopup({
      id: PopupId.UPDATE_INSTALL_CONFIRMATION,
      title: t(Strings.UPDATE_POPUP_INSTALL_CONFIRMATION_TITLE),
      buttons: [
        {
          element: t(Strings.UPDATE_POPUP_INSTALL_CONFIRMATION_CANCEL),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(Strings.UPDATE_POPUP_INSTALL_CONFIRMATION_VALIDATE),
          type: PopupButtonType.VALIDATE,
          onClick: () => {
            successCallback?.();
            return true;
          },
        },
      ],
      content: t(items.length > 1
        ? Strings.UPDATE_POPUP_INSTALL_CONFIRMATION_CONTENT_MANY_DEVICE
        : Strings.UPDATE_POPUP_INSTALL_CONFIRMATION_CONTENT),
      iconContent: PopupIconType.WARNING,
      enableCloseButton: false,
    });
    // not show again
    if (!displayed) {
      successCallback?.();
    }
  };

  const showPopupSchedule = (
    items: TableUpdateDevicesData[],
    successCallback?: (start: Date, end: Date) => void,
  ) => {
    const popupRef: React.MutableRefObject<PopupRef | null> = { current: null };
    let message: string;
    if (items?.length > 1) {
      message = t(Strings.UPDATE_POPUP_SCHEDULE_MANY_UPDATE_CONTENT);
    } else {
      message = t(Strings.UPDATE_POPUP_SCHEDULE_ONE_UPDATE_CONTENT);
    }
    const now = new Date();
    let startDate: Date | undefined = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
    );
    let endDate: Date | undefined = new Date(startDate.getTime() + 1000 * 60 * 60);
    let startTimeText : string | undefined;
    let endTimeText : string | undefined;
    const startTimerPickerRef: React.MutableRefObject<TimePickerRef | null> = { current: null };
    const endTimerPickerRef: React.MutableRefObject<TimePickerRef | null> = { current: null };
    const stepMinute = 10;
    const iconSize = 20;

    let detailsType: 'error' | 'warning' | 'none' = 'none';
    let detailsMessage: string | undefined;

    const updateDetails = () => {
      if (!startDate || !endDate) {
        detailsType = 'error';
        detailsMessage = t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_ERROR_UNKNOWN);
        return;
      }

      const startMs = startDate.getTime();
      const endMs = endDate.getTime();

      if (startMs === endMs) {
        detailsType = 'error';
        detailsMessage = t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_ERROR_SAME_TIME);
        return;
      }

      if (endMs < startMs) {
        detailsType = 'warning';
        detailsMessage = t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_WARING_TWO_DAYS, {
          start: startTimeText,
          end: endTimeText,
        });
        return;
      }

      /* DISABLE
      const limitMinutes = 0;
      if (endMs - startMs < limitMinutes) {
        detailsType = 'warning';
        detailsMessage = t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_WARING_SMALL_RANGE);
        return;
      }
      */
      detailsType = 'none';
    };

    const getButtons = () : PopupButtonElement[] => (
      [
        {
          element: t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_CANCEL),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_VALIDATE),
          type: PopupButtonType.VALIDATE,
          disabled: detailsType === 'error',
          onClick: () => {
            if (detailsType === 'error') return false;
            const start : Date = startTimerPickerRef.current!.getDate()!;
            const end : Date = endTimerPickerRef.current!.getDate()!;
            const startMs = start!.getTime();
            const endMs = end!.getTime();
            if (endMs <= startMs) end.setTime(end.getTime() + 24 * 60 * 60 * 1000);
            successCallback?.(start, end);
            return true;
          },
        },
      ]
    );
    const getContent = () => {
      const timePickerOnChange = () => {
        const lastDetailsType = detailsType;
        const lastDetailsMessage = detailsMessage;
        updateDetails();
        if (lastDetailsType !== detailsType || lastDetailsMessage !== detailsMessage) {
          popupRef.current?.updateContent(getContent());
          popupRef.current?.updateButtons(getButtons());
        }
      };
      return (
        <div className={styles.popupContentScheduleContainer}>
          {message}
          <div className={styles.popupContentSchedulePickerContainer}>
            <TimePicker
              stepMinute={stepMinute}
              value={startDate}
              ref={startTimerPickerRef}
              onChange={(value: string, date : Date) => {
                startTimeText = value;
                startDate = date;
                endTimeText = endTimerPickerRef.current?.getValue();
                endDate = endTimerPickerRef.current?.getDate();
                timePickerOnChange();
              }}
            />
            -
            <TimePicker
              stepMinute={stepMinute}
              value={endDate}
              ref={endTimerPickerRef}
              onChange={(value: string, date : Date) => {
                startTimeText = startTimerPickerRef.current?.getValue();
                startDate = startTimerPickerRef.current?.getDate();
                endTimeText = value;
                endDate = date;
                timePickerOnChange();
              }}
            />
          </div>
          {
            detailsType !== 'none' ? (
              <div
                className={styles.popupContentScheduleWarningContainer}
                style={{ color: detailsType === 'warning' ? Colors.getBroom() : Colors.getTorchRed() }}
              >
                {
                  detailsType === 'warning'
                    ? <IconWarning width={iconSize} fill={Colors.getBroom()} />
                    : <IconError width={iconSize} fill={Colors.getTorchRed()} />
                }
                {detailsMessage}
              </div>
            ) : null
          }
        </div>
      );
    };
    showPopup({
      id: PopupId.UPDATE_SCHEDULE_SILENT,
      title: t(Strings.UPDATE_POPUP_SCHEDULE_UPDATE_TITLE),
      buttons: getButtons(),
      enableNotShowAgain: false,
      enableCloseButton: false,
      enableBackdropDismiss: false,
      content: getContent(),
    }, popupRef);
  };

  // ---------------- INSTALL ------------ //

  const startDownloadUpdateForData = (items: TableUpdateDevicesData[]) => {
    items.forEach((item) => {
      startDownloadUpdateForDevice(item);
    });
    render();
  };

  const scheduleUpdateForData = (items: TableUpdateDevicesData[], start: Date, end: Date) => {
    items.forEach((item) => {
      scheduleUpdateForDevice(item, start, end);
    });
    render();
  };

  const startInstallUpdateForData = (items: TableUpdateDevicesData[]) => {
    items.forEach((item) => {
      installUpdateForDevice(item);
    });
    render();
  };
  // ---------------- TABLE ------------ //

  const onItemCheck = (item: TableUpdateDevicesData) => {
    updateItemChecked();
  };

  const renderTable = () => (
    <TableUpdateDevices
      className={styles.table}
      data={generateTableData()}
      ref={tableRef}
      onItemChecked={onItemCheck}
      onAction={(
        action: TableUpdateDeviceAction,
        item: TableUpdateDevicesData,
      ) => {
        if (action === TableUpdateDeviceAction.START_UPDATE) {
          startDownloadUpdateForDevice(item);
        } else if (action === TableUpdateDeviceAction.INSTALL) {
          showPopupBeforeInstall([item], () => {
            installUpdateForDevice(item);
          });
        } else if (action === TableUpdateDeviceAction.ABORT) {
          abortUpdateForDevice(item);
        } else if (action === TableUpdateDeviceAction.CLEAN_ERROR) {
          item.error = undefined;
          tableRef.current?.render();
        }
      }}
    />
  );

  // ---------------- VERSION ------------ //

  const renderNewVersions = () => {
    const content: React.ReactNode[] = [];
    let headerIcon: React.ReactNode = null;
    let headerMessage: string = '';

    const renderNewVersion = (software: SoftwareVersionInformation) => {
      const date = new Date(software.builtAt).toLocaleDateString(undefined, DATE_FORMAT as any);
      return (
        <div
          className={styles.newVersionContainer}
          key={`key-${software.id}`}
        >
          <div>
            <span className={styles.title}>{t(Strings.UPDATE_VERSIONS_DETAILS_VERSION)}</span>
            <span className={`${styles.value} ${styles.version}`}>{getVersion(software)}</span>
          </div>
          <div>
            <span className={styles.title}>{t(Strings.UPDATE_VERSIONS_DETAILS_DATE)}</span>
            <span className={styles.value}>{date}</span>
          </div>
        </div>
      );
    };

    if (information.length === 0) {
      headerIcon = (
        <IconError width={20} fill={Colors.getTorchRed()} />
      );
      headerMessage = t(Strings.UPDATE_VERSIONS_MESSAGE_NO_VALIDE_VERSION);
    } else if (softwares.length === 0) {
      headerIcon = (
        <IconError width={20} fill={Colors.getTorchRed()} />
      );
      headerMessage = t(Strings.UPDATE_VERSIONS_MESSAGE_NO_VERSION_DETECTED);
    } else if (newSoftwares.length === 0) {
      headerIcon = (
        <IconSuccess width={20} />
      );
      headerMessage = t(Strings.UPDATE_VERSIONS_MESSAGE_EVERYTHING_UP_TO_DATE);
    } else {
      headerIcon = (
        <IconInfo width={20} />
      );
      if (newSoftwares.length === 1) {
        headerMessage = t(Strings.UPDATE_VERSIONS_MESSAGE_UPDATE_AVAILABLE);
      } else {
        headerMessage = t(Strings.UPDATE_VERSIONS_MESSAGE_UPDATES_AVAILABLE);
      }

      newSoftwares.forEach((s) => content.push(renderNewVersion(s)));
    }
    return (
      <div
        className={styles.newVersionsContainer}
      >
        <div
          className={styles.newVersionsHeader}
        >
          {headerIcon}
          <div
            className={styles.newVersionsHeaderMessage}
          >
            {headerMessage}
          </div>
        </div>
        {content}
      </div>
    );
  };

  // ---------------- BUTTONS ------------ //

  const renderUpdateDeviceButton = () => {
    const buttonSupportRef = { current: null };
    const newItemChecked = tableDataRef.current.filter((v) => (
      v.online && !v.running && v.checked
      && v.session?.status !== DeviceUpdateSessionStatus.WAITING_FOR_INSTALL
    ));
    const buttonEnabled = newItemChecked.length;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.UPDATE_BUTTON_UPDATE_NOW_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(Strings.UPDATE_BUTTON_UPDATE_NOW, { details: buttonEnabled ? ` (${newItemChecked.length})` : '' })}
            onPress={() => startDownloadUpdateForData(newItemChecked)}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderScheduleUpdateDeviceButton = () => {
    const buttonSupportRef = { current: null };
    const newItemChecked = tableDataRef.current.filter((v) => (
      v.online && !v.running && v.checked
      && v.session?.status !== DeviceUpdateSessionStatus.WAITING_FOR_INSTALL
    ));
    const buttonEnabled = newItemChecked.length;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.UPDATE_BUTTON_SCHEDULE_UPDATE_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(Strings.UPDATE_BUTTON_SCHEDULE_UPDATE, { details: buttonEnabled ? ` (${newItemChecked.length})` : '' })}
            onPress={() => {
              showPopupSchedule(newItemChecked, (start: Date, end: Date) => {
                scheduleUpdateForData(newItemChecked, start, end);
              });
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderInstallDeviceButton = () => {
    const buttonSupportRef = { current: null };
    const itemsAvailable = tableDataRef.current.filter((v) => (
      v.online && !v.running && v.session?.mode === DeviceUpdateSessionMode.MANDATORY
      && v.session?.status === DeviceUpdateSessionStatus.WAITING_FOR_INSTALL
    ));
    const itemsAvailableChecked = itemsAvailable.filter((v) => v.checked);
    const buttonEnabled = itemsAvailableChecked.length;
    if (!itemsAvailable.length) return null;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.UPDATE_BUTTON_INSTALL_NOW_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(Strings.UPDATE_BUTTON_INSTALL_NOW, { details: buttonEnabled ? ` (${itemsAvailableChecked.length})` : '' })}
            onPress={() => {
              showPopupBeforeInstall(itemsAvailableChecked, () => {
                startInstallUpdateForData(itemsAvailableChecked);
              });
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderButtons = () => (
    <div
      className={styles.buttonContainer}
    >
      {renderUpdateDeviceButton()}
      {renderScheduleUpdateDeviceButton()}
      {renderInstallDeviceButton()}
    </div>
  );

  // ---------------- NO DATA ------------ //

  const renderNoDevices = () => (
    <div className={styles.emptyDataMessage}>
      {t(Strings.UPDATE_NO_DEVICES)}
    </div>
  );

  const notEmptyData = devices.length > 0;
  return (
    <PageContainer
      title={t(Strings.UPDATE_PAGE_TITLE)}
      subtitle={t(Strings.UPDATE_PAGE_SUBTITLE)}
      loading={loading}
    >
      <div
        className={styles.container}
      >
        {notEmptyData ? renderTable() : renderNoDevices()}
        {notEmptyData ? renderNewVersions() : null}
      </div>
      {notEmptyData ? renderButtons() : null}
    </PageContainer>
  );
};

export default DevicesUpdatePage;
