import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DeviceList } from '../../../features/device/DeviceList';
import MenuItem, { MenuItemRef } from './MenuItem';
import { useAppTranslation } from '../../hooks/translation';
import MenuSubitem from './MenuSubitem';
import { MenuDisplayMode, MenuItemDisplayMode } from './menu.constant';
import { Strings } from '../../constants/translation';

import IconPreview from '../../../assets/icons/preview.svg';
import IconDevice from '../../../assets/icons/camera.svg';

import styles from './MenuItems.module.css';

const DEFAULT_DISPLAY_MODE = MenuDisplayMode.DEFAULT;

interface MenuItemData {
  ref?: MenuItemRef,
}
type MenuData = {
  [key in MenuItemName]: MenuItemData
};

export enum MenuItemName {
  PREVIEW = 'preview',
  DEVICES = 'devices',
  TEST = 'test', // Dev
}

const DEFAULT_MENU_DATA: { [key in MenuItemName]: MenuItemData } = {
  [MenuItemName.PREVIEW]: {},
  [MenuItemName.DEVICES]: {},
  [MenuItemName.TEST]: {},
};

const LOCATIONS = {
  [MenuItemName.PREVIEW]: '/preview',
  [MenuItemName.DEVICES]: {
    list: '/devices/description',
    time: '/devices/time',
    networks: '/devices/networks',
    update: '/devices/update',
    system: '/devices/system',
  },
  [MenuItemName.TEST]: '/users',
};

const getAllLocations = (item: MenuItemName): string[] => {
  const locations: string[] = [];
  const obj = LOCATIONS[item];
  if (typeof obj === 'string') locations.push(obj);
  else {
    locations.push(...Object.values(obj));
  }
  return locations;
};

const MATCH_LOCATIONS: { [key in MenuItemName]: (string | RegExp)[] } = {
  [MenuItemName.PREVIEW]: ['/', ...getAllLocations(MenuItemName.PREVIEW)], // "/" or "/preview"
  [MenuItemName.DEVICES]: getAllLocations(MenuItemName.DEVICES),
  [MenuItemName.TEST]: getAllLocations(MenuItemName.TEST),
};

export interface MenuItemsProps {
  onItemSelected?: (value: boolean, ref: MenuItemRef) => void;
  onItemOpened?: (value: boolean, ref: MenuItemRef) => void;
  onNavigateTo?: (itemName: MenuItemName, path: string) => void;
  displayMode?: MenuDisplayMode;
  className?: string;
  style?: React.CSSProperties;
}
export interface MenuItemsRef {
}

const MenuItems = React.forwardRef((
  {
    onItemSelected,
    onItemOpened,
    onNavigateTo,
    displayMode,
    className,
    style,
  }: MenuItemsProps,
  forwardRef: React.ForwardedRef<MenuItemsRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const [mode, setMode] = useState<MenuDisplayMode>(DEFAULT_DISPLAY_MODE);
  const itemRefs = useRef<MenuData>(DEFAULT_MENU_DATA);

  useImperativeHandle(forwardRef, () => ({}));

  const openItem = (value: boolean, ref?: MenuItemRef) => {
    ref?.open(value);
  };

  useEffect(() => {
    setMode(displayMode ?? DEFAULT_DISPLAY_MODE);
  }, [displayMode]);

  useEffect(() => {
    if (mode === MenuDisplayMode.SMALL) {
      const menuData: MenuData = itemRefs.current;
      const currentItems: MenuItemData[] = Object.values(menuData);
      currentItems.forEach((item) => {
        openItem(false, item.ref);
        if (item.ref) item.ref.select(item.ref?.isMatching());
      });
    }
  }, [mode]);

  const itemOnSelected = (menuItemName: MenuItemName) => (selected: boolean, ref: MenuItemRef) => {
    const menuData: MenuData = itemRefs.current;
    const currentItemNames: MenuItemName[] = Object.keys(menuData) as MenuItemName[];
    const currentItem: MenuItemData | undefined = menuData[menuItemName];
    if (!currentItem) return;
    currentItemNames.forEach((name) => {
      const item = menuData[name];
      const currentRef = item.ref;
      if (selected) {
        if (currentRef) {
          if (menuItemName !== name) {
            currentRef.select(false);
            openItem(false, currentRef);
          }
        }
      }
    });
    onItemSelected?.(selected, ref);
  };

  const itemOnMatching = (menuItemName: MenuItemName) => (matching: boolean, ref: MenuItemRef) => {
    if (!matching) {
      openItem(false, ref);
    }

    if (menuItemName === MenuItemName.PREVIEW) {
      ref.displayChild(matching);
    }
  };

  const itemOnOpened = (menuItemName: MenuItemName) => (opened: boolean, ref: MenuItemRef) => {
    onItemOpened?.(opened, ref);
  };

  const itemHovered = (menuItemName: MenuItemName) => (hover: boolean, ref: MenuItemRef) => {
    const isOpened = ref?.isOpened();
    if (mode === MenuDisplayMode.SMALL) {
      if (hover && !isOpened) {
        openItem(true, ref);
      } else if (!hover && isOpened && !ref.childIsHovered()) {
        openItem(false, ref);
      }
    }
  };

  const itemOnNavigateTo = (itemName: MenuItemName, path: string) => {
    onNavigateTo?.(itemName, path);
  };

  const subItemOnNavigateTo = (itemName: MenuItemName, path: string) => {
    const data = itemRefs.current[itemName];
    if (mode === MenuDisplayMode.SMALL) {
      data?.ref?.open(false);
    }
    data?.ref?.select(true);
    onNavigateTo?.(itemName, path);
  };

  const getItemDisplayMode = () => {
    if (mode === MenuDisplayMode.SMALL) return MenuItemDisplayMode.ICON_ONLY;
    return MenuItemDisplayMode.DEFAULT;
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  classes.push(mode === MenuDisplayMode.SMALL
    ? styles.displayModeSmall : styles.displayModeDefault);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      <MenuItem
        className={styles.item}
        iconSrc={IconPreview}
        matchLocations={MATCH_LOCATIONS[MenuItemName.PREVIEW]}
        title={t(Strings.MENU_ITEM_TITLE_PREVIEW)}
        navigateTo={LOCATIONS[MenuItemName.PREVIEW]}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.PREVIEW].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.PREVIEW)}
        onMatching={itemOnMatching(MenuItemName.PREVIEW)}
        onOpened={itemOnOpened(MenuItemName.PREVIEW)}
        onHovered={itemHovered(MenuItemName.PREVIEW)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.PREVIEW, path)}
      >
        <DeviceList className={styles.deviceList} />
      </MenuItem>
      <MenuItem
        className={styles.item}
        iconSrc={IconDevice}
        matchLocations={MATCH_LOCATIONS[MenuItemName.DEVICES]}
        title={t(Strings.MENU_ITEM_TITLE_DEVICES)}
        ref={(forwardedRef: MenuItemRef) => {
          itemRefs.current[MenuItemName.DEVICES].ref = forwardedRef;
        }}
        displayMode={getItemDisplayMode()}
        onSelected={itemOnSelected(MenuItemName.DEVICES)}
        onMatching={itemOnMatching(MenuItemName.DEVICES)}
        onOpened={itemOnOpened(MenuItemName.DEVICES)}
        onHovered={itemHovered(MenuItemName.DEVICES)}
        onNavigateTo={(path) => itemOnNavigateTo(MenuItemName.DEVICES, path)}
      >
        <div>
          <MenuSubitem
            title={t(Strings.MENU_SUBITEM_DEVICES_DESCRIPTION)}
            navigateTo={LOCATIONS[MenuItemName.DEVICES].list}
            onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.DEVICES, path)}
          />
          <MenuSubitem
            title={t(Strings.MENU_SUBITEM_DEVICES_TIME)}
            navigateTo={LOCATIONS[MenuItemName.DEVICES].time}
            onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.DEVICES, path)}
          />
          <MenuSubitem
            title={t(Strings.MENU_SUBITEM_DEVICES_NETWORK)}
            navigateTo={LOCATIONS[MenuItemName.DEVICES].networks}
            onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.DEVICES, path)}
          />
          <MenuSubitem
            title={t(Strings.MENU_SUBITEM_DEVICES_UPDATE)}
            navigateTo={LOCATIONS[MenuItemName.DEVICES].update}
            onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.DEVICES, path)}
          />
          <MenuSubitem
            title={t(Strings.MENU_SUBITEM_DEVICES_SYSTEM)}
            navigateTo={LOCATIONS[MenuItemName.DEVICES].system}
            onNavigateTo={(path) => subItemOnNavigateTo(MenuItemName.DEVICES, path)}
          />
        </div>
      </MenuItem>
      {
        /* <MenuItem
          className={styles.item}
          iconSrc={IconDevice}
          matchLocations={MATCH_LOCATIONS[MenuItemName.TEST]}
          title="Test"
          ref={(forwardedRef: MenuItemRef) => {
            itemRefs.current[MenuItemName.TEST].ref = forwardedRef;
          }}
          displayMode={getItemDisplayMode()}
          onSelected={itemOnSelected(MenuItemName.TEST)}
          onMatching={itemOnMatching(MenuItemName.TEST)}
          onOpened={itemOnOpened(MenuItemName.TEST)}
          onHovered={itemHovered(MenuItemName.TEST)}
          navigateTo={LOCATIONS[MenuItemName.TEST]}
        /> */
      }
    </div>
  );
});

MenuItems.defaultProps = {
  onItemSelected: undefined,
  onItemOpened: undefined,
  onNavigateTo: undefined,
  displayMode: DEFAULT_DISPLAY_MODE,
  className: undefined,
  style: undefined,
};

export default MenuItems;
