import React from 'react';
import Colors from '../../constants/colors';
import Fonts from '../../../constants/fonts';
import { ElementOnHover } from './ElementOnHover';
import InfoIcon from '../../../assets/icons/info.svg';
import WarningIcon from '../../../assets/icons/warning.svg';
import ErrorIcon from '../../../assets/icons/error.svg';
import IconSuccess from '../../../assets/icons/success.svg';

const DEFAULT_CLASSNAME = 'message-on-hover';

const containerStyle : React.CSSProperties = {
  maxWidth: 'min(400px, 90vw)',
};

const messageContainerStyle : React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '5px',
  fontFamily: Fonts.getSignika(),
  fontSize: '1rem',
  color: Colors.getMainWhite(),
};

const imgStyle : React.CSSProperties = {
  width: '20px',
  height: '20px',
  marginRight: '5px',
};

const infoStyle : React.CSSProperties = {
  backgroundColor: Colors.getClayEbony(),
};

const warningStyle : React.CSSProperties = {
  backgroundColor: Colors.getTreePoppy(),
};

const errorStyle : React.CSSProperties = {
  backgroundColor: Colors.getTorchRed(),
};

const successStyle : React.CSSProperties = {
  backgroundColor: Colors.getSalem(),
};

export enum MessageOnHoverMode {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}
const DEFAULT_MODE = MessageOnHoverMode.INFO;

export interface MessageOnHoverProps<T extends HTMLElement> {
  targetRef: React.RefObject<T> | React.MutableRefObject<T | undefined>
  /**
   * Allow to detect hover on parent of target. Hover used will be on :
   * 0 => no parent.
   * 1 => parent of target
   * 2 => parent of parent of target
   * ...
   */
  hoverOnParentLevel?: number;
  mode?: MessageOnHoverMode;
  message?: string;
  delay?: number;
  enableClick?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
function MessageOnHover<T extends HTMLElement>({
  targetRef,
  hoverOnParentLevel,
  mode,
  message,
  delay,
  enableClick,
  children,
  className,
  style,
} : MessageOnHoverProps<T>) {
  const divStyle : React.CSSProperties = { ...messageContainerStyle };
  if (style) Object.assign(divStyle, style);
  if (mode === MessageOnHoverMode.INFO) Object.assign(divStyle, infoStyle);
  if (mode === MessageOnHoverMode.WARNING) Object.assign(divStyle, warningStyle);
  if (mode === MessageOnHoverMode.ERROR) Object.assign(divStyle, errorStyle);
  if (mode === MessageOnHoverMode.SUCCESS) Object.assign(divStyle, successStyle);

  const renderMessage = () => {
    const renderImage = () => {
      let src : any = null;
      let alt : string = '';
      if (mode === MessageOnHoverMode.INFO) {
        src = InfoIcon;
        alt = 'info';
      } else if (mode === MessageOnHoverMode.WARNING) {
        src = WarningIcon;
        alt = 'warning';
      } else if (mode === MessageOnHoverMode.ERROR) {
        src = ErrorIcon;
        alt = 'error';
      } else if (mode === MessageOnHoverMode.SUCCESS) {
        src = IconSuccess;
        alt = 'success';
      }
      if (!src) return null;
      return (
        <img
          style={imgStyle}
          src={src}
          alt={alt}
        />
      );
    };
    return (
      <div style={divStyle}>
        { renderImage() }
        <div style={{ whiteSpace: 'pre-wrap' }}>
          { children ?? message ?? '' }
        </div>
      </div>
    );
  };
  return (
    <ElementOnHover
      targetRef={targetRef}
      hoverOnParentLevel={hoverOnParentLevel}
      delay={delay}
      enableClick={enableClick}
      className={className}
      style={containerStyle}
    >
      { renderMessage() }
    </ElementOnHover>
  );
}

MessageOnHover.defaultProps = {
  message: undefined,
  hoverOnParentLevel: 0,
  mode: DEFAULT_MODE,
  enableClick: true,
  delay: 500,
  children: undefined,
  className: DEFAULT_CLASSNAME,
  style: undefined,
};

export default MessageOnHover;
