export enum AuthStatus {
  In = 'in',
  Out = 'out',
  Loading = 'loading',
}

export enum UserRole {
  KADMIN = 'kadmin', // kalyzee admin
  ADMIN = 'admin',
  TECHNICIAN = 'technician',
  ORGANIZER = 'organizer',
  ATTENDEE = 'attendee',
  GUEST = 'guest',
}

export enum PasswordResetState {
  Idle = 'idle',
  ResetRefused = 'reset_refused',
  UserInput = 'user_input',
  EmailSent = 'email_sent',
  Completed = 'completed',
  KeyRefused = 'key_refused',
}

// Actions :

export interface RequestLoginLocal {
  email: string,
  password: string,
}

export interface RequestRefreshToken {
  refreshToken: string,
}

export interface RequestCreateRecovery {
  email: string,
}

export interface RequestGetRecoveryState {
  key: string,
}

export interface RequestTerminateRecovery {
  key: string,
  password: string,
}

// Redux Store :

export interface Identity {
  email: string
  username: string
  lastname?: string
  firstname?: string
  avatar?: string
}

export enum UserStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  TRIAL_PERIOD_IN_PROGRESS = 'trial_period_in_progress', // equal to 'ENABLED'
  TRIAL_PERIOD_IS_OVER = 'trial_period_is_over', // equal to 'DISABLED'
  BANNED = 'banned',
}

export interface User {
  id: string
  _id: string
  _identity?: string
  _organization: string
  _liveProfiles?: string[]
  casUserId?: string
  ltiUserId?: string
  role: UserRole
  identity?: Identity
  status: UserStatus,
}

export enum OrganizationStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  TRIAL_PERIOD_IN_PROGRESS = 'trial_period_in_progress', // equal to 'ENABLED'
  TRIAL_PERIOD_IS_OVER = 'trial_period_is_over', // equal to 'DISABLED'
}

export interface Organization {
  id: string
  _id: string
  name: string
  status: OrganizationStatus
}
