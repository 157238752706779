import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { AuthStatus, OrganizationStatus, UserStatus } from '../../../features/session/interfaces';
import { selectOrganization, selectUser } from '../../../features/session/selectors';
import { useSocketConnexion } from '../../../features/socket/hooks';
import { Strings } from '../../constants/translation';
import { useAppTranslation } from '../../hooks/translation';
import { Loading } from '../utils/Loading';
import Touchable from '../utils/Touchable';
import styles from './PrivateRoute.module.css';

interface PrivateRouteProps {
  redirectPath?: string,
  authStatus: AuthStatus,
}

const PrivateRoute = ({ redirectPath, authStatus }: PrivateRouteProps) => {
  const { t } = useAppTranslation();
  const [displayStatusBar, setDisplayStatusBar] = useState(true);
  const user = useAppSelector(selectUser);
  const organization = useAppSelector(selectOrganization);

  useSocketConnexion();
  if (authStatus === AuthStatus.Out) {
    const url = new URL(window.location.href);
    const currentPath = url.pathname.startsWith('/') ? url.pathname.replace('/', '') : url.pathname;
    let redirection = redirectPath!;
    if (currentPath.length && currentPath !== 'logout') {
      redirection += `?redirect=${currentPath}`;
    }
    return (
      <Navigate to={redirection} replace />
    );
  }
  if (authStatus === AuthStatus.Loading) {
    return <Loading />;
  }

  const renderStatusBar = () => {
    if (!displayStatusBar) return null;
    const userStatus = user?.status;
    const organizationStatus = organization?.status;
    if (
      userStatus === UserStatus.TRIAL_PERIOD_IN_PROGRESS
      || organizationStatus === OrganizationStatus.TRIAL_PERIOD_IN_PROGRESS
    ) {
      return (
        <Touchable
          className={styles.statusBarContainer}
          onPress={() => setDisplayStatusBar(false)}
        >
          <div className={styles.statusBarLine} />
          <div className={styles.statusBarText}>
            {t(Strings.STATUS_BAR_TRIAL_PERIOD_IN_PROGRESS)}
          </div>
          <div className={styles.statusBarLine} />
        </Touchable>
      );
    }
    return null;
  };
  return (
    <div className={styles.container}>
      {renderStatusBar()}
      <Outlet />
    </div>

  );
};

PrivateRoute.defaultProps = {
  redirectPath: '/',
};

export default PrivateRoute;
