import { DeviceFactoryResetRequest, DeviceGetInformationRequest,
  DeviceMoveRequest,
  DeviceRebootRequest,
  DeviceSetSystemTimeRequest,
  DeviceSetSystemTimezoneRequest,
  DeviceSetViewRequest,
  DeviceStartLiveRequest,
  DeviceStopLiveRequest,
  DeviceStopMoveRequest,
  DeviceStopZoomRequest,
  DeviceSwitchSceneRequest,
  DeviceSwitchViewRequest,
  DeviceUpdateAbortRequest,
  DeviceUpdateGetContextRequest,
  DeviceUpdateInstallRequest,
  DeviceUpdateScheduleSilentRequest,
  DeviceUpdateStartDownloadRequest,
  DeviceZoomRequest,
  OrganizationGetInformationAboutDevicesRequest } from '@kalyzee/kast-websocket-module';
import { createAction } from '@reduxjs/toolkit';

// Request
export const requestDevices = createAction('request_devices');
export const requestDevicesInformation = createAction<OrganizationGetInformationAboutDevicesRequest>('request_devices_information');
export const requestDeviceInformation = createAction<DeviceGetInformationRequest>('request_device_information');
export const requestDevicesUpdateSessions = createAction('request_devices_update_sessions');
export const requestDeviceUpdateSessions = createAction<DeviceUpdateGetContextRequest>('request_device_update_sessions');
export const requestDevicesUpdateName = createAction('request_devices_update_name');

// Ptz
export const move = createAction<DeviceMoveRequest>('move');
export const stopMove = createAction<DeviceStopMoveRequest>('stop_move');
export const zoom = createAction<DeviceZoomRequest>('zoom');
export const stopZoom = createAction<DeviceStopZoomRequest>('stop_zoom');

// Video
export const startLive = createAction<DeviceStartLiveRequest>('start_live');
export const stopLive = createAction<DeviceStopLiveRequest>('stop_live');
export const switchScene = createAction<DeviceSwitchSceneRequest>('switch_scene');
export const switchView = createAction<DeviceSwitchViewRequest>('switch_view');
export const setView = createAction<DeviceSetViewRequest>('set_view');

// Update
export const startDownloadUpdate = createAction<DeviceUpdateStartDownloadRequest>('update_start_download');
export const scheduleUpdate = createAction<DeviceUpdateScheduleSilentRequest>('update_schedule');
export const installUpdate = createAction<DeviceUpdateInstallRequest>('update_install');
export const abortUpdate = createAction<DeviceUpdateAbortRequest>('update_abort');

// System
export const reboot = createAction<DeviceRebootRequest>('reboot');
export const factoryReset = createAction<DeviceFactoryResetRequest>('factory_reset');
export const setSystemTime = createAction<DeviceSetSystemTimeRequest>('set_system_time');
export const setSystemTimezone = createAction<DeviceSetSystemTimezoneRequest>('set_system_timezone');

export default {
  // Request
  requestDevices,
  requestDevicesInformation,
  requestDeviceInformation,
  requestDevicesUpdateSessions,
  requestDeviceUpdateSessions,
  requestDevicesUpdateName,
  // Ptz
  move,
  stopMove,
  zoom,
  stopZoom,
  // Video
  startLive,
  stopLive,
  switchScene,
  switchView,
  setView,
  // Update
  startDownloadUpdate,
  scheduleUpdate,
  installUpdate,
  abortUpdate,
  // System
  reboot,
  factoryReset,
  setSystemTime,
  setSystemTimezone,
};
