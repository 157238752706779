import { DeviceUpdateSession, VersionState } from '@kalyzee/kast-websocket-module';
import { Device, DeviceInformation } from '../../../../features/device/interfaces';

export enum TableUpdateDeviceAction {
  START_UPDATE = 'start_update',
  INSTALL = 'install',
  ABORT = 'abort',
  CLEAN_ERROR = 'clean_error',
}

export interface TableUpdateDevicesData {
  device: Device,
  info?: DeviceInformation,
  session?: DeviceUpdateSession,
  deviceId: string,

  // UTILS
  state?: VersionState,
  online: boolean,
  checked: boolean,
  running: boolean,
  newVersion?: string,
  error?: string,
  versionCode?: string;
  scheduled: boolean;

  // TO SHOW
  name?: string,
  date?: Date,
  version?: string,
}
