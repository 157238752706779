import { createError } from '../../features/error/utils';
import { ErrorType, ResponseError } from './errors';

import storage from './storage';

const addToPathNameUrl = (url : string, suffixPathName : string) : string => {
  const urlObj = new URL(url);
  if (urlObj.pathname.endsWith('/') && suffixPathName.startsWith('/')) {
    suffixPathName = suffixPathName.replace('/', '');
  }
  urlObj.pathname += suffixPathName;
  return urlObj.href;
};
const getUrl = () => new URL(window.location.href);

// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Build backend url
 *
 * @returns {string} Returns a url as string
 */
const buildBaseUrl = (protocol : string) => {
  const url = getUrl();
  const hostNameSplitted = url.hostname.split('.');
  hostNameSplitted.splice(0, 1);
  const hostNameStr = hostNameSplitted.join('.');
  // return 'https://abo.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b';
  // return 'https://dro-1.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b/';
  return `${protocol}//api.${hostNameStr}`;
};

/**
 * Get the current backend base (root) url
 *
 * @returns {string} Returns a url as string
 */
export const getBaseUrl = () => {
  const url = getUrl();
  return buildBaseUrl(url.protocol);
};

export const getGenerateWebRTCSessionTokenUrl = (deviceId: string) => `${getBaseUrl()}/socket/token/webrtc/${deviceId}`;

const getSocketBaseUrl = () => {
  const url = getUrl();
  // return 'wss://abo.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b';
  // return 'https://dro-1.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b';
  // Keep same security level : http -> ws & https -> wss
  return `${buildBaseUrl(url.protocol.replace('http', 'ws'))}`;
};

export const getUserSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), '/user');
export const getWebRTCSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), '/webrtc');

export const getAuthHeaders = (token: string) => ({ Authorization: `Bearer ${token}` });

// TODO : token retrieval
export const getHeaders = (authenticated: boolean) => {
  let headers = {
    'Content-Type': 'application/json',
  };
  if (authenticated) {
    const token = storage.getToken();
    if (token) {
      const authHeaders = getAuthHeaders(token);
      headers = { ...headers, ...authHeaders };
    }
  }
  return headers;
};

export const getInit = (
  method: string,
  body?: object,
  authenticated: boolean = true,
) => {
  const init = {
    method,
    body: JSON.stringify(body as BodyInit),
    headers: getHeaders(authenticated),
  };
  return init;
};

export const request = async (
  url: string,
  method: string,
  body?: object,
  authenticated: boolean = true,
) : Promise<any> => {
  const fullUrl = `${getBaseUrl()}${url}`;
  let response;
  try {
    response = await fetch(fullUrl, getInit(method, body, authenticated));
  } catch (error) {
    // Network error (< 200)
    // TODO : see how to retrieve status
    throw new ResponseError(createError(ErrorType.NetworkError));
  }
  // The Api might return an error that doesn't have a JSON body
  // and is only described by the Http status :
  // if (response.status === 460 || response.status === 403) {
  //   throw new ResponseError({ type: ErrorType.ApiHttpError, code: response.status });
  // }
  // Past this point, the request is supposed to have a JSON body
  let data;
  try {
    data = await response.json();
  } catch (error) {
    // An error occured while parsing the JSON body
    // throw new ResponseError({ type: ErrorType.ParsingError, message: 'Parsing error' });
  }
  if (response.ok) {
    return data;
  }
  // The api error is described by the JSON body :
  throw new ResponseError(createError(ErrorType.ApiError, response.status, data?.message, data));
};

export default {
  request,
  getSocketBaseUrl,
};
