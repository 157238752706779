import { ResultEmittedMessage } from '@kalyzee/kast-websocket-module';
import { PayloadAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

// To use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useSocketAppDispatch = () => {
  const dispatch = useAppDispatch();
  return (
    <T extends ResultEmittedMessage, P = any>(
      action: PayloadAction<P>,
    ): Promise<T> => (
      dispatch<Promise<T>, PayloadAction<P>>(action) as Promise<T>
    )
  );
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
