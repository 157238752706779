import React from 'react';
import Colors from '../../constants/colors';
import LoadingSvg from '../../../assets/icons/loading.svg';

export const Loading = () => (
  <div style={{
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.getBlueOxford(),
  }}
  >
    <img style={{ width: '10vw', height: '10vw' }} alt="" src={LoadingSvg} />
  </div>
);

export default {
  Loading,
};
