import { DeviceFactoryResetRequest,
  DeviceFactoryResetResult,
  DeviceRebootRequest,
  DeviceRebootResult, DeviceSystemContext } from '@kalyzee/kast-websocket-module';
import React, { useRef, useState } from 'react';
import { useSocketAppDispatch } from '../../../app/hooks';
import PageContainer from '../../../common/components/page/PageContainer';
import TableSystemDevices, { TableSystemDevicesRef } from '../../../common/components/table/system/TableSystemDevices';
import { TableSystemDevicesAction, TableSystemDevicesData } from '../../../common/components/table/system/TableSystemDevices.constant';
import Button from '../../../common/components/utils/Button';
import MessageOnHover, { MessageOnHoverMode } from '../../../common/components/utils/MessageOnHover';
import { PopupButtonType, PopupIconType, PopupId } from '../../../common/components/utils/Popup';
import { showPopup } from '../../../common/components/utils/PopupContainer';
import { Strings } from '../../../common/constants/translation';
import { awaitWithMinimumDelay } from '../../../common/helpers/utils';
import { useRender } from '../../../common/hooks/component';
import { useAppTranslation } from '../../../common/hooks/translation';
import { factoryReset, reboot } from '../../../features/device/actions';
import { useDevices } from '../../../features/device/hooks';
import { Device } from '../../../features/device/interfaces';
import { getDeviceName } from '../../../features/device/utils';

import styles from './devices.system.module.css';

const DevicesSystemPage = () => {
  const { t } = useAppTranslation();
  const render = useRender();
  const [loading, setLoading] = useState<boolean>(true);
  const socketDispatch = useSocketAppDispatch();
  const devices: Device[] = useDevices();
  const tableDataRef = useRef<TableSystemDevicesData[]>([]);
  const [itemsChecked, setItemChecked] = useState<TableSystemDevicesData[]>([]);
  const tableRef = useRef<TableSystemDevicesRef>();

  if (devices.length && loading) {
    setLoading(false);
  }

  const updateItemChecked = () => {
    const current = tableDataRef.current.filter((d) => d.checked);
    let update = false;
    if (current.length === itemsChecked.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currItem1 of current) {
        if (!itemsChecked.find((currItem2) => currItem1.deviceId === currItem2.deviceId)) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) setItemChecked(current);
  };
  updateItemChecked();
  const onItemCheck = (item: TableSystemDevicesData) => {
    updateItemChecked();
  };

  const generateTableData = (): TableSystemDevicesData[] => {
    if (loading) return tableDataRef.current;
    const result: TableSystemDevicesData[] = [];
    devices.forEach((device) => {
      const deviceId = device.id;
      const currentData: TableSystemDevicesData | undefined = tableDataRef.current.find(
        (d) => d.deviceId === deviceId,
      );
      const online = !!device?.online;
      const systemContext : DeviceSystemContext | undefined = device.context?.systemContext;
      const processing = online ? currentData?.processing : undefined;
      const success = online ? currentData?.success : false;
      const error = online ? currentData?.error : undefined;
      const checked = online ? (currentData?.checked ?? false) : false;
      const newData: TableSystemDevicesData = {
        deviceId,
        device,
        name: getDeviceName(device, false),
        online,
        checked,
        lastReboot: systemContext?.lastReboot,
        processing,
        success,
        error,
      };
      const data: TableSystemDevicesData = Object.assign(currentData ?? {}, newData);
      result.push(data);
    });
    tableDataRef.current = result;
    return tableDataRef.current;
  };

  // ---------------- POPUP ------------ //

  const showPopupReboot = (
    items: TableSystemDevicesData[],
    successCallback?: () => void,
  ) => {
    const content = t(Strings.SYSTEM_POPUP_REBOOT_CONFIRMATION_CONTENT);
    const displayed = showPopup({
      id: PopupId.SYSTEM_WARNING_REBOOT,
      title: t(Strings.SYSTEM_POPUP_REBOOT_CONFIRMATION_TITLE),
      buttons: [
        {
          element: t(Strings.SYSTEM_POPUP_REBOOT_CONFIRMATION_CANCEL),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(Strings.SYSTEM_POPUP_REBOOT_CONFIRMATION_VALIDATE),
          type: PopupButtonType.VALIDATE,
          onClick: () => {
            successCallback?.();
            return true;
          },
        },
      ],
      content,
      iconContent: PopupIconType.WARNING,
      enableCloseButton: false,
      enableNotShowAgain: true,
    });
    // not show again
    if (!displayed) {
      successCallback?.();
    }
  };

  const showPopupFactoryReset = (
    items: TableSystemDevicesData[],
    successCallback?: () => void,
  ) => {
    const content = t(Strings.SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_CONTENT);
    const displayed = showPopup({
      id: PopupId.SYSTEM_WARNING_FACTORY_RESET,
      title: t(Strings.SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_TITLE),
      buttons: [
        {
          element: t(Strings.SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_CANCEL),
          type: PopupButtonType.CANCEL,
        },
        {
          element: t(Strings.SYSTEM_POPUP_FACTORY_RESET_CONFIRMATION_VALIDATE),
          type: PopupButtonType.VALIDATE,
          onClick: () => {
            successCallback?.();
            return true;
          },
        },
      ],
      content,
      iconContent: PopupIconType.WARNING,
      enableCloseButton: false,
      enableNotShowAgain: true,
    });
    // not show again
    if (!displayed) {
      successCallback?.();
    }
  };

  // ---------------- REQUEST ------------ //

  const rebootForDevice = async (
    item: TableSystemDevicesData,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId } = item;

    item.processing = t(Strings.SYSTEM_REBOOT_PROCESSING_MESSAGE);
    if (rerender) tableRef.current?.render();
    const payload: DeviceRebootRequest = {
      deviceId,
    };
    const result: DeviceRebootResult = await awaitWithMinimumDelay(
      socketDispatch(reboot(payload)),
      2000,
    );

    item.checked = false;
    updateItemChecked();
    if (result.error && item.processing) {
      if (!result.error.timeout) {
        item.error = t(Strings.SYSTEM_REBOOT_ERROR_MESSAGE);
        item.processing = undefined;
      }
    }

    if (rerender) tableRef.current?.render();
    return true;
  };

  const rebootForData = async (items: TableSystemDevicesData[]) => {
    items.forEach((item) => {
      rebootForDevice(item);
    });
    render();
  };

  const factoryResetForDevice = async (
    item: TableSystemDevicesData,
    rerender: boolean = true,
  ): Promise<boolean> => {
    const { deviceId } = item;

    item.processing = t(Strings.SYSTEM_FACTORY_RESET_PROCESSING_MESSAGE);
    if (rerender) tableRef.current?.render();
    const payload: DeviceFactoryResetRequest = {
      deviceId,
    };
    const result: DeviceFactoryResetResult = await awaitWithMinimumDelay(
      socketDispatch(factoryReset(payload)),
      2000,
    );

    item.checked = false;
    updateItemChecked();
    if (result.error && item.processing) {
      if (!result.error.timeout) {
        item.error = t(Strings.SYSTEM_FACTORY_RESET_ERROR_MESSAGE);
        item.processing = undefined;
      }
    }

    if (rerender) tableRef.current?.render();
    return true;
  };

  const factoryResetForData = async (items: TableSystemDevicesData[]) => {
    items.forEach((item) => {
      factoryResetForDevice(item);
    });
    render();
  };

  // ---------------- BUTTONS ------------ //

  const renderRebootButton = () => {
    const buttonSupportRef = { current: null };
    const itemsAvailable = tableDataRef.current.filter((v) => (
      v.online && !v.processing && !v.error && !v.success
    ));
    const itemsAvailableChecked = itemsAvailable.filter((v) => v.checked);
    const buttonEnabled = itemsAvailableChecked.length;
    if (!itemsAvailable.length) return null;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.SYSTEM_BUTTON_REBOOT_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(itemsAvailableChecked.length <= 1
              ? Strings.SYSTEM_BUTTON_REBOOT
              : Strings.SYSTEM_BUTTON_REBOOT_MANY_DEVICE, { details: buttonEnabled ? ` (${itemsAvailableChecked.length})` : '' })}
            onPress={() => {
              showPopupReboot(itemsAvailableChecked, () => {
                rebootForData(itemsAvailableChecked);
              });
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderFactoryResetButton = () => {
    const buttonSupportRef = { current: null };
    const itemsAvailable = tableDataRef.current.filter((v) => (
      v.online && !v.processing && !v.error && !v.success
    ));
    const itemsAvailableChecked = itemsAvailable.filter((v) => v.checked);
    const buttonEnabled = itemsAvailableChecked.length;
    if (!itemsAvailable.length) return null;
    return (
      <div>
        {
          !buttonEnabled ? (
            <MessageOnHover
              targetRef={buttonSupportRef}
              mode={MessageOnHoverMode.INFO}
              message={t(Strings.SYSTEM_BUTTON_FACTORY_RESET_HOVER_MESSAGE)}
            />
          ) : null
        }

        <div ref={buttonSupportRef}>
          <Button
            title={t(itemsAvailableChecked.length <= 1
              ? Strings.SYSTEM_BUTTON_FACTORY_RESET_TIMEZONE
              : Strings.SYSTEM_BUTTON_FACTORY_RESET_TIMEZONE_MANY_DEVICE, { details: buttonEnabled ? ` (${itemsAvailableChecked.length})` : '' })}
            onPress={() => {
              showPopupFactoryReset(itemsAvailableChecked, () => {
                factoryResetForData(itemsAvailableChecked);
              });
            }}
            disabled={!buttonEnabled}
          />
        </div>
      </div>
    );
  };

  const renderButtons = () => (
    <div
      className={styles.buttonContainer}
    >
      {renderRebootButton()}
      {renderFactoryResetButton()}
    </div>
  );

  // ---------------- NO DATA ------------ //

  const renderNoDevices = () => (
    <div className={styles.emptyDataMessage}>
      {t(Strings.SYSTEM_NO_DEVICES)}
    </div>
  );

  const notEmptyData = devices.length > 0;

  // ---------------- TABLE ------------ //
  const renderTable = () => (
    <TableSystemDevices
      className={styles.table}
      ref={tableRef}
      onItemChecked={onItemCheck}
      data={generateTableData()}
      onAction={(action : TableSystemDevicesAction, item : TableSystemDevicesData) => {
        if (action === TableSystemDevicesAction.REBOOT) {
          showPopupReboot([item], () => {
            rebootForDevice(item);
          });
        } else if (action === TableSystemDevicesAction.FACTORY_RESET) {
          showPopupFactoryReset([item], () => {
            factoryResetForDevice(item);
          });
        } else if (action === TableSystemDevicesAction.CLEAN_ERROR) {
          item.error = undefined;
          tableRef.current?.render();
          render();
        }
      }}
    />
  );

  return (
    <PageContainer
      title={t(Strings.SYSTEM_PAGE_TITLE)}
      subtitle={t(Strings.SYSTEM_PAGE_SUBTITLE)}
      loading={loading}
    >
      <div
        className={styles.container}
      >
        {notEmptyData ? renderTable() : renderNoDevices()}
      </div>
      {notEmptyData ? renderButtons() : null}
    </PageContainer>
  );
};

export default DevicesSystemPage;
