import { request } from '../../common/helpers/request';
import { Organization, User } from './interfaces';

export const loginLocal = async (body?: object) : Promise<object> => request('/user/login/local/panel', 'POST', body, false);
export const userMe = async () : Promise<User> => request('/user/me', 'GET');
export const organization = async () : Promise<Organization> => request('/organization/current', 'GET');
export const refreshToken = async (body?: object) : Promise<object> => request('/user/token/refresh', 'POST', body, true);
export const createRecovery = async (body?: object) : Promise<object> => request('/user/recovery', 'POST', { ...body, source: 'panel' }, false);
export const getRecoveryState = async (payload: any) : Promise<object> => request(`/user/recovery/${payload.key}`, 'GET', undefined, false);
export const terminateRecovery = async (payload: any) : Promise<object> => request(`/user/recovery/${payload.key}`, 'POST', { password: payload.password }, false);

export default {
  loginLocal,
  userMe,
  organization,
  refreshToken,
  createRecovery,
  getRecoveryState,
  terminateRecovery,
};
