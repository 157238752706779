import { createAction } from '@reduxjs/toolkit';
import { RequestCreateRecovery, RequestGetRecoveryState, RequestLoginLocal, RequestRefreshToken, RequestTerminateRecovery } from './interfaces';

// Saga Actions :

export const requestLoginLocal = createAction<RequestLoginLocal>('request_login_local');
export const requestLogout = createAction('request_logout');
export const requestUserMe = createAction('request_user_me');
export const requestOrganization = createAction('request_organization');
export const requestRefreshToken = createAction<RequestRefreshToken>('request_refresh_token');
export const refreshTokenFailed = createAction('refresh_token_failed');
export const retryRequest = createAction('retry_request');
export const requestCreateRecovery = createAction<RequestCreateRecovery>('request_create_recovery');
export const requestGetRecoveryState = createAction<RequestGetRecoveryState>('request_get_recovery_state');
export const requestTerminateRecovery = createAction<RequestTerminateRecovery>('request_terminate_recovery');

export default {
  requestLoginLocal,
  requestLogout,
  requestUserMe,
  requestOrganization,
  requestRefreshToken,
  refreshTokenFailed,
  retryRequest,
  requestCreateRecovery,
  requestGetRecoveryState,
  requestTerminateRecovery,
};
