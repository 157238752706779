import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorData } from '../../common/helpers/errors';

interface ErrorState {
  error?: ErrorData,
}

const initialState: ErrorState = {
  error: undefined,
};

export const sessionSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorData | undefined>) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = sessionSlice.actions;

export default sessionSlice.reducer;
