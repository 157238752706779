import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import CountryAndTimezones, { getAllTimezones, Timezone, TimezoneName } from 'countries-and-timezones';
import CountryFlag from './CountryFlag';

import styles from './TimezoneSelector.module.css';
import MessageOnHover, { MessageOnHoverMode } from './MessageOnHover';

const DEFAULT_TIMEZONE_NAME_ON_HOVER = true;

interface DatasetRef {
  details: Record<TimezoneName, Timezone>;
  timezones: TimezoneName[];
}

export interface TimezoneSelectorProps {
  timezone: TimezoneName;
  onChange?: (timezone: TimezoneName) => void;
  timezoneOffsetOnHover?: boolean;
  flag?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export interface TimezoneSelectorRef { }

const TimezoneSelector = React.forwardRef(({
  timezone,
  onChange,
  timezoneOffsetOnHover,
  flag,
  className,
  style,
}: TimezoneSelectorProps, forwardRef: ForwardedRef<TimezoneSelectorRef | undefined>) => {
  const [selected, setSelected] = useState<TimezoneName>(timezone as TimezoneName);
  const datasetRef = useRef<DatasetRef>();

  if (!datasetRef.current) {
    const timezonesDetails = getAllTimezones();
    const timezones = Object.keys(timezonesDetails) as TimezoneName[];
    timezones.sort((a: TimezoneName, b: TimezoneName) => {
      if (a === selected) return -1;
      if (b === selected) return 1;
      return a.localeCompare(b);
    });
    datasetRef.current = {
      details: timezonesDetails,
      timezones,
    };
  }

  const renderRow = (curr: TimezoneName) => {
    const timezoneDetails = CountryAndTimezones.getTimezone(curr);
    if (!timezoneDetails) return null;
    const timezoneName = timezoneDetails.name;
    const renderFlag = () => {
      if (!flag) return null;
      const country = CountryAndTimezones.getCountryForTimezone(timezoneName);
      return (
        <CountryFlag
          countryCode={country?.id}
        />
      );
    };
    const currentRef: React.MutableRefObject<HTMLDivElement | null> = { current: null };
    const rowClasses = [styles.timezoneRow];
    if (selected === curr) rowClasses.push(styles.selected);
    return (
      <div
        role="tablist"
        tabIndex={0}
        key={`key_${timezoneName}`}
        onClick={(ev) => {
          onChange?.(curr);
          setSelected(curr);
        }}
        onKeyDown={() => { }}
        ref={currentRef}
      >
        {
          timezoneOffsetOnHover ? (
            <MessageOnHover
              enableClick={false}
              targetRef={currentRef}
              mode={MessageOnHoverMode.INFO}
              message={`UTC ${timezoneDetails.utcOffsetStr} DST ${timezoneDetails.dstOffsetStr}`}
            />
          ) : null
        }
        <div
          className={rowClasses.join(' ')}
        >
          {renderFlag()}
          {timezoneName}
        </div>
      </div>
    );
  };

  const renderRows = () => {
    if (!datasetRef.current?.timezones) return null;
    return datasetRef.current?.timezones.map((tz) => renderRow(tz));
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      {renderRows()}
    </div>
  );
});

TimezoneSelector.defaultProps = {
  onChange: undefined,
  timezoneOffsetOnHover: DEFAULT_TIMEZONE_NAME_ON_HOVER,
  flag: true,
  className: undefined,
  style: undefined,
};

export default TimezoneSelector;
