import React, { useImperativeHandle, useRef } from 'react';
import Colors from '../../../constants/colors';
import { useElementSize } from '../../../hooks/window';
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from '../../utils/Table';
import { useRender } from '../../../hooks/component';
import { useAppTranslation } from '../../../hooks/translation';
import { TableSystemDevicesAction, TableSystemDevicesData } from './TableSystemDevices.constant';
import ContentWithIcon from '../../utils/ContentWithIcon';
import MessageOnHover, { MessageOnHoverMode } from '../../utils/MessageOnHover';
import Touchable from '../../utils/Touchable';
import { resetAllSvgAnimations } from '../../../helpers/element';

import { ReactComponent as IconLoading } from '../../../../assets/icons/loading.svg';
import { ReactComponent as IconSuccess } from '../../../../assets/icons/success.svg';

import styles from './TableSystemDevices.module.css';
import commonStyles from '../TableCommon.module.css';
import { Strings } from '../../../constants/translation';

export interface TableSystemDevicesRef {
  render: () => void;
}

export interface TableSystemDevicesProps {
  data: TableSystemDevicesData[];
  onItemChecked?: (item: TableSystemDevicesData) => void;
  onAction?: (action: TableSystemDevicesAction, item: TableSystemDevicesData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableSystemDevices = React.forwardRef((
  {
    data,
    onItemChecked,
    onAction,
    className,
    style,
  }: TableSystemDevicesProps,
  forwardRef: React.ForwardedRef<TableSystemDevicesRef | undefined>,
) => {
  const { t } = useAppTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const render = useRender();
  const size = useElementSize(containerRef);
  let iconLoadingRef: TableContentRef[] = [];

  useImperativeHandle(forwardRef, () => ({
    render,
  }));

  const generateConfiguration = () => {
    const columnConfiguration: TableConfColumn<TableSystemDevicesData>[] = [
      {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'name',
        width: '15%',
        minWidth: '7.5rem',
        title: t(Strings.SYSTEM_TABLE_COLUMN_TITLE_DEVICES),
        header: { className: commonStyles.tableHeaderCellDevice },
        item: { className: commonStyles.tableCellDevice },
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'lastReboot',
        title: t(Strings.SYSTEM_TABLE_COLUMN_TITLE_LAST_REBOOT),
        minWidth: '10rem',
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'reboot',
        width: '9rem',
        title: t(Strings.SYSTEM_TABLE_COLUMN_TITLE_REBOOT),
        enableSort: false,
      },
      {
        type: TableColumnType.CLASSIC,
        key: 'factoryReset',
        width: '9rem',
        title: t(Strings.SYSTEM_TABLE_COLUMN_TITLE_FACTORY_RESET),
        enableSort: false,
      },
    ];

    const tableConfiguration: TableConf<TableSystemDevicesData> = {
      columns: columnConfiguration,
      header: {
        className: commonStyles.tableHeader,
        cell: {
          className: commonStyles.tableHeaderCell,
        },
      },
      row: {
        className: commonStyles.tableRow,
        cell: {
          className: commonStyles.tableRowCell,
        },
      },
      content: { className: commonStyles.tableContent },
      valueToShowIfUndefined: { value: '-', className: commonStyles.tableUndefinedValue },
    };

    return tableConfiguration;
  };

  // Called when a value is changed. Checkboxes here
  const valueChanged = (value: any, columnKey: string, item: TableSystemDevicesData) => {
    if (columnKey === 'checked') onItemChecked?.(item);
  };

  const customRenderCell = (
    element: JSX.Element | null,
    elementRef: TableContentRef,
    columnKey: string,
    item: TableSystemDevicesData,
  ) => {
    if (columnKey === 'checked') {
      if (!item.online) return element;
      if (item.error) {
        return (
          <div ref={elementRef} style={{ color: Colors.getGrayDusty() }}>
            -
          </div>
        );
      }
      if (item.processing) {
        iconLoadingRef.push(elementRef);
        return (
          <div ref={elementRef}>
            <IconLoading
              width="20px"
            />
          </div>
        );
      }
      if (item.success) {
        return (
          <IconSuccess
            width="20px"
            height="20px"
            fill={Colors.getMountainMeadow()}
          />
        );
      }
    }
    if (columnKey === 'lastReboot') {
      if (!item.online || !item.lastReboot) return '-';
      const date = new Date(item.lastReboot);
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
      return date.toLocaleString(undefined, options);
    }
    if (columnKey === 'reboot') {
      if (!item.online) return '-';
      return (
        <Touchable
          className={commonStyles.button}
          onPressOut={() => onAction?.(TableSystemDevicesAction.REBOOT, item)}
        >
          {t(Strings.SYSTEM_TABLE_BUTTON_REBOOT)}
        </Touchable>
      );
    }
    if (columnKey === 'factoryReset') {
      if (!item.online) return '-';
      return (
        <div ref={elementRef}>
          <Touchable
            className={commonStyles.button}
            onPressOut={() => onAction?.(TableSystemDevicesAction.FACTORY_RESET, item)}
          >
            <MessageOnHover
              targetRef={elementRef}
              message={t(Strings.SYSTEM_TABLE_BUTTON_FACTORY_RESET_HOVER_MESSAGE)}
              mode={MessageOnHoverMode.WARNING}
            />
            {t(Strings.SYSTEM_TABLE_BUTTON_FACTORY_RESET)}
          </Touchable>
        </div>
      );
    }
    return element;
  };

  const customRenderRow = (
    elements: JSX.Element[],
    item: TableSystemDevicesData,
    d: TableSystemDevicesData[],
    index: number,
  ) => {
    if (!item.online) return elements;
    if (item.error) {
      const content = (
        <div className={styles.errorMessageContainer} key={`error_${item.deviceId}`}>
          <ContentWithIcon
            className={styles.errorMessage}
            alwaysShowIcon
            mode={MessageOnHoverMode.ERROR}
          >
            {item.error}
          </ContentWithIcon>
          <Touchable
            className={commonStyles.button}
            onPressOut={() => onAction?.(TableSystemDevicesAction.CLEAN_ERROR, item)}
          >
            {t(Strings.OK)}
          </Touchable>
        </div>
      );
      return [
        elements[0],
        elements[1],
        content,
      ];
    }
    if (item.processing) {
      const content = (
        <ContentWithIcon
          className={styles.processingMessage}
          alwaysShowIcon
          mode={MessageOnHoverMode.INFO}
          key={`processing_${item.deviceId}`}
        >
          <div style={{ width: '100%' }}>
            {item.processing}
          </div>
        </ContentWithIcon>
      );
      return [
        elements[0],
        elements[1],
        content,
      ];
    }
    return elements;
  };

  const addCustomStyleOnCell = (
    columnKey: string,
    item: TableSystemDevicesData,
  ) => {
    const result: TableStyle = {};
    return result;
  };

  const addCustomStyleOnRow = (
    item: TableSystemDevicesData,
    currData: TableSystemDevicesData[],
    index: number,
  ) => {
    const result: TableStyle = {};
    const rowStyle: React.CSSProperties = {};
    result.style = rowStyle;
    if (!item.online) {
      rowStyle.opacity = 0.5;
      rowStyle.backgroundColor = Colors.getClayEbonyMedium(0.4);
      if (index === 0 || currData[index - 1].online) {
        rowStyle.borderTopLeftRadius = '10px';
        rowStyle.borderTopRightRadius = '10px';
      }
      if (index + 1 >= currData.length || currData[index + 1].online) {
        rowStyle.borderBottomLeftRadius = '10px';
        rowStyle.borderBottomRightRadius = '10px';
      }
    }
    return result;
  };

  const sortByDeviceOnline = (a: TableSystemDevicesData, b: TableSystemDevicesData) => {
    const aOnline = a.online ? 1 : 0;
    const bOnline = b.online ? 1 : 0;
    return bOnline - aOnline;
  };

  const onRenderTableStarts = () => {
    iconLoadingRef = [];
  };

  const onRenderTableEnded = () => {
    iconLoadingRef.forEach((ref) => {
      const el = ref?.current;
      if (el) resetAllSvgAnimations(el);
    });
  };

  // Transform value before to display it
  const transformValue = (columnKey: string, item: TableSystemDevicesData, value: any) => {
    if (columnKey === 'checked') {
      return (
        item.online
      ) ? value : false;
    }
    return value;
  };

  const renderTable = () => (
    <Table
      className={commonStyles.table}
      data={data}
      keyExtractor={(_, item) => `key-${item.deviceId}`}
      transformValue={transformValue}
      configuration={generateConfiguration()}
      onData={(d) => d.sort(sortByDeviceOnline)}
      onRenderCellRow={customRenderCell}
      onRenderRow={customRenderRow}
      onStyleCellRow={addCustomStyleOnCell}
      onStyleRow={addCustomStyleOnRow}
      onChangeValue={valueChanged}
      onRenderStarts={() => onRenderTableStarts}
      onRenderEnded={onRenderTableEnded}
    />
  );

  const classes = [commonStyles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
      ref={containerRef}
    >
      {renderTable()}
    </div>
  );
});

TableSystemDevices.defaultProps = {
  onItemChecked: undefined,
  onAction: undefined,
  className: undefined,
  style: undefined,
};

export default TableSystemDevices;
