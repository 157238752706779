import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './home.module.css';
import MenuOutline from '../../assets/icons/menu-outline.svg';

import { Strings } from '../../common/constants/translation';
import { useAppTranslation } from '../../common/hooks/translation';
import { useSocket } from '../../features/socket/hooks';
import { SocketStatus } from '../../features/socket/interfaces';
import TouchableImage from '../../common/components/utils/TouchableImage';
import Menu from '../../common/components/menu/Menu';
import { MenuDisplayMode } from '../../common/components/menu/menu.constant';
import { getMenuDisplayMode, setMenuDisplayMode } from '../../common/helpers/storage';
import { useElementSize } from '../../common/hooks/window';
import { MenuItemName } from '../../common/components/menu/MenuItems';
import { useOuterClick } from '../../common/hooks/click';

const HomePage = () => {
  const { t } = useAppTranslation();
  const elementRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(elementRef);
  const { socketStatus, retryConnect } = useSocket();
  const [socketBar, setSocketBar] = useState(false);
  const [displayLeftPane, setDisplayLeftPane] = useState(true);
  const firstSocketConnectionRef = useRef<boolean>(true);
  const mobileBarRef = useRef<HTMLDivElement>(null);
  let menuDisplayMode: MenuDisplayMode = getMenuDisplayMode() ?? MenuDisplayMode.DEFAULT;
  let menuMobileMode = false;
  let enableSwitchDisplayMode = true;

  useOuterClick(menuContainerRef, () => {
    if (menuMobileMode && displayLeftPane) {
      setDisplayLeftPane(false);
    }
  });

  if (size) {
    if (size.width && size.width < 1000) {
      menuDisplayMode = MenuDisplayMode.DEFAULT;
      enableSwitchDisplayMode = false;
      menuMobileMode = true;
    }
  }

  useEffect(() => {
    if (firstSocketConnectionRef.current) {
      if (socketStatus === SocketStatus.Online
        || socketStatus === SocketStatus.Offline) {
        firstSocketConnectionRef.current = false;
      }
      if (socketStatus !== SocketStatus.Offline) return () => { };
    }
    if (socketStatus === SocketStatus.Online) {
      if (socketBar) {
        const id = setTimeout(() => {
          if (socketBar) setSocketBar(false);
        }, 5000);
        return () => { clearTimeout(id); };
      }
    } else if (!socketBar) {
      setSocketBar(true);
    }
    return () => { };
  }, [socketStatus]);

  const renderSocketBar = () => {
    if (!socketBar) return null;
    if (socketStatus === SocketStatus.Online) {
      return (
        <div className={styles.socketBarOnline}>
          <span>{t(Strings.SOCKET_ONLINE)}</span>
        </div>
      );
    }
    if (socketStatus === SocketStatus.Loading) {
      return (
        <div className={styles.socketBarLoading}>
          <span>{t(Strings.SOCKET_LOADING)}</span>
        </div>
      );
    }
    return (
      <div className={styles.socketBarOffline}>
        <span>{t(Strings.SOCKET_OFFLINE)}</span>
        <button className={styles.retryButton} type="button" onClick={retryConnect}>{t(Strings.RETRY)}</button>
      </div>
    );
  };

  return (
    <div className={styles.mainContainer} ref={elementRef}>
      {
        menuMobileMode ? (
          <div className={styles.mobileBar} ref={mobileBarRef}>
            <TouchableImage
              onPress={() => {
                setDisplayLeftPane(!displayLeftPane);
              }}
              src={MenuOutline}
              alt=""
              className={styles.mobileBarButton}
              imageStyle={{ width: '50px', height: '50px' }}
            />
          </div>
        ) : null
      }
      {renderSocketBar()}
      <div className={styles.main}>
        <div ref={menuContainerRef}>
          <Menu
            display={displayLeftPane}
            displayMode={menuDisplayMode}
            enableSwitchDisplayMode={enableSwitchDisplayMode}
            onNavigateTo={(itemName: MenuItemName, path: string) => {
              if (menuMobileMode && itemName !== MenuItemName.PREVIEW) {
                setDisplayLeftPane(false);
              }
            }}
            onDisplayMode={(mode: MenuDisplayMode) => {
              setMenuDisplayMode(mode);
              if (!mobileBarRef.current) return;
              if (mode === MenuDisplayMode.SMALL) {
                mobileBarRef.current.style.display = 'none';
              } else {
                mobileBarRef.current.style.removeProperty('display');
              }
            }}
          />
        </div>
        <div className={styles.rightPane}>
          <div className={styles.rightPaneContent}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
