import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocketStatus } from './interfaces';

interface SocketState {
  socketStatus: SocketStatus,
}

const initialState: SocketState = {
  socketStatus: SocketStatus.Loading,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setSocketStatus: (state, action: PayloadAction<SocketStatus>) => {
      state.socketStatus = action.payload;
    },
  },
});

export const {
  setSocketStatus,
} = socketSlice.actions;

export default socketSlice.reducer;
