import React, { useEffect, useRef, useState } from 'react';
import { DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection, Player, PlayerWebRTC, VideoState } from '@kalyzee/kast-react-player-module';

import { Loading } from '../../common/components/utils/Loading';
import Colors from '../../common/constants/colors';

import { move, setView, stopMove, stopZoom, switchScene, switchView, zoom } from '../../features/device/actions';
import { DeviceCard } from '../../features/device/DeviceCard';
import { useDevicesData } from '../../features/device/hooks';
import { DeviceData } from '../../features/device/interfaces';
import { useSocket } from '../../features/socket/hooks';
import { SocketStatus } from '../../features/socket/interfaces';
import Background from '../../assets/background.png';
import VideoOverlay from '../../common/components/video/VideoOverlay';
import { getGenerateWebRTCSessionTokenUrl, getWebRTCSocketUrl } from '../../common/helpers/request';
import { getToken } from '../../common/helpers/storage';
import { useAppDispatch } from '../../app/hooks';
import { addTrackEndedMediaStreamListener, TrackEndedMediaStreamListener } from '../../common/helpers/media';
import Fonts from '../../constants/fonts';
import { RTCCommunication, RTCSignlingEmitterData, RTCSignlingEvent } from '../../common/helpers/rtc';
import { useAppTranslation } from '../../common/hooks/translation';
import { Strings } from '../../common/constants/translation';

const pageStyle : React.CSSProperties = {
  width: '100%',
  height: '100%',
  backgroundColor: Colors.getGallery(),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const containerStyle : React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90%',
  width: '90%',
  maxWidth: '500px',
  borderRadius: '10px',
  overflow: 'hidden',
  zIndex: 1,
};

const containerDevicesStyle : React.CSSProperties = {
  overflow: 'auto',
  width: '100%',
  flex: 1,
  backgroundColor: Colors.getClayEbony(),
};

const backgroundStyle : React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  width: '100%',
  height: '100%',
  filter: 'blur(8px)',
  objectFit: 'cover',
};

const titleStyle : React.CSSProperties = {
  fontFamily: Fonts.getHalisR(),
  color: Colors.getBluePrussian(),
  fontSize: '22px',
  marginBottom: '15px',
  maxWidth: '90%',
  textAlign: 'center',
  zIndex: 1,
};

const containerButtonStyle : React.CSSProperties = {
  width: '100%',
  height: '30px',
  fontFamily: Fonts.getHalisR(),
  color: 'white',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};
const selectButtonStyle : React.CSSProperties = {
  flex: 1,
  height: '100%',
  backgroundColor: Colors.getMountainMeadow(),
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const cancelButtonStyle : React.CSSProperties = {
  flex: 1,
  height: '100%',
  backgroundColor: Colors.getTorchRed(),
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

enum IframeActions {
  SELECTION_DONE = 'SELECTION_DONE',
  SELECTION_CANCELED = 'SELECTION_CANCELED',
  // RTC
  RTC_NEW = 'NEW_RTC',
  RTC_SDP = 'SDP',
  RTC_ICE_CANDIDATE = 'ICE_CANDIDATE',
  RTC_NEW_MEDIA = 'NEW_MEDIA',
  // CONTROLS
  CONTROLS_MOVE_UP = 'MOVE_UP',
  CONTROLS_MOVE_LEFT = 'MOVE_LEFT',
  CONTROLS_MOVE_RIGHT = 'MOVE_RIGHT',
  CONTROLS_MOVE_DOWN = 'MOVE_DOWN',
  CONTROLS_MOVE_STOP = 'MOVE_STOP',
  CONTROLS_SCENE_1 = 'SCENE_1',
  CONTROLS_SCENE_2 = 'SCENE_2',
  CONTROLS_SCENE_3 = 'SCENE_3',
  CONTROLS_SCENE_4 = 'SCENE_4',
  CONTROLS_ZOOM_IN = 'ZOOM_IN',
  CONTROLS_ZOOM_OUT = 'ZOOM_OUT',
  CONTROLS_ZOOM_STOP = 'ZOOM_STOP',
}

function sendIframeMessage<T = any>(action : IframeActions, data? : T) : void {
  const message = { action, data };
  window.parent.postMessage(JSON.stringify(message), '*');
}

const DeviceSelectorPage = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const devices = useDevicesData();
  const { socketStatus } = useSocket();
  const [currentDeviceId, setCurrentDeviceId] = useState<string>();
  const currentDevice = currentDeviceId
    ? devices.find((d) => d.id === currentDeviceId) : undefined;
  const [mediaStream, setMediaStream] = useState<MediaStream | undefined>();
  const rtcRef = useRef<RTCCommunication>();

  const onMove = (item : DeviceData, direction : DeviceMoveDirection) => {
    dispatch(move({ deviceId: item.id, direction }));
  };

  const onStopMove = (item : DeviceData) => {
    dispatch(stopMove({ deviceId: item.id }));
  };

  const onZoom = (item : DeviceData, direction : DeviceZoomDirection) => {
    dispatch(zoom({ deviceId: item.id, direction }));
  };

  const onStopZoom = (item : DeviceData) => {
    dispatch(stopZoom({ deviceId: item.id }));
  };

  const onScene = (item : DeviceData, scene : DeviceScene) => {
    dispatch(switchScene({ deviceId: item.id, scene: scene as any }));
  };

  const onView = (item : DeviceData, view: DeviceView) => {
    dispatch(switchView({ deviceId: item.id, view: view as any }));
  };

  const onAssignView = (item : DeviceData, view: DeviceView) => {
    dispatch(setView({ deviceId: item.id, view: view as any }));
  };

  const onIframeMessage = (action : string, data : any) => {
    if (action === RTCSignlingEvent.SDP
      || action === RTCSignlingEvent.ICE_CANDIDATE) {
      rtcRef.current?.emit(action, data);
    } else if (action === IframeActions.CONTROLS_MOVE_DOWN) {
      if (currentDevice) onMove(currentDevice, DeviceMoveDirection.DOWN);
    } else if (action === IframeActions.CONTROLS_MOVE_UP) {
      if (currentDevice) onMove(currentDevice, DeviceMoveDirection.UP);
    } else if (action === IframeActions.CONTROLS_MOVE_LEFT) {
      if (currentDevice) onMove(currentDevice, DeviceMoveDirection.LEFT);
    } else if (action === IframeActions.CONTROLS_MOVE_RIGHT) {
      if (currentDevice) onMove(currentDevice, DeviceMoveDirection.RIGHT);
    } else if (action === IframeActions.CONTROLS_MOVE_STOP) {
      if (currentDevice) onStopMove(currentDevice);
    } else if (action === IframeActions.CONTROLS_SCENE_1) {
      if (currentDevice) onScene(currentDevice, DeviceScene.CAMERA_ONLY);
    } else if (action === IframeActions.CONTROLS_SCENE_2) {
      if (currentDevice) onScene(currentDevice, DeviceScene.INPUT_HDMI_ONLY);
    } else if (action === IframeActions.CONTROLS_SCENE_3) {
      if (currentDevice) onScene(currentDevice, DeviceScene.HALF_MODE);
    } else if (action === IframeActions.CONTROLS_SCENE_4) {
      if (currentDevice) onScene(currentDevice, DeviceScene.MIXED);
    } else if (action === IframeActions.CONTROLS_ZOOM_IN) {
      if (currentDevice) onZoom(currentDevice, DeviceZoomDirection.ZOOM_IN);
    } else if (action === IframeActions.CONTROLS_ZOOM_OUT) {
      if (currentDevice) onZoom(currentDevice, DeviceZoomDirection.ZOOM_OUT);
    } else if (action === IframeActions.CONTROLS_ZOOM_STOP) {
      if (currentDevice) onStopZoom(currentDevice);
    }
  };

  useEffect(() => {
    const messageListener = (ev : MessageEvent) => {
      try {
        const msg = JSON.parse(ev.data);
        onIframeMessage(msg.action, msg.data);
      } catch (err) { /* */ }
    };
    window.addEventListener('message', messageListener, false);
    return () => window.removeEventListener('message', messageListener);
  }, [currentDevice]);

  useEffect(() => {
    let listener : TrackEndedMediaStreamListener;
    if (mediaStream) {
      listener = addTrackEndedMediaStreamListener(mediaStream, (track : MediaStreamTrack) => {
        if (track.kind === 'video') {
          setMediaStream(undefined);
        }
      });
      rtcRef.current?.close();
      const callback = <E extends keyof RTCSignlingEmitterData>(
        event : E,
        data : RTCSignlingEmitterData[E],
      ) => {
        let action : IframeActions | undefined;
        if (event === RTCSignlingEvent.SDP) action = IframeActions.RTC_SDP;
        else if (event === RTCSignlingEvent.ICE_CANDIDATE) action = IframeActions.RTC_ICE_CANDIDATE;
        if (action) sendIframeMessage(action, data);
      };
      sendIframeMessage(IframeActions.RTC_NEW);
      rtcRef.current = new RTCCommunication(callback);
      rtcRef.current.addStream(mediaStream);
    }
    return () => {
      if (listener) listener.clear();
    };
  }, [mediaStream]);

  const renderDevice = (device : DeviceData) => (
    <DeviceCard
      device={device}
      key={device.id}
      select={() => {
        if (currentDeviceId !== device.id) {
          setMediaStream(undefined);
        }
        setCurrentDeviceId(device.id);
      }}
    />
  );
  const renderDevices = () => devices.map((d) => (
    renderDevice(d)
  ));

  const renderWebRTCPlayer = () => {
    if (!currentDevice) return null;
    return (
      <PlayerWebRTC
        style={{ height: '200px' }}
        src={{
          url: getWebRTCSocketUrl(),
          generateTokenWithUserToken: getToken() || undefined,
          generateTokenEndpoint: getGenerateWebRTCSessionTokenUrl(currentDeviceId!),
        }}
        play
        live
        mute
        enableCameraControls
        delayAfterHideControls={0}
        retryAmountOnError={3}
        retryDelay={3000}
        enableScene
        enableView
        enableMoveKeyboardEvent={false}
        enableAssignViewKeyboardEvent={false}
        enableViewKeyboardEvent={false}
        enableSceneKeyboardEvent={false}
        enableZoomKeyboardEvent={false}
        scene={currentDevice.currentScene as (DeviceScene | undefined)}
        onMove={(direction : DeviceMoveDirection) => onMove?.(currentDevice, direction)}
        onStopMove={() => onStopMove?.(currentDevice)}
        onZoom={(z : DeviceZoomDirection) => onZoom?.(currentDevice, z)}
        onStopZoom={() => onStopZoom?.(currentDevice)}
        onScene={(scene : DeviceScene) => onScene?.(currentDevice, scene)}
        onView={(view: DeviceView) => onView?.(currentDevice, view)}
        onAssignView={(view: DeviceView) => onAssignView?.(currentDevice, view)}
        onMediaStream={(stream : MediaStream) => {
          if (stream) {
            setMediaStream(stream);
            sendIframeMessage(IframeActions.RTC_NEW_MEDIA, currentDeviceId);
          }
        }}
      >
        {
          (state: VideoState, r: () => void) => (
            <VideoOverlay
              state={state}
              onRetry={r}
            />
          )
        }
      </PlayerWebRTC>
    );
  };

  const selectKast = () => {
    sendIframeMessage(IframeActions.SELECTION_DONE, currentDevice);
  };

  const cancel = () => {
    sendIframeMessage(IframeActions.SELECTION_CANCELED);
  };

  const render = () => {
    if (socketStatus !== SocketStatus.Online) return <Loading />;
    return (
      <div style={pageStyle}>
        <img style={backgroundStyle} src={Background} alt="background" />
        <div style={titleStyle}>
          { t(Strings.CHOOSE_YOUR_KAST) }
        </div>
        <div style={containerStyle}>
          { renderWebRTCPlayer() }
          <div style={containerDevicesStyle}>
            { renderDevices() }
          </div>
          <div style={containerButtonStyle}>
            {
              mediaStream && currentDevice ? (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => selectKast()}
                  onKeyDown={(e) => { if (e.code === 'Enter') selectKast(); }}
                  style={selectButtonStyle}
                >
                  { t(Strings.SELECT_THIS_KAST) }
                </div>
              ) : null
            }
            <div
              role="button"
              tabIndex={0}
              onClick={() => cancel()}
              onKeyDown={(e) => { if (e.code === 'Enter') cancel(); }}
              style={cancelButtonStyle}
            >
              { t(Strings.CANCEL) }
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default DeviceSelectorPage;
