import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface RouterContextState {
  current?: string,
  from?: string,
}
export const RouterContext = React.createContext<RouterContextState>({});
export const RouterProvider = ({
  children,
} : { children: React.ReactNode }) => {
  const location = useLocation();
  const [route, setRoute] = useState<RouterContextState>({
    current: location.pathname,
    from: undefined,
  });

  useEffect(() => {
    setRoute((prev) => ({ current: location.pathname, from: prev.current }));
  }, [location]);

  return (
    <RouterContext.Provider value={route}>
      {children}
    </RouterContext.Provider>
  );
};
