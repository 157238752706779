import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import Timezone from 'timezone-enum';

import styles from './DateTimeLabel.module.css';
import TimezoneLabel from './TimezoneLabel';

export const dateTimeLabelPredefinedOptions: { [key: string]: Intl.DateTimeFormatOptions } = {
  DATE_ONLY: { year: 'numeric', month: 'long', day: '2-digit' },
  TIME_ONLY: { hour: 'numeric', minute: 'numeric', second: 'numeric' },
  DATE_AND_TIME: { year: 'numeric', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' },
};

export interface DateTimeLabelProps {
  dateTime: Date;
  timezone?: Timezone;
  local?: string;
  options?: Intl.DateTimeFormatOptions;
  className?: string;
  style?: React.CSSProperties;
}

export interface DateTimeLabelRef { }

const DateTimeLabel = React.forwardRef(({
  dateTime,
  timezone,
  local,
  options,
  className,
  style,
}: DateTimeLabelProps, forwardRef: ForwardedRef<DateTimeLabelRef | undefined>) => {
  const convertDateToString = (date: Date): string => date.toLocaleString(local, options);
  const [strDateTime, setStrDateTime] = useState<string>(convertDateToString(dateTime));
  const dateTimeOffsetStartedAt = useRef<Date>(new Date());

  useEffect(() => {
    dateTimeOffsetStartedAt.current = new Date();
    const interval = setInterval(() => {
      const now = new Date();
      const offsetFromStart = now.getTime() - dateTimeOffsetStartedAt.current.getTime();
      const dateToDisplay = new Date(dateTime.getTime() + offsetFromStart);
      setStrDateTime(convertDateToString(dateToDisplay));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dateTime]);

  const renderTimezone = () => {
    if (!timezone) return null;
    return (
      <div className={styles.timezoneContainer}>
        (
        <TimezoneLabel
          timezone={timezone}
        />
        )
      </div>
    );
  };

  const classes = [styles.container];
  if (className) classes.push(className);
  return (
    <div
      className={classes.join(' ')}
      style={style}
    >
      {strDateTime}
      {renderTimezone()}
    </div>
  );
});

DateTimeLabel.defaultProps = {
  local: undefined,
  timezone: undefined,
  options: dateTimeLabelPredefinedOptions.DATE_AND_TIME,
  className: undefined,
  style: undefined,
};

export default DateTimeLabel;
