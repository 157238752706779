import { createAction } from '@reduxjs/toolkit';
import { ErrorData } from '../../common/helpers/errors';

// Saga Actions :

export const appError = createAction<ErrorData>('app_error');

export default {
  appError,
};
