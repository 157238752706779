import React from 'react';
import Colors from '../../constants/colors';
import Fonts from '../../../constants/fonts';

const containerStyle : React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  fontFamily: Fonts.getSignika(),
  fontSize: 14,
  color: Colors.getBlueCornflower(),
  padding: 5,
};
export interface AppVersionProps {
  className?: string;
  style?: React.CSSProperties;
}

const AppVersion = ({
  className,
  style,
} : AppVersionProps) => {
  const version = process.env.REACT_APP_APP_VERSION;
  const getText = () : string | null => {
    if (version === undefined) return null;
    if (version.startsWith('dev')) return `version:${version}`;
    return `v${version}`;
  };
  const render = () => (
    <div
      style={{ ...containerStyle, ...(style || {}) }}
      className={className}
    >
      { getText() }
    </div>
  );
  return render();
};

AppVersion.defaultProps = {};

export default AppVersion;
