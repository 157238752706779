import React from 'react';
import BackButton from '../../common/components/utils/BackButton';
import { Strings } from '../../common/constants/translation';
import { useAppTranslation } from '../../common/hooks/translation';
import { ReactComponent as IconDisabled } from '../../assets/icons/disabled.svg';
import { ReactComponent as IconBanned } from '../../assets/icons/nospeaker.svg';
import { ReactComponent as IconTrialPeriodIsOver } from '../../assets/icons/trail-period-is-over.svg';

import styles from './forbidden.module.css';
import Colors from '../../common/constants/colors';

const ForbiddenPage = () => {
  const { t } = useAppTranslation();
  const url = new URL(window.location.href);
  const reason = url.searchParams.get('r') ?? 'default';

  const detailsReasonMapping: any = {
    ubanned: {
      icon: (
        <IconBanned
          className={styles.icon}
          stroke={Colors.getBluePrussian()}
          fill={Colors.getBluePrussian()}
        />
      ),
      title: t(Strings.FORBIDDEN_PAGE_USER_BANNED_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_USER_BANNED_MESSAGE),
    },
    udisabled: {
      icon: (
        <IconDisabled
          className={styles.icon}
          stroke={Colors.getBluePrussian()}
          fill={Colors.getBluePrussian()}
        />
      ),
      title: t(Strings.FORBIDDEN_PAGE_USER_DISABLED_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_USER_DISABLED_MESSAGE),
    },
    utrialperiodisover: {
      icon: (
        <IconTrialPeriodIsOver
          className={styles.icon}
          stroke={Colors.getBluePrussian()}
          fill={Colors.getBluePrussian()}
        />
      ),
      title: t(Strings.FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_USER_TRIAL_PERIOD_IS_OVER_MESSAGE),
    },
    odisabled: {
      icon: (
        <IconDisabled
          className={styles.icon}
          stroke={Colors.getBluePrussian()}
          fill={Colors.getBluePrussian()}
        />
      ),
      title: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_DISABLED_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_DISABLED_MESSAGE),
    },
    otrialperiodisover: {
      icon: (
        <IconTrialPeriodIsOver
          className={styles.icon}
          stroke={Colors.getBluePrussian()}
          fill={Colors.getBluePrussian()}
        />
      ),
      title: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_ORGANIZATION_TRIAL_PERIOD_IS_OVER_MESSAGE),
    },
    default: {
      icon: (
        <IconDisabled
          className={styles.icon}
          stroke={Colors.getBluePrussian()}
          fill={Colors.getBluePrussian()}
        />
      ),
      title: t(Strings.FORBIDDEN_PAGE_ERROR_TITLE),
      message: t(Strings.FORBIDDEN_PAGE_ERROR_MESSAGE),
    },
  };
  const details = detailsReasonMapping[reason] ?? detailsReasonMapping.default;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className={styles.blurredBackground} />
      <div className={styles.container}>
        <BackButton />
        {details.icon}
        <div className={styles.title}>{details.title}</div>
        <div className={styles.subTitle}>{details.message}</div>
        <div className={styles.contact}>{t(Strings.FORBIDDEN_PAGE_CONTACT_ADMINISTRATOR)}</div>
      </div>
    </div>
  );
};

export default ForbiddenPage;
