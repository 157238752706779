import React, { ForwardedRef, useImperativeHandle, useRef, useState } from 'react';
import { useOnPress, UseOnPressDirecion } from '../../hooks/click';

const DEFAULT_DISABLED = false;

const containerStyle : React.CSSProperties = {
  cursor: 'pointer',
  userSelect: 'none',
};
const defaultAdditionalStylePressed : React.CSSProperties = {
  opacity: 0.5,
};
const disabledStyle : React.CSSProperties = {
  cursor: 'default',
};

export interface TouchableProps {
  disabled?: boolean;
  onPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  additionalStylePressed?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export interface TouchableRef {
  getHTMLElement: () => HTMLElement | undefined;
}

const Touchable = React.forwardRef(({
  disabled,
  onPress,
  onPressIn,
  onPressOut,
  additionalStylePressed,
  children,
  className,
  style,
} : TouchableProps, forwardRef: ForwardedRef<TouchableRef | undefined>) => {
  const [touched, setTouched] = useState<boolean>(false);
  const innerRef = useRef<HTMLDivElement>();

  const getHTMLElement = () => innerRef.current;

  useImperativeHandle(forwardRef, () => ({
    getHTMLElement,
  }));

  useOnPress(innerRef, (e : UseOnPressDirecion) => {
    if (!disabled && e === UseOnPressDirecion.PRESS_IN) {
      setTouched(true);
      onPressIn?.();
      onPress?.();
    } else if (e === UseOnPressDirecion.PRESS_OUT) {
      setTouched(false);
      if (!disabled) onPressOut?.();
    }
  });

  const divStyle : React.CSSProperties = { ...containerStyle };
  if (disabled) Object.assign(divStyle, disabledStyle);
  if (style) Object.assign(divStyle, style);
  if (touched && additionalStylePressed) Object.assign(divStyle, additionalStylePressed);
  return (
    <div
      draggable={false}
      ref={(el) => { innerRef.current = el || undefined; }}
      style={divStyle}
      className={className}
    >
      { children }
    </div>
  );
});

Touchable.defaultProps = {
  disabled: DEFAULT_DISABLED,
  className: 'touchable',
  style: undefined,
  additionalStylePressed: defaultAdditionalStylePressed,
  onPress: undefined,
  onPressIn: undefined,
  onPressOut: undefined,
  children: undefined,
};

export default Touchable;
