import React from 'react';
import AppVersion from '../../common/components/app/AppVersion';
import LogoutForm from '../../features/session/LogoutForm';

import styles from './logout.module.css';
import BackButton from '../../common/components/utils/BackButton';

const LogoutPage = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <div className={styles.blurredBackground} />
    <BackButton />
    <div className={styles.container}>
      <div className={styles.form}>
        <LogoutForm />
        <AppVersion />
      </div>
    </div>
  </div>
);

export default LogoutPage;
