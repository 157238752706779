import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthStatus } from '../../../features/session/interfaces';
import { Loading } from '../utils/Loading';

interface LoginRouteProps {
  redirectPath: string,
  authStatus: AuthStatus,
}

const LoginRoute = ({ redirectPath, authStatus }: LoginRouteProps) => {
  if (authStatus === AuthStatus.In) {
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect') || redirectPath;
    return <Navigate to={redirect} replace />;
  }
  if (authStatus === AuthStatus.Loading) {
    return <Loading />;
  }
  return <Outlet />;
};

LoginRoute.defaultProps = {
  redirectPath: '/',
};

LoginRoute.propTypes = {
  redirectPath: PropTypes.string,
  authStatus: PropTypes.string.isRequired,
};

export default LoginRoute;
