import React from 'react';
import { base64encode } from 'nodejs-base64';
import Timezone from 'timezone-enum';

export const delay = (ms: number) => new Promise((res) => { setTimeout(res, ms); });

export const awaitWithMinimumDelay = <T = any>(
  promise: Promise<T>,
  ms: number,
): Promise<T> => {
  const startsAt = new Date();
  const waitDelay = async () : Promise<void> => {
    const endsAt = new Date();
    const diff = endsAt.getTime() - startsAt.getTime();
    if (diff >= ms) return;
    await delay(ms - diff);
  };
  const result = new Promise<T>((resolve, reject) => {
    promise.then(async (value : T) => {
      await waitDelay();
      resolve(value);
    }).catch(async (error : any) => {
      await waitDelay();
      reject(error);
    });
  });
  return result;
};

export const generateRandomId = (digit: number = 16): string => [...Array(16)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

export const cssPropertiesToStyledComponent = (dict: React.CSSProperties): string => {
  let str = '';
  Object.entries(dict).forEach((pair) => {
    const [key, value] = pair;
    let clo = '';
    key.split('').forEach((lt) => {
      if (lt.toUpperCase() === lt) {
        clo += `-${lt.toLowerCase()}`;
      } else {
        clo += lt;
      }
    });
    str += `${clo}:${value};`;
  });
  return str;
};

export const enumContainsString = (enu: any, str: string): boolean => (
  Object.values(enu).includes(str)
);

export const is24HourClockFormat = (): boolean => {
  const now = new Date();
  const dateStr = now.toLocaleTimeString();

  // apparently toLocaleTimeString() has a bug in Chrome. toString() however
  // returns 12/24 hour formats. If one of two contains AM/PM execute 12 hour
  // coding.
  if (dateStr.match(/am|pm/i) || dateStr.toString().match(/am|pm/i)) {
    return false;
  }
  return true;
};

interface Stringifiable {
  toString: () => string;
}
export const toBase64 = <T extends Stringifiable>(value: T | string): string => (
  base64encode(typeof value === 'string' ? value : value.toString()).toString()
);

export const getCurrentTimezone = (defaultValue: Timezone = Timezone['Europe/Paris']): Timezone => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (enumContainsString(Timezone, timezone)) {
    return timezone as Timezone;
  }
  return defaultValue;
};

export const isMobilePlatform = (): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

export const isTouchEnabled = (): boolean => (
  ('ontouchstart' in window)
  || ((navigator as any).maxTouchPoints > 0)
  || ((navigator as any).msMaxTouchPoints > 0)
);

export default {
  delay,
  generateRandomId,
  cssPropertiesToStyledComponent,
  enumContainsString,
  is24HourClockFormat,
  toBase64,
  getCurrentTimezone,
  isMobilePlatform,
  isTouchEnabled,
};
